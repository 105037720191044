import { FinancingTypesResult, UserResult } from "services/orders/models/OrdersTypes";
import { FileResult, Locale } from "services/shared-models/sharedTypes";
import { FileResponse } from "services/shared-models/sharedTypes";

export type ProgramDetailsResult = {
  displayName: string;
  description: string;
  ruleAndConditionName?: string;
  ruleAndConditionTemplate?: string;
  commitmentName?: string;
  commitmentTemplate?: string;
  locale?: Locale;
};

export type AudienceTypesResult = {
  id: number;
  audienceTypeDetail: {
    displayName: string;
    locale: string;
  };
};

export interface ProgramLocalResult {
  id: number;
  adsImage: string;
  programDetail: ProgramDetailsResult;
  programType: string;
  adsEndDate: Date;
  programOrders: number;
  programClassification: string;
  fundingDurationInMonths: number;
  status: string;
  audienceTypes: AudienceTypesResult[];
}

export interface ProgramLocalItemResult {
  id: number;
  status: string;
  serialNumber: string;
  programDetails: ProgramDetailsResult[];
  classificationProgramId: number;
  classificationProgramName: string;
  adsImage: string;
  programTypeId: number;
  contractStartDate: Date;
  fundingDurationInMonths: number;
  adsStartDate: Date;
  adsEndDate: Date;
  fundingAmount: number;
  audienceTypes: AudienceTypesResult[];
  programCycleId?: number;
  programCycleYearId?: number;
  adsImageId?: string;
  contractId?: number;
  formBuilderSchema?: string;
  files: FileResult[];
  financingCalculationItems?: {
    id: number;
    financingType: FinancingTypesResult;
  }[];

  programMinutesResult?: ProgramMinutes;
}

export type ProgramMinutes = {
  id: number;
  committeeDecisionNumber: string;
  committeeDecisionDate: string;
  minuteDescription: string;
  files: FileResult[];
};

export interface ConditionsAndRulesResultResponse {
  id: number;
  conditionAndRuleDetail: {
    displayName: string;
    description: string;
    templateContent: string;
    locale: Locale;
  };
  isActive: boolean;
  createdOn: Date;
  updatedBy: string;
  updatedOn: Date;
  order: number;
}

export interface PDFTemplatesResultResponse {
  id: number;
  pdfTemplateDetail: {
    displayName: string;
    description: string;
    templateContent: string;
    locale: Locale;
  };
  isActive: boolean;
  createdOn: Date;
  updatedBy: string;
  updatedOn: Date;
  order: number;
}

export interface ConditionsAndRulesByIDResponse {
  id: number;
  isActive: boolean;
  conditionAndRuleDetails: {
    displayName: string;
    description: string;
    templateContent: string;
    locale: Locale;
  }[];
}

export interface PDFTemplateByIDResponse {
  id: number;
  isActive: boolean;
  templateTypeId: number;
  pdfTemplateDetails: {
    displayName: string;
    description: string;
    templateContent: string;
    locale: Locale;
  }[];
}

export enum FinancingTypes {
  PrimaryParticipant = 1,
  SecondaryParticipant = 2,
  Other = 3,
}

export interface ProgramByIDResponse {
  id: number;
  status: string;
  serialNumber: string;
  programDetails: {
    displayName: string;
    description: string;
    ruleAndConditionName: string;
    ruleAndConditionTemplate: string;
    commitmentName: string;
    commitmentTemplate: string;
    locale: Locale;
  }[];
  classificationProgramId: number;
  programTypeId: number;
  contractStartDate: Date;
  fundingDurationInMonths: number;
  adsStartDate: Date;
  adsEndDate: Date;
  fundingAmount: number;
  audienceTypes: {
    id: number;
    audienceTypeDetail: {
      displayName: string;
      locale: Locale;
    };
  }[];
  programCycleYearId: number;
  programCycleId: number;
  adsImageId: string;
  adsImage: string;
  adsImageSize: string;
  adsImageName: string;
  contractId: number;
  formBuilderSchema: string;
  financingCalculationItems: {
    id: number;
    fromValue: number;
    toValue: number;
    notes?: string;
    financingType: {
      id: number;
      repetitionAllowed?: number;
      financingTypesDetail: {
        displayName: string;
        locale: Locale;
      };
      calculationWay: {
        calculationWayDetail: {
          displayName: string;
          locale: Locale;
        };
      };
    };
  }[];
  files?: FileResponse[];
  programTypeName: string;
  classificationProgramName: string;
  programCycleDetail: {
    displayName: string;
    locale: Locale;
  };
  programCycleYearDetail: {
    displayName: string;
    locale: Locale;
  };
  numberOfOrders?: number;
  programMinutes?: ProgramMinutes;
}

export type CreateProgramMinutes = {
  committeeDecisionNumber: string;
  committeeDecisionDate: string;
  minuteDescription: string;
  filesIds: string[];
  actionName?: string | null;
  serialGuid?: string | null;
};

export type ProgramActionResult = {
  action: string;
  processInstanceId: number;
  folio?: string;
  workflowName?: string;
  appliedBy: UserResult;
  createdOn?: string;
  status?: string;
  notes?: string;
};
