import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useEffect } from "react";

function DashboardView() {
  const { setBreadcrumbs } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "dashboard",
        path: "/home/dashboard",
        menuItemId: "dashboard",
      },
    ]);
  }, []);

  return <div> Dashoard</div>;
}

export default DashboardView;
