import { ProgramDetailsResult } from "services/programs/models/ProgramTypes";
import { CentreManagementResult, DetailsResult, FileResult } from "services/shared-models/sharedTypes";
import { TaskActionsNames } from "services/tasks/models/tasksTypes";

export type ExaminerUserResult = {
  id: string;
  arabicName: string;
  englishName: string;
  username: string;
  taskCount: number;
  createdOn: string | Date | null;
  isActive: boolean;
};

export enum OrderExaminerStatus {
  Pending = 1,
  Cancel,
  Evaluated,
  Accepted,
  Apologies,
  Returned,
  Submitted,
}

export type ExaminerStatus = keyof typeof OrderExaminerStatus;
export type ExaminerOrderEvaluationFormResult = {
  programId: number;
  serialNumber: string;
  programDetail: ProgramDetailsResult;
  centreManagement?: CentreManagementResult;
  formBuilderSchema?: string;
  orderId: number;
  deadLine: string | Date | null;
  formSubject: string;
  formValue: string;
  notes: string;
  examinerStatus: ExaminerStatus;
  examinerEvaluationFormValue: ExaminerEvaluationFormValueResult;
  programOrderEvaluationFiles: FileResult[];
  evaluationForm: ExaminerEvaluationFormResult;
};

export type ExaminerResult = {
  isSentInvitation: boolean;
  evaluationFormId: number;
  examinerId?: string | null;
  examinerEmail?: string | null;
};

export type ExaminerEvaluationFormResult = {
  id: number;
  jsonSchema: string;
  criteries: CriteriaResult[];
  evaluationFormDetails: (DetailsResult & { description: string })[];
};

export type ExaminerEvaluationFormValueResult = {
  id: number;
  programOrderExaminerId: number;
  evaluationPercentage: number;
  recommendations: string;
  formBuilderValue: string;
  files: FileResult[];
  criteriaValues: CriteriaValueResult[];
};

export type SubmittedExaminerOrderEvaluationFormRequest = {
  serialGuid: string;
  actionName: TaskActionsNames;
  evaluationPercentage: number; // place holder for UI
  recommendations: string;
  formBuilderValue: string;
  filesIds: string[];
  criteriaValuesRequest: CriteriaValueRequest[];
};

export type CriteriaValueRequest = {
  criteriaId: number;
  value: number;
  notes: string;
};

export type CriteriaResult = {
  id: number;
  criteriaDetail: DetailsResult;
  weight: number;
};

export type CriteriaValueResult = {
  id: number;
  criteriaId: number;
  value: number;
  notes: string;
  weight?: number;
  criteria: CriteriaResult;
  criteriaValueDetails: DetailsResult[];
};

export type RejectExaminerOrderEvaluationFormRequest = {
  actionName: TaskActionsNames;
  serialGuid: string;
};

export type ReturnExaminerOrderEvaluationFormRequest = {
  actionName: TaskActionsNames;
  serialGuid: string;
  recommendations: string;
};

export enum AccreditationType {
  Accreditation = 1,
  Reject,
}

export const AccreditationTypes = {
  Accreditation: {
    value: 1,
    label: "Accreditation",
  },
  Reject: {
    value: 2,
    label: "Reject",
  },
};

export type ExaminerListResult = {
  id: number;
  processInstanceId?: number | null;
  isSentInvitation: boolean;
  isConfirmFees: boolean;
  examinerName: string;
  deadLine?: string | Date | null;
  iteration?: number | null;
  evaluationPercentage: number;
  recommendations?: string;
  status?: string | null;
  examinerEvaluationFormValue: ExaminerEvaluationFormValueResult;
  accreditationType?: keyof typeof AccreditationType;
  evaluationForm: ExaminerEvaluationFormResult;
};

export type ReferralOfExaminersRequest = {
  examinersIds: number[];
  deadLine: Date;
  notes: string;
};

export type CreateProgramOrderExaminerRequest = {
  programOrderEvaluationId: number;
  examinerId: string | null;
  examinerEmail?: string | null;
};

export type ExaminerRewardApprovedRequest = {
  isConfirmFees: boolean;
  feesNotes: string;
};

export type UpdateExaminerAccreditationTypeRequest = {
  type: AccreditationType;
};
