import Modal from "components/blocks/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const AddEvaluationNoteModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (note: string) => void;
}) => {
  const { t } = useTranslation("Orders");
  const [note, setNote] = useState("");

  const onCancel = () => {
    onClose?.();
  };

  const onSubmit = async (notes: string) => {
    onConfirm(notes);
  };

  return (
    <Modal
      containerClassName="bg-white pb-2"
      isOpen={isOpen}
      onClose={onClose}
      title={t("addNote")}
      withHeaderBorder
      size="lg"
      titleClassName="fs-6 text-dark"
      bodyMargin="py-3"
    >
      <label className="form-label mt-2">{t("notes")}</label>
      <textarea
        className={`form-control`}
        rows={5}
        placeholder={t("notes")}
        onChange={(e) => setNote(e.target.value)}
        style={{ resize: "none" }}
      />

      <div className="divider mt-3"></div>

      <div className="d-flex justify-content-center gap-2 w-100">
        <button type="button" className="btn text-dark rounded-3 p-10-32P" onClick={onCancel}>
          {t("Common:cancel")}
        </button>
        <button
          type="button"
          className="btn btn-primary rounded-3 p-10-32P"
          disabled={!note.length}
          onClick={() => {
            if (!note.length) return;

            onSubmit(note);
          }}
        >
          {t("add")}
        </button>
      </div>
    </Modal>
  );
};

export default AddEvaluationNoteModal;
