import { useMenuContext } from "contexts/menu/menuContext";
import { useState, useCallback, ReactNode, createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

type BreadcrumbsParamsType = {
  localizationKey: string;
  path: string;
  localizationParameters?: Record<string, any>;
  menuItemId?:
    | "allOrders"
    | "programs"
    | "dashboard"
    | "allTasks"
    | "allResearchers"
    | "researcherPayments"
    | "allUnsignedOrders"
    | "tasks"
    | "evaluationForms"
    | "examinerRewards"
    | "publicPrograms"
    | "examinerCompletedTasks"
    | "mySignedWork"
    | "rewards";
};

interface BreadcrumbContextType {
  breadcrumbs: BreadcrumbsParamsType[];
  setBreadcrumbs: (breadcrumbs: BreadcrumbsParamsType[]) => void;
}

export const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

export const BreadcrumbProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [breadcrumbs, setBreadcrumbsState] = useState<BreadcrumbsParamsType[]>([]);
  const setBreadcrumbs = useCallback((breadcrumbs: BreadcrumbsParamsType[]) => {
    setBreadcrumbsState(breadcrumbs);
  }, []);

  return <BreadcrumbContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>{children}</BreadcrumbContext.Provider>;
};

export const useBreadcrumb = () => {
  const context = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  if (context === undefined) {
    throw new Error("useBreadcrumb must be used within a BreadcrumbProvider");
  }

  const { breadcrumbs, setBreadcrumbs } = context;
  const { setSelectedMenuItem } = useMenuContext();
  const onItemInPathClicked = useCallback(
    (index: number) => {
      const path = breadcrumbs[index].path;
      const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
      setBreadcrumbs(newBreadcrumbs);
      navigate(path);
    },
    [context],
  );

  useEffect(() => {
    const lastIndexOfBreadcrumbs = breadcrumbs.length - 1;
    if (lastIndexOfBreadcrumbs >= 0) {
      setSelectedMenuItem(breadcrumbs[lastIndexOfBreadcrumbs].menuItemId);
    }
  }, [breadcrumbs]);

  return { breadcrumbs, setBreadcrumbs, onItemInPathClicked };
};
