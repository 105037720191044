import "./WelcomePage.scss";
import ProgramsTaps from "./components/ProgramsTaps";
import NavBar from "./components/NavBar";
import { useTranslation } from "react-i18next";

const WelcomePageView = () => {
  const { t } = useTranslation("WelcomePage");

  return (
    <div className="w-100 bg-white d-flex flex-column">
      <div className="welcome-container text-white d-flex flex-column justify-content-between py-4 px-4rem ">
        <NavBar />
        <div className=" welcome-text-container d-flex flex-column gap-4 mb-5 align-items-start">
          <h1 className="fw-bold"> لوريم ايبسوم دولار سيت أميت كونسيكتيتور أدايبا يسكينج</h1>
          <p>
            أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس
            نوستريد
          </p>
          <button className="btn btn-primary p-10-32P rounded-3">{t("readMore")}</button>
        </div>
      </div>

      {/* programs */}
      <div className="programs-container px-4rem pt-4 pb-4rem text-dark">
        <h4 className="fw-bold">تمويل المشروعات البحثية</h4>
        <p>
          أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس
          نوستريد
        </p>
        <ProgramsTaps />
      </div>
    </div>
  );
};

export default WelcomePageView;
