import ReactDOM from "react-dom/client";
import "./assets/style/index.scss";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { localizationInit } from "./helpers";
import { CookieProvider } from "./contexts";
import { MenuProvider } from "./contexts/menu/menuContext";
import ToastContainer from "components/Toast/ToastContainer";
import { NotificationProvider } from "contexts/notification/NotificationContext";
import { BreadcrumbProvider } from "contexts/breadcrumb/BreadcrumbContext";

localizationInit();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <CookieProvider>
    <MenuProvider>
      <BreadcrumbProvider>
        <NotificationProvider>
          <I18nextProvider i18n={i18next}>
            <ToastContainer />
            <App />
          </I18nextProvider>
        </NotificationProvider>
      </BreadcrumbProvider>
    </MenuProvider>
  </CookieProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
