import FormInput from "components/blocks/FormInput";
import Modal from "components/blocks/Modal";
import { useNotification } from "hooks/useNotification";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SettingsService } from "services/settingsService";
import { FundingSettingConstants } from "shared/constants";

type RewardSettingsModalProps = {
  initialRewardAmount: number;
  isOpen: boolean;
  onClose: () => void;
};

const ExaminerRewardSettingsModal: React.FC<RewardSettingsModalProps> = ({ initialRewardAmount, isOpen, onClose }) => {
  const { t } = useTranslation("ExaminerRewards");
  const { showNotification } = useNotification();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [rewardAmount, setRewardAmount] = useState(initialRewardAmount);

  const handleRewardAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRewardAmount(Number(e.target.value));
    setError(undefined);
  };

  const handleSave = async () => {
    setLoading(true);
    // validate the reward amount
    if (rewardAmount < 0) {
      setError(t("invalidRewardAmount"));
      return;
    }
    setError(undefined);

    try {
      const response = await SettingsService.updateSettings({
        key: FundingSettingConstants.rewardAmount,
        value: rewardAmount.toString(),
      });
      if (!response.hasError) {
        showNotification({
          description: t("Common:success"),
          type: "success",
        });
        onClose();
      } else {
        showNotification({
          description: t(response.description),
          type: "error",
        });
      }
    } catch (error) {
      showNotification({
        description: t("Common:internalServerError"),
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen || loading}
      onClose={onClose}
      containerClassName="bg-white"
      size="lg"
      title={t("rewardAmount")}
      withHeaderBorder
      footer={
        <div className="d-flex gap-2 flex-grow-1 justify-content-center">
          <button className="btn btn-outline-secondary px-5" onClick={onClose}>
            {t("Common:cancel")}
          </button>
          <button className="btn btn-primary px-5" onClick={handleSave} disabled={loading}>
            {t("Common:save")} {loading && <Spinner size="sm" className="mx-2" />}
          </button>
        </div>
      }
    >
      <FormInput
        label={t("rewardAmount")}
        type="number"
        value={rewardAmount}
        onChange={handleRewardAmountChange}
        error={error}
      />
    </Modal>
  );
};

export default ExaminerRewardSettingsModal;
