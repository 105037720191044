import FormInput from "components/blocks/FormInput";
import IconButton from "components/blocks/IconButton";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type CriteriaElementTemplateProps = {
  onAdd: (item: { nameAr: string; nameEn: string; weight: number }) => void;
};

const CriteriaElementTemplate: React.FC<CriteriaElementTemplateProps> = ({ onAdd }) => {
  const { t } = useTranslation("EvaluationForms");
  const [errors, setErrors] = useState({
    nameAr: "",
    nameEn: "",
    weight: "",
  });
  const [itemData, setItemData] = useState({
    nameAr: "",
    nameEn: "",
    weight: 0,
  });

  const onSubmit = () => {
    const validationErrors = {
      nameAr: itemData.nameAr === "" ? t("Common:requiredField") : "",
      nameEn: itemData.nameEn === "" ? t("Common:requiredField") : "",
      weight: itemData.weight < 0 ? t("minWeight") : itemData.weight > 100 ? t("maxWeight") : "",
    };

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => error === "")) {
      onAdd(itemData);
      setItemData({
        nameAr: "",
        nameEn: "",
        weight: 0,
      });
    }
  };

  const onWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(+e.currentTarget.value)) {
      return;
    }

    if (+e.currentTarget.value < 0) {
      return;
    }

    if (+e.currentTarget.value > 100) {
      return;
    }

    setItemData({ ...itemData, weight: +e.target.value });
    setErrors({ ...errors, weight: "" });
  };

  return (
    <div
      className={`d-flex gap-4 mb-3 ${
        Object.values(errors).some((error) => error !== "") ? "align-items-center" : "align-items-end"
      }`}
    >
      <div className="row flex-grow-1">
        <FormInput
          wrapperClassName="col-md-4 col-sm-12"
          label={t("criteriaNameAr")}
          value={itemData.nameAr}
          onChange={(e) => {
            setItemData({ ...itemData, nameAr: e.target.value });
            setErrors({ ...errors, nameAr: "" });
          }}
          error={errors.nameAr}
        />
        <FormInput
          wrapperClassName="col-md-4 col-sm-12"
          label={t("criteriaNameEn")}
          value={itemData.nameEn}
          onChange={(e) => {
            setItemData({ ...itemData, nameEn: e.target.value });
            setErrors({ ...errors, nameEn: "" });
          }}
          error={errors.nameEn}
        />
        <FormInput
          wrapperClassName="col-md-4 col-sm-12"
          label={t("weight")}
          value={itemData.weight}
          onChange={onWeightChange}
          error={errors.weight}
          leftElement={<span className="fs-24px text-primary">%</span>}
        />
      </div>
      <IconButton size="lg" variant="primary" icon="icon-plus" onClick={onSubmit} />
    </div>
  );
};

export default CriteriaElementTemplate;
