import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FormInput from "components/blocks/FormInput";
import { useTranslation } from "react-i18next";
import "./Procedures.scss";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import OrderActionsGrid from "views/home/orders/OrderActionsGrid";
import { useParams } from "react-router-dom";
import { OrderUserTypes } from "../../..";

type ProcedureType = {
  data: {
    notes: string;
    phase: string;
    action: string;
    userName: string;
    date: string;
    isActive: boolean;
    isCompleted: boolean;
  }[];
  notes: string; // TODO: is it part of the submission data?
};

const Procedures = ({ orderId, userType }: { orderId: number; userType: keyof typeof OrderUserTypes }) => {
  const { t, i18n } = useTranslation("Orders");
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const procedures: ProcedureType = {
    data: [
      {
        notes: "الملاحظات",
        phase: "التفديم على البرنامج",
        action: "ارسال",
        userName: "اسم المستخدم",
        date: toSystemThemeDateFormat(new Date()),
        isActive: false,
        isCompleted: true,
      },
      {
        notes: "الملاحظات",
        phase: "تعيين فاحصين",
        action: "الدور",
        userName: "اسم المستخدم",
        date: toSystemThemeDateFormat(new Date()),
        isActive: true,
        isCompleted: false,
      },
      {
        notes: "الملاحظات",
        phase: "تقييم طلب",
        action: "مدير مركز",
        userName: "اسم المستخدم",
        date: toSystemThemeDateFormat(new Date()),
        isActive: false,
        isCompleted: false,
      },
      {
        notes: "الملاحظات",
        phase: "تقييم طلب",
        action: "مدير مركز",
        userName: "اسم المستخدم",
        date: toSystemThemeDateFormat(new Date()),
        isActive: false,
        isCompleted: false,
      },
      {
        notes: "الملاحظات",
        phase: "تقييم طلب",
        action: "مدير مركز",
        userName: "اسم المستخدم",
        date: toSystemThemeDateFormat(new Date()),
        isActive: false,
        isCompleted: false,
      },
      {
        notes: "الملاحظات",
        phase: "تقييم طلب",
        action: "مدير مركز",
        userName: "اسم المستخدم",
        date: toSystemThemeDateFormat(new Date()),
        isActive: false,
        isCompleted: false,
      },
      {
        notes: "الملاحظات",
        phase: "تقييم طلب",
        action: "مدير مركز",
        userName: "اسم المستخدم",
        date: toSystemThemeDateFormat(new Date()),
        isActive: false,
        isCompleted: false,
      },
    ],
    notes: "notes",
  };

  return (
    <Accordion>
      <AccordionItem initiallyOpen title={t("procedures")} elementId={3}>
        <div className="pb-2">
          <OrderActionsGrid
            orderId={orderId}
            visibleColumns={["notes", "workflowName", "action", "appliedBy", "createdOn"]}
          />

          <FormInput placeholder={t("notes")} label={t("notes")} />

          {userType === OrderUserTypes.Admin && (
            <div className="action-stepper gap-1">
              {procedures.data.map((procedure, i) => {
                return (
                  <div key={i} className="step-container d-flex align-items-center ">
                    <div className="step gap-2" key={i}>
                      <div
                        className={`status-circle ${procedure.isActive ? "active" : ""} ${
                          procedure.isCompleted ? "completed" : ""
                        }`}
                      ></div>

                      <div>
                        <div className="fs-14px fw-bold">
                          {procedure.phase}
                          <span className="text-primary fs-12px"> ({t("action")})</span>
                          {/* TODO: ask about this */}
                        </div>
                        <div className="fs-12px text-gray">
                          {procedure.action} : {procedure.userName}
                        </div>
                        <div className="fs-12px text-gray">{toSystemThemeDateFormat(procedure.date, false)}</div>
                      </div>
                    </div>
                    {i === procedures.data.length - 1 ? null : (
                      <div className={`mx-3 text-gray ${i18n.language === "en" ? "rotate-icon" : ""}`}>
                        <i className="icon-arrow-left-with-tile "></i>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default Procedures;
