import type { RefereeSignUpFormType } from "../register.schema";
import { useTranslation } from "react-i18next";
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import FormInput from "components/blocks/FormInput";
import { useEffect, useState } from "react";
import { LookupService } from "services/lookupService";
import { DegreeResultResponse, JobResultResponse } from "services/lookupService/models/LookupsType";
import { useCookieContext } from "contexts";
import Dropdown, { DropdownItemType } from "components/blocks/Dropdown";
import UserTypeGuideModal from "./UserTypeGuideModal";
import "../registerView.scss";

type ProfessionalInfoProps = {
  getFormProps: UseFormRegister<RefereeSignUpFormType>;
  formErrors: FieldErrors<RefereeSignUpFormType>;
  watch: UseFormWatch<RefereeSignUpFormType>;
  setValue: UseFormSetValue<RefereeSignUpFormType>;
};

const ProfessionalInfo = ({ getFormProps, formErrors, watch, setValue }: ProfessionalInfoProps) => {
  const { t } = useTranslation("RegisterView");
  const { isAr } = useCookieContext();

  const [isUserTypeGuideModalOpen, setIsUserTypeGuideModalOpen] = useState(false);
  const [JobTitleDdl, setJobTitleDdl] = useState<JobResultResponse[]>([]);
  const [degreeDdl, setDegreeDdl] = useState<DegreeResultResponse[]>([]);
  const userTypesDdl: DropdownItemType[] = [
    { value: t("postgraduateStudent"), id: 6 },
    { value: t("specialist"), id: 7 },
  ];

  const initializeAllDropDowns = async () => {
    const [jobsRes, degreesRes] = await Promise.all([LookupService.getJobs(), LookupService.getDegrees()]);

    setJobTitleDdl(jobsRes.data);
    setDegreeDdl(degreesRes.data);
  };

  useEffect(() => {
    initializeAllDropDowns();
  }, [isAr]);

  return (
    <>
      <div className="row">
        <div className="form-group my-2 col-6">
          <Dropdown
            noSelectionPlaceholder={t("userType")}
            label={
              <div className="d-flex align-items-center justify-content-between">
                <label>{t("userType")}</label>
                <div role="button" className="user-type-guide-button" onClick={() => setIsUserTypeGuideModalOpen(true)}>
                  <span>!</span>
                </div>
              </div>
            }
            data={userTypesDdl}
            error={formErrors.userType?.message}
            value={watch("userType")}
            onChange={(value) => {
              setValue("userType", +value);
            }}
            useReactSelect
          />
        </div>
        <div className="form-group my-2 col-6">
          <Dropdown
            noSelectionPlaceholder={t("job")}
            label={t("job")}
            data={JobTitleDdl.map((item) => ({
              value: item.jobDetail.displayName,
              id: item.id,
            }))}
            error={formErrors.jobId?.message}
            value={watch("jobId")}
            onChange={(value) => {
              setValue("jobId", +value);
            }}
            useReactSelect
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group my-2 col-6">
          <FormInput
            type="text"
            placeholder={t("specialization")}
            label={t("specialization")}
            inputClassName="form-control"
            error={formErrors.specialization?.message}
            {...getFormProps("specialization")}
          />
        </div>
        <div className="form-group my-2 col-6">
          <FormInput
            type="text"
            label={t("university")}
            inputClassName="form-control"
            placeholder={t("university")}
            error={formErrors.university?.message}
            {...getFormProps("university")}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group my-2 col-6">
          <Dropdown
            noSelectionPlaceholder={t("degree")}
            label={t("degree")}
            data={degreeDdl.map((item) => ({
              value: item.degreeDetail.displayName,
              id: item.id,
            }))}
            error={formErrors.degreeId?.message}
            value={watch("degreeId")}
            onChange={(value) => {
              setValue("degreeId", +value);
            }}
            useReactSelect
          />
        </div>
      </div>

      <UserTypeGuideModal isOpen={isUserTypeGuideModalOpen} onClose={() => setIsUserTypeGuideModalOpen(false)} />
    </>
  );
};

export default ProfessionalInfo;
