import IconButton from "components/blocks/IconButton";
import { Link } from "react-router-dom";
import { OrderStatus } from "shared/lookups";
import { OrderUserTypes, TapsIds, tabsIds } from "..";
import { OpenFundingTaskResult } from "services/tasks/models/tasksTypes";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import { useTranslation } from "react-i18next";
import useFundingUnitActions from "./useFundingUnitActions";
import ReturnOrderToCenterModal from "../OrderTaskActionModals/ReturnOrderToCenterModal";
import useDeanOfScientificResearch from "./useDeanOfScientificResearch";
import ReturnOrderContractModal from "../OrderTaskActionModals/ReturnOrderContractModal";
import { EvaluateProgramUpdateRef } from "../Tabs/OrderInfoTab/components/Updates/useProgramOrderUpdateActions";
import ResearcherRequestActions from "./ResearcherRequestActions";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import useCloseRequestActions, { ApproveByCenterManagerSubmitHandlerRef } from "./useCloseRequestActions";
import CloseRequestModal from "../OrderTaskActionModals/CloseRequestModal";

type PageActionsProps = {
  selectedTabId: string;
  order: OrderLocalItemResult | null;
  openedTask: OpenFundingTaskResult | null;
  programOrderTaskState:
    | {
        actions: string[];
        canReturn: boolean;
        orderId: number;
      }
    | undefined;
  onActionClicked: (action: "return" | "respond") => void;
  onSave: (selectedTabId: TapsIds) => void;
  userType: keyof typeof OrderUserTypes;
  programOrderId: number;
  serialGuid: string;
  programUpdateHandlersRef: React.MutableRefObject<EvaluateProgramUpdateRef | null>;
  program: ProgramByIDResponse | null;
  approveByCenterManagerSubmitHandlerRef?: React.MutableRefObject<ApproveByCenterManagerSubmitHandlerRef | null>;
};

const PageActions = ({
  selectedTabId,
  order,
  openedTask,
  programOrderTaskState,
  onActionClicked,
  onSave,
  userType,
  programOrderId,
  serialGuid,
  programUpdateHandlersRef,
  program,
  approveByCenterManagerSubmitHandlerRef,
}: PageActionsProps) => {
  const { t } = useTranslation(["Orders", "Shared"]);
  const isFundingUnit = userType === OrderUserTypes.FundingUnit;
  const isResearcherCenterManager = userType === OrderUserTypes.ResearchCenterManager;
  const isDeanOfScientificResearch = userType === OrderUserTypes.DeanOfScientificResearch;

  const {
    confirmFundingUnitLoading,
    onConfirmFundingUnit,
    onReturnOrderToCentreManagement,
    returnOrderToCentreManagementLoading,
    isReturnToCenterModalOpen,
    setIsReturnToCenterModalOpen,
  } = useFundingUnitActions({ programOrderId, serialGuid });

  const {
    deanOfScientificResearchLoading,
    isReturnContractModalOpen,
    onAcceptSignContract,
    onReturnContract,
    returnContractLoading,
    setIsReturnContractModalOpen,
  } = useDeanOfScientificResearch({ programOrderId, serialGuid });

  const {
    onReturn,
    onApprove,
    onReject,
    onComplete,
    loading,
    closeRequestModalProps,
    setCloseRequestModalProps,
    userCloseActions,
  } = useCloseRequestActions({
    programOrderCloseId: openedTask?.programOrderCloseId ?? order?.closeOrder?.id ?? 0,
    serialGuid,
    userType,
    approveByCenterManagerSubmitHandlerRef,
  });

  return (
    <>
      {[tabsIds.programMinutesTab, tabsIds.speechesTab, tabsIds.additionalAttachmentsTab].includes(
        selectedTabId as TapsIds,
      ) && (
        <button
          key="save"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSave(selectedTabId as TapsIds);
          }}
          className="btn btn-primary btn-sm rounded"
        >
          {t("save")}
        </button>
      )}
      {isResearcherCenterManager && programOrderTaskState && (
        <>
          {order?.isInspectMode && programOrderTaskState.actions.length > 0 && (
            <button className="btn btn-primary px-3" onClick={() => onActionClicked("respond")}>
              {t("sendToTheFundingUnit")}
            </button>
          )}
          {programOrderTaskState.canReturn && (
            // TODO: check the other ''return'' type - check figma
            <button
              className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
              onClick={() => onActionClicked("return")}
            >
              <i className="icon-turn-order" />
              {t("returnOrder")}
            </button>
          )}
        </>
      )}

      {isFundingUnit && selectedTabId === tabsIds.orderInfoTab && (
        <>
          {openedTask?.actionName.includes("Finish") && (
            <button
              disabled={returnOrderToCentreManagementLoading || confirmFundingUnitLoading}
              className="btn btn-primary px-3"
              onClick={() => onConfirmFundingUnit()}
            >
              {t("approval")}
            </button>
          )}
          {openedTask?.actionName.includes("Return") && (
            <button
              type="button"
              disabled={returnOrderToCentreManagementLoading || confirmFundingUnitLoading}
              className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
              onClick={() => setIsReturnToCenterModalOpen(true)}
            >
              <i className="icon-turn-order" />
              {t("returnOrderToCentreManagement")}
            </button>
          )}
        </>
      )}

      {isDeanOfScientificResearch && (
        <>
          {openedTask?.actionName.includes("Approve") && (
            <button
              disabled={deanOfScientificResearchLoading || returnContractLoading}
              className="btn btn-primary px-3"
              onClick={() => onAcceptSignContract()}
            >
              {t("approval")}
            </button>
          )}
          {openedTask?.actionName.includes("Return") && (
            <button
              type="button"
              disabled={returnContractLoading || deanOfScientificResearchLoading}
              className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
              onClick={() => setIsReturnContractModalOpen(true)}
            >
              <i className="icon-turn-order" />
              {t("returnOrder")}
            </button>
          )}
        </>
      )}

      {userCloseActions && (
        <>
          {openedTask?.actionName.includes("Approve") && (
            <button className="btn btn-primary px-3" onClick={() => onApprove()} disabled={loading}>
              {t("approve", { placeholder: "" })}
            </button>
          )}
          {openedTask?.actionName.includes("Complete") && (
            <button
              className="btn btn-primary px-3"
              onClick={() => {
                setCloseRequestModalProps({
                  isOpen: true,
                  actionType: "complete",
                });
              }}
              disabled={loading}
            >
              {t("ApprovalOfTheDisbursementAndAttachmentOfTheReceipt")}
            </button>
          )}
          {openedTask?.actionName.some((ele) => ele.toLowerCase().includes("return")) && (
            <button
              className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
              onClick={() => {
                setCloseRequestModalProps({
                  isOpen: true,
                  actionType: "return",
                });
              }}
              disabled={loading}
            >
              <i className="icon-turn-order " />
              {t("returnTo", {
                placeholder: t(userCloseActions.returnToTitleKey ?? ""),
              })}
            </button>
          )}
          {openedTask?.actionName.includes("Reject") && (
            <button
              className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
              onClick={() => {
                setCloseRequestModalProps({
                  isOpen: true,
                  actionType: "reject",
                });
              }}
              disabled={loading}
            >
              {t("reject", { placeholder: t("request") })}
            </button>
          )}
        </>
      )}

      <ResearcherRequestActions
        openedTask={openedTask}
        programUpdateHandlersRef={programUpdateHandlersRef}
        serialGuid={serialGuid}
        userType={userType}
        order={order}
        program={program}
      />
      {order?.orderStatusId === OrderStatus.Draft && (
        <>
          <Link to={`/home/orders/edit/${openedTask?.orderId}`}>
            <IconButton
              key="edit"
              icon="icon-edit"
              size="md"
              className="btn action-btn btn-sm rounded p-2"
              variant="light"
              innerIconColor="secondary"
            />
          </Link>
          <IconButton
            key="delete"
            icon="icon-delete"
            size="md"
            className="btn action-btn btn-sm rounded p-2"
            variant="light"
            innerIconColor="danger"
          />
        </>
      )}

      <ReturnOrderToCenterModal
        isOpen={isReturnToCenterModalOpen}
        onClose={() => setIsReturnToCenterModalOpen(false)}
        isSubmitting={returnOrderToCentreManagementLoading}
        onSubmit={onReturnOrderToCentreManagement}
      />

      <ReturnOrderContractModal
        isOpen={isReturnContractModalOpen}
        onClose={() => setIsReturnContractModalOpen(false)}
        isSubmitting={returnContractLoading}
        onSubmit={onReturnContract}
      />

      <CloseRequestModal
        isSubmitting={loading}
        type={closeRequestModalProps.actionType}
        isOpen={closeRequestModalProps.isOpen}
        returnToTitleKey={userCloseActions?.returnToTitleKey ?? ""}
        onClose={() => setCloseRequestModalProps({ isOpen: false, actionType: closeRequestModalProps.actionType })}
        onSubmit={async (notes, receiptId) => {
          if (closeRequestModalProps.actionType === "return") {
            await onReturn(notes);
          } else if (closeRequestModalProps.actionType === "complete") {
            await onComplete(notes, receiptId!);
          } else {
            await onReject(notes);
          }
        }}
      />
    </>
  );
};
export default PageActions;
