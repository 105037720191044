import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { ReferralOfExaminersRequest } from "services/examiners/Model/ExaminerTypes";
import { z } from "zod";

export const getReferExaminerSchema = (t: any) => {
  const schema = z.object({
    deadLine: z.date({
      message: t("Common:invalidValue"),
    }),
    examinersIds: z.array(z.number().min(1, t("Common:invalidValue"))).min(1, t("pleaseSelectAtLeastOneExaminer")),
    notes: z.string().optional(),
  }) as z.ZodType<ReferralOfExaminersRequest>;

  return zodResolver(schema);
};
