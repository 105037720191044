import FileInput from "components/blocks/FileInput";
import FilePreview from "components/blocks/FileInput/FilePreview";
import { SelectedFilesType } from "components/blocks/FileUploadWithPreview";
import useMutation from "hooks/useMutation";
import { UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FileService } from "services/fileService";
import { CreateProgramOrderUpdateRequest } from "services/programOrderUpdate/models";

const ResearcherFileUpdate = ({
  selectedFile,
  setSelectedFile,
  setValue,
}: {
  selectedFile?: SelectedFilesType;
  setSelectedFile: (files?: SelectedFilesType) => void;
  setValue: UseFormSetValue<CreateProgramOrderUpdateRequest>;
}) => {
  const { t } = useTranslation("Common");

  const { mutateAsync: uploadFileAsync, loading: fileUploadLoading } = useMutation({
    queryFn: async (values: File) => {
      return await FileService.uploadFile(values);
    },
  });

  const { mutateAsync: fileDownload, loading: fileDownloadLoading } = useMutation({
    queryFn: async (id: string) => {
      await FileService.downloadFile(id);
      return { hasError: false, data: null, description: "", code: 200 };
    },
  });

  const onFileUpload = async (file: File) => {
    const response = await uploadFileAsync(file);
    if (response && !response.hasError) {
      setSelectedFile({
        fileName: response.data!.fileName,
        uuid: response.data!.uuid,
        file,
        size: `${(file.size / 1024 / 1024).toFixed(2)}MB`,
      });
      setValue("fileId", response.data!.uuid);
    }
  };

  return (
    <div className="d-flex gap-1 flex-2">
      {fileUploadLoading ? (
        <div className="flex-1 d-flex align-items-center mt-2">
          <div className="custom-loader "></div>
        </div>
      ) : (
        !selectedFile && (
          <FileInput
            labelClassName="fw-bold"
            label={t("Common:attachments")}
            clickHereForLabel={t("Common:toAttachFile")}
            containerWrapperClassName="flex-grow-1"
            onChange={(files) => {
              files && onFileUpload(files[0]);
            }}
          />
        )
      )}

      {selectedFile && (
        <div className="d-flex flex-column flex-1">
          <label className="fw-bold invisible mb-1">{t("Common:attachedFiles")}</label>
          <FilePreview
            key={selectedFile.uuid}
            loading={fileDownloadLoading}
            onDelete={() => {
              setSelectedFile(undefined);
            }}
            onShow={async () => {
              await fileDownload(selectedFile.uuid);
            }}
            file={selectedFile.file}
            wrapperClassName="p-10px flex-1"
          />
        </div>
      )}
    </div>
  );
};

export default ResearcherFileUpdate;
