import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { CreateProgramOrderUpdateRequest } from "services/programOrderUpdate/models";
import { z } from "zod";

export const getResearcherUpdateFormSchema = (t: any) => {
  const schema = z.object({
    fileId: z.string().min(1, t("invalidValue")),
    completionRate: z.number().min(1, t("invalidValue")).max(100, t("invalidValue")),
    details: z.string().min(1, t("invalidValue")),
    programOrderId: z.number().min(1, t("invalidValue")),
  }) as z.ZodType<CreateProgramOrderUpdateRequest>;

  return zodResolver(schema);
};
