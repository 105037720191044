import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FilePreview from "components/blocks/FileInput/FilePreview";
import FileUploadWithPreview from "components/blocks/FileUploadWithPreview";
import { useEffect, useRef, useState } from "react";
import { FileUploadResponse } from "services/shared-models/sharedTypes";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import { useTranslation } from "react-i18next";
import PaginatedLookupDropdown from "components/blocks/PaginatedLookupDropdown";
import { OrdersService } from "services/orders";
import { useNotification } from "hooks/useNotification";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import ExaminersTable from "./ExaminersTable";
import EvaluationFormPreviewModal from "views/home/evaluationForms/EvaluationFormPreviewModal";

export type ExaminerSelectedTemplateType = {
  title: string;
  id: number;
};

const ExaminersSection = ({ order }: { order: OrderLocalItemResult | null }) => {
  const { t } = useTranslation(["Orders"]);
  const { showNotification } = useNotification();

  const [evaluationTemplateModalVisible, setEvaluationTemplateModalVisible] = useState(false);
  const [updatingProgramEvaluationOrderIsLoading, setUpdatingProgramEvaluationOrderIsLoading] = useState(false);
  const [files, setFiles] = useState<(FileUploadResponse & { file: File; size?: string })[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<ExaminerSelectedTemplateType>({
    title: "",
    id: order?.programOrderEvaluation?.evaluationFormId ?? 0,
  });

  useEffect(() => {
    if (order?.programOrderEvaluation) {
      setSelectedTemplate({
        title: order?.programOrderEvaluation.evaluationForm?.evaluationFormDetail.displayName ?? t("Common:noData"),
        id: order?.programOrderEvaluation.evaluationFormId,
      });

      setFiles([
        ...order.programOrderEvaluation.files.map((file) => ({
          file: new File([], file.name),
          uuid: file.id,
          fileName: file.name,
          size: file.size,
        })),
        ...order.files.map((file) => ({
          file: new File([], file.name!),
          uuid: file.id!,
          fileName: file.name!,
          size: file.size,
        })),
      ]);
    }
  }, [order?.programOrderEvaluation]);

  const updateProgramEvaluationOrder = async ({
    evaluationFormId,
    filesIds,
  }: {
    evaluationFormId: number;
    filesIds: string[];
  }) => {
    if (!order?.programOrderEvaluation) return;

    try {
      setUpdatingProgramEvaluationOrderIsLoading(true);
      const response = await OrdersService.updateProgramEvaluationOrder(order?.programOrderEvaluation.id, {
        evaluationFormId: evaluationFormId,
        filesIds: filesIds,
      });
      if (response.hasError) {
        showNotification({
          description: t(response.description),
          type: "error",
        });
      } else {
        showNotification({
          description: t("Common:success"),
          type: "success",
        });
      }
    } catch {
      showNotification({
        description: t("Common:internalServerError"),
        type: "error",
      });
    } finally {
      setUpdatingProgramEvaluationOrderIsLoading(false);
    }
  };

  return (
    <>
      <Accordion>
        <AccordionItem initiallyOpen title={t("examiners")} elementId={13}>
          <div className="d-flex flex-column gap-4 border-bottom pb-4">
            <div className="d-flex flex-column gap-4 position-relative">
              <LoadingOverlay visible={updatingProgramEvaluationOrderIsLoading} />
              <PaginatedLookupDropdown
                label={t("evaluationTemplate")}
                service="evaluationFormsService"
                endpoint="getAllEvaluationForms"
                queryKey="search"
                idValueKey="id"
                textValueKey="evaluationFormDetail.displayName"
                isPaginated
                value={selectedTemplate?.id}
                onChange={(id, item) => {
                  setSelectedTemplate({
                    title: item.label,
                    id: +id,
                  });

                  updateProgramEvaluationOrder({
                    evaluationFormId: +id,
                    filesIds: files.map((file) => file.uuid),
                  });
                }}
              />

              {selectedTemplate && (
                <FilePreview
                  title={selectedTemplate.title}
                  callbackValue={selectedTemplate.id}
                  onShow={() => {
                    setEvaluationTemplateModalVisible(true);
                  }}
                  showFileSize={false}
                  hideDeleteButton
                />
              )}

              {selectedTemplate && (
                <FileUploadWithPreview
                  files={files}
                  setFiles={setFiles}
                  onChange={async (files) => {
                    if (selectedTemplate)
                      await updateProgramEvaluationOrder({
                        evaluationFormId: selectedTemplate.id,
                        filesIds: files.map((file) => file.uuid),
                      });
                  }}
                  formProps={{}}
                />
              )}
            </div>

            <ExaminersTable
              orderId={order?.id ?? 0}
              fundingUnitNotes={order?.fundingUnitNotes ?? ""}
              programOrderEvaluationId={order?.programOrderEvaluation?.id ?? 0}
              selectedTemplate={selectedTemplate}
            />
          </div>
        </AccordionItem>
      </Accordion>

      <EvaluationFormPreviewModal
        evaluationFormId={selectedTemplate.id}
        title={selectedTemplate.title}
        isOpen={evaluationTemplateModalVisible}
        onClose={() => setEvaluationTemplateModalVisible(false)}
        size="lg"
      />
    </>
  );
};

export default ExaminersSection;
