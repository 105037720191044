import { CloseOrderResult } from "services/closeOrder/models";
import { ExaminerListResult } from "services/examiners/Model/ExaminerTypes";
import { ProgramDetailsResult } from "services/programs/models/ProgramTypes";
import {
  CentreManagementDetailsResult,
  FileResult,
  Locale,
  PagedResponseType,
} from "services/shared-models/sharedTypes";

export interface OrderLocalResult {
  id: number;
  serialNumber?: string;
  programDetail: ProgramDetailsResult;
  appliedUserName?: string;
  createdOn: string;
  centreManagementDetail: CentreManagementDetailsResult;
  orderPhase: string;
  centreManagerName: string;
  orderStatus: string;
  orderStatusId: number;
  finalCommitteeDecision?: string;
}

export interface CreateOrderRequest {
  programId: number;
  centreManagementId: number;
  formSubject?: string;
  formValue?: string;
  isFormValueReady: boolean;
  isAcceptedConditionAndRules: boolean;
  isAcceptedCommitments: boolean;
  filesIds: string[];
  researchFieldId: number;
  subspecialty: string;
  isDraft: boolean;
  serialGuid?: string;
  actionName?: string;
}

export interface OrderResult {
  programId: number;
  centreManagementId: number;
  formSubject?: string;
  formValue?: string;
  isAcceptedConditionAndRules: boolean;
  isAcceptedCommitments: boolean;
}

export interface FinancingTypesDetailsResult {
  displayName: string;
  locale?: string;
}

export interface CalculationWayResult {
  calculationWayDetail: {
    displayName: string;
    locale?: string;
  };
}

export interface FinancingTypesResult {
  id: number;
  financingTypesDetail: FinancingTypesDetailsResult;
  calculationWay: CalculationWayResult;
  repetitionAllowed?: number;
}

export interface UserResult {
  id: string;
  arabicName?: string;
  englishName?: string;
  employeeId?: string;
  phoneNumber: string;
  email: string;
  username: string;
  userType?: number;
  createdOn?: string;
  department?: string;
  college?: string;
  specialization?: string;
  degree?: string;
  job?: string;
}

export interface OrderLocalItemResult {
  id?: number;
  serialNumber?: string;
  createdOn: string;
  contractStartDate: string;
  centreManagementDetail?: CentreManagementDetailsResult;
  centreManagement?: CentreManagementDetailsResult;
  showProgressUpdateForm?: boolean;
  researchField: {
    displayName: string;
    locale?: string;
  };
  subspecialty: string;
  fundingAmount: number;
  fundingDurationInMonths: number;
  financingTypes?: FinancingTypesResult;
  adsEndDate: string;
  appliedUser?: UserResult;
  formSubject?: string;
  formValue?: string;
  files: FileResult[];
  fundingUnitNotes?: string;

  isInspectMode?: boolean;
  notes?: string;
  programId: number;
  centreManagementId: number;
  orderStatusId: number;
  orderPhaseId: number;
  appliedUserId: string;
  researchFieldId?: number;
  isAcceptedConditionAndRules: boolean;
  isAcceptedCommitments: boolean;
  processId: number;
  updateRequestNotes?: string;
  fundingPeriodInMonth?: number;
  extendedPeriodInMonth?: number;
  extendedRequestNotes?: string;
  apologyRequestNotes?: string;
  centralManagementIQueryNotes?: string;
  inQueryNotes?: string;
  appliedDate?: string;
  researcherNotes?: string;
  dsrNotes?: string;

  programOrderEvaluation: {
    id: number;
    evaluationFormId: number;
    evaluationForm: {
      id: number;
      evaluationFormDetail: {
        displayName: string;
        description: string;
        locale: Locale;
      };
      createdOn: string;
    };
    files: {
      id: string;
      name: string;
      size: string;
      type: string;
      extension: string;
    }[];
  };
  selectedProgramOrderExaminers?: SelectedProgramOrderExaminerResult[];
  closeOrder?: CloseOrderResult;
}

export type SelectedProgramOrderExaminerResult = {
  programOrderExaminerId: number;
  programOrderId: number;
  showRecommendation: boolean;
  showFiles: boolean;
  programOrderExaminer: ExaminerListResult;
  programOrder: ProgramOrderLocalResult;
};

export type GetAllOrdersResponse = PagedResponseType<OrderLocalResult>;

export interface CentreManagementLocalResult {
  id: number;
  centreManagementDetail: CentreManagementDetailsResult;
  centreManagerId: string;
  centreManagerName?: string;
  createdOn?: string;
  isActive: boolean;
  updatedBy: string;
  updatedOn?: string;
  order?: number;
}

export interface CreateProgramMinuteRequest {
  programOrderId: number;
  programMinutesNumber: string;
  date: Date;
  description: string;
  files: string[];
  users: string[];
}

export interface ProgramMinutesResult {
  id: number;
  programOrderId: number;
  programMinutesNumber: string;
  date: string;
  description: string;
  users: string[];
  files: string[];
}

export interface CreateProgramSpeechesRequest {
  programOrderId: number;
  pdfTemplateId: number;
  description: string;
  selectedLocale: Locale;
}

export interface AdditionalAttachmentsResult {
  id: number;
  fileId: string;
  name: string;
  size: string;
  type: string;
  extension: string;
  description: string;
}

export interface CreateAdditionalAttachmentRequest {
  fileId: string;
  programOrderId: number;
  description: string;
}

export interface ProgramSpeechesResult {
  id: number;
  programOrderId: number;
  pdfTemplateDetail: {
    displayName: string;
    description: string;
    templateContent?: string;
    locale?: "En" | "Ar";
  };
  description: string;
  selectedLocale: string;
  createdBy: string;
  createdOn: string;
  pdfTemplateId: number;
  fileId: string;
}

export type PdfTemplateLocalResult = {
  id: number;
  pdfTemplateDetail: {
    displayName: string;
    description: string;
    templateContent?: string;
    locale?: "En" | "Ar";
  };
  isActive: boolean;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
  order: number;
};

export type UserProgramOrdersResult = {
  appliedUserName: string;
  degree?: string;
  college?: string;
  specialization?: string;
  email: string;
  phoneNumber: string;
  centreManagementName: string;
};

export type ProgramOrderLocalResult = {
  id: number;
  programId: number;
  programSerialNumber: string;
  programDetail: ProgramDetailsResult;
  orderSerialNumber: string;
  createdOn: Date | string;
  orderStatus: string;
  orderPhase: string;
  finalCommitteeDetermination: string;
  formSubject: string;
  centreManagementDetail: {
    displayName: string;
    locale: Locale;
  };
  orderStatusId: number;
};

export type GetAllMyProgramsOrderSignedStatusResult = ProgramOrderLocalResult;

export enum OrderActionEnum {
  Applier = 1,
}

export interface OrderActionResult {
  notes?: string;
  action: OrderActionEnum;
  processInstanceId: number;
  folio: string;
  workflowName: string;
  appliedBy: {
    id: string;
    arabicName: string;
    englishName: string;
    phoneNumber: string;
    email: string;
    username: string;
    createdOn: Date | string;
  };
  createdOn: Date | string;
  status: string;
}

export interface UpdateProgramEvaluationOrderRequest {
  evaluationFormId: number;
  filesIds: string[];
}

export type ReturnOrderRequest = {
  actionName: string;
  serialGuid: string;
  selectedProgramOrderExaminers: SelectedProgramOrderExaminerRequest[];
  notes?: string;
};

export type SelectedProgramOrderExaminerRequest = {
  programOrderExaminerId: number;
  showFiles: boolean;
  showRecommendation: boolean;
};

export type SendToFinanceUnitRequest = {
  actionName: string;
  serialGuid: string;
  recommendations: string;
  isAccept: boolean;
};

export type ConfirmFundingUnitRequest = {
  actionName: string;
  serialGuid: string;
  isAccept: boolean;
};

export type ReturnFundingUnitRequest = {
  actionName: string;
  serialGuid: string;
  isAccept: boolean;
  fundingUnitNotes: string;
};

export enum FinalCommitteeDecisionType {
  Primary = 1,
  Secondary,
}

export const FinalCommitteeDecisionLookup: {
  [key: string]: {
    id: FinalCommitteeDecisionType;
    label: string;
  };
} = {
  Primary: {
    id: 1,
    label: "Primary",
  },
  Secondary: {
    id: 2,
    label: "Secondary",
  },
};

export type WorkFlowTaskRequest = {
  actionName: string;
  serialGuid: string;
  notes?: string;
};

export type WorkFlowTaskWithFilesRequest = WorkFlowTaskRequest & {
  filesIds: string[];
};

export type ProgramExtendTimeRequest = {
  notes: string;
  extendedTime: number;
};
export type ProgramApologiesRequest = {
  notes: string;
};
export type ProgramOrderUpdateRequest = {
  notes: string;
};
