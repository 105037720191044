import FormInput from "components/blocks/FormInput";
import IconButton from "components/blocks/IconButton";
import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CreateEvaluationFormRequest } from "./evaluationForm.schema";

type CriteriaElementProps = {
  index: number;
  onDelete: (index: number) => void;
  register: UseFormRegister<CreateEvaluationFormRequest>;
  errors: FieldErrors<CreateEvaluationFormRequest>;
};

const CriteriaElement: React.FC<CriteriaElementProps> = ({ index, onDelete, register, errors }) => {
  const { t } = useTranslation("EvaluationForms");

  const onSubmit = () => {
    // TODO: show confirmation
    onDelete(index);
  };

  return (
    <div className="d-flex gap-4 align-items-end mb-3">
      <div className="row flex-grow-1">
        <FormInput
          wrapperClassName="col-md-4 col-sm-12"
          label={t("criteriaNameAr")}
          {...register(`criteries.${index}.criteriaDetails.0.displayName`)}
          error={t(errors.criteries?.[index]?.criteriaDetails?.[0]?.displayName?.message ?? "")}
        />
        <FormInput
          wrapperClassName="col-md-4 col-sm-12"
          label={t("criteriaNameEn")}
          {...register(`criteries.${index}.criteriaDetails.1.displayName`)}
          error={t(errors.criteries?.[index]?.criteriaDetails?.[1]?.displayName?.message ?? "")}
        />
        <FormInput
          wrapperClassName="col-md-4 col-sm-12"
          label={t("weight")}
          leftElement={<span className="fs-24px text-primary">%</span>}
          {...register(`criteries.${index}.weight`, { valueAsNumber: true })}
          error={t(errors.criteries?.[index]?.weight?.message ?? "")}
        />
      </div>
      <IconButton size="lg" variant="danger" icon="icon-delete" onClick={onSubmit} />
    </div>
  );
};

export default CriteriaElement;
