import { useTranslation } from "react-i18next";
import Modal, { ModalProps } from ".";
import { iconClassNames } from "assets/style/theme/icon-classes";

type ActionModalProps = {
  headerMsg: string;
  subMsg: string;
  loading?: boolean;
  actionIcon: keyof typeof iconClassNames;
  confirmBtnText?: string;
  cancelBtnText?: string;
  iconColor?: "white" | "dark" | "light";
  iconWrapperColor?: "primary" | "danger" | "warning" | "success" | "info" | "dark" | "light" | "secondary";
  onActionConfirm: () => void;
} & ModalProps;

const ActionModal = (props: ActionModalProps) => {
  const { t } = useTranslation("Common");
  const {
    onActionConfirm,
    headerMsg,
    subMsg,
    confirmBtnText = t("Common:Save"),
    cancelBtnText = t("Common:Cancel"),
    actionIcon,
    iconWrapperColor = "primary",
    iconColor = "white",
    loading,
    ...rest
  } = props;

  return (
    <Modal {...rest} containerClassName={`bg-white ${rest.containerClassName}`} size={rest.size || "lg"}>
      <div className="mb-2">
        <div className="d-flex flex-column align-items-center">
          <div className={`icon-parent-wrapper mb-3 bg-${iconWrapperColor}-opacity-10`}>
            <div className={`icon-wrapper bg-${iconWrapperColor}`}>
              <i className={`icon ${actionIcon} ${iconColor}`}></i>
            </div>
          </div>
          <h1 className="text-dark mt-4">{headerMsg}</h1>
          <p>{subMsg}</p>
        </div>
      </div>
      <div className="d-flex justify-content-center gap-2 my-3">
        <button
          type="button"
          className={`p-10-56P btn btn-${iconWrapperColor} text-white `}
          onClick={onActionConfirm}
          disabled={loading}
        >
          {loading ? <div className="custom-loader"></div> : confirmBtnText}
        </button>
        <button type="button" className="p-10-56P btn border border-1 text-dark" onClick={rest.onClose}>
          {cancelBtnText}
        </button>
      </div>
    </Modal>
  );
};

export default ActionModal;
