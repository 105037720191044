import ActionModal from "components/blocks/Modal/ActionModal";
import useMutation from "hooks/useMutation";
import { useTranslation } from "react-i18next";
import { ExaminersService } from "services/examiners";
import { ExaminerResult } from "services/examiners/Model/ExaminerTypes";
import { BaseResponseType } from "services/shared-models/sharedTypes";

export type DeleteExaminerModalProps = {
    isOpen: boolean;
    onClose: (shouldUpdateExaminersTable: boolean) => void;
    selectedExaminerId: number;
    examinerStatus?: string | null;
    examinerName?: string;
};

export const DeleteExaminerModal = ({ props }: { props?: DeleteExaminerModalProps | null }) => {
    const { t } = useTranslation(["Orders"]);
    const { mutateAsync: deleteExaminerAsync, loading: deleteExaminerLoading } = useMutation({
        queryFn: async (selectedExaminerId: number) => {
            return await ExaminersService.deleteExaminer(selectedExaminerId);
        },
    });

    const { mutateAsync: stopExaminerWorkflowAsync, loading: stopExaminerWorkflowAsyncLoading } = useMutation({
        queryFn: async (selectedExaminerId: number) => {
            return await ExaminersService.stopExaminerWorkflow(selectedExaminerId);
        },
    });

    const onConfirmDelete = async () => {
        let res: BaseResponseType<ExaminerResult>;
        if (!props!.examinerStatus) {
            res = (await deleteExaminerAsync(props!.selectedExaminerId)) as BaseResponseType<ExaminerResult>;
        } else {
            res = (await stopExaminerWorkflowAsync(props!.selectedExaminerId)) as BaseResponseType<ExaminerResult>;
        }

        if (!res.hasError) {
            props!.onClose(true);
        }
    };

    return (
        <ActionModal
            isOpen={props?.isOpen}
            onClose={() => props?.onClose(false)}
            headerMsg={t("deleteExaminerModalHeaderMsg")}
            subMsg={t("deleteExaminerModalSubMsg", {
                examinerName: props?.examinerName,
            })}
            confirmBtnText={t("deleteExaminer")}
            actionIcon="icon-delete"
            iconWrapperColor="danger"
            onActionConfirm={onConfirmDelete}
            loading={deleteExaminerLoading || stopExaminerWorkflowAsyncLoading}
        />
    );
};
