import PageWithTabs from "components/PageWithTabs";
import IconButton from "components/blocks/IconButton";
import { useTranslation } from "react-i18next";
import PersonalInfo from "./Tabs/PersonalInfo";
import ProfessionalInfo from "./Tabs/ProfessionalInfo";
import OrdersTab from "./Tabs/OrdersTab";
import { useQuery } from "hooks/useQuery";
import { AccountService } from "services/accountService";
import { useMemo } from "react";
import { useCookieContext } from "contexts";
import { Link } from "react-router-dom";
import BankingInfo from "./Tabs/BankingInfo";
import TasksTab from "./Tabs/TasksTab";

const tabsIds = {
  ProfessionalInfo: "ProfessionalInfo",
  OrdersTab: "OrdersTab",
  PersonalInfo: "PersonalInfo",
  BankingAccount: "BankingAccount",
  Tasks: "Tasks",
} as const;

const UserProfile = () => {
  const { t, i18n } = useTranslation("UserProfile");
  const { isAr } = useCookieContext();

  //! get user from query
  const params = new URLSearchParams(window.location.search);
  const userId = params.get("researcherId") ?? params.get("examinerId") ?? params.get("userId");
  const pageParams = {
    id: userId ?? "",
    isResearcherRoute: !!params.get("researcherId"),
  };

  const { data: user, loading } = useQuery({
    queryFn: async () => {
      return await AccountService.getUserByIdAsync(pageParams.id);
    },
    options: {
      enabled: !!pageParams.id,
    },
    triggers: [isAr],
  });

  const userRoles = useMemo(() => {
    return user?.roles.reduce((acc, role, i) => {
      return (
        acc +
          role.roleDetails.find(
            (roleDetail) => roleDetail.locale.toLocaleLowerCase() === i18n.language.toLocaleLowerCase(),
          )?.displayName +
          (i === user.roles.length - 1 ? "" : ", ") ?? ""
      );
    }, "");
  }, [user, i18n.language]);

  return (
    <PageWithTabs
      title={i18n.language === "ar" ? user?.arabicName : user?.englishName}
      subTitle={`${t("userNumber")}: ${user?.employeeId ?? ""}`}
      defaultTabId={tabsIds.PersonalInfo}
      loading={loading}
      pageHeaders={[
        {
          title: t("username"),
          value: user?.username,
        },
        {
          title: t("role"),
          value: userRoles,
        },
        {
          title: t("userClassification"),
          value: user?.isExternal ? t("external") : t("internal"),
        },
        {
          title: t("userType"),
          value: user?.type ?? "",
        },
        {
          title: t("status"),
          value: user ? (user?.isActive ? t("active") : t("inactive")) : "",
        },
      ]}
      actions={(selectedTabId) => {
        return (
          <>
            <Link to={`/home/editUserProfile/${user?.id ?? ""}`}>
              <IconButton
                icon="icon-edit"
                size="md"
                className="btn action-btn btn-sm rounded p-2"
                variant="light"
                innerIconColor="secondary"
              />
            </Link>
          </>
        );
      }}
      tabs={[
        {
          id: tabsIds.PersonalInfo,
          title: t("personalInfo"),
          content: <PersonalInfo user={user} />,
        },
        {
          id: tabsIds.ProfessionalInfo,
          title: t("professionalInfo"),
          content: <ProfessionalInfo user={user} />,
        },
        {
          id: tabsIds.BankingAccount,
          title: t("bankingInformation"),
          content: <BankingInfo user={user} />,
        },
        /* !pageParams.isResearcherRoute || */ !user?.id
          ? null
          : {
              id: tabsIds.OrdersTab,
              title: t("orders"),
              content: <OrdersTab userId={user.id} />,
            },

        {
          id: tabsIds.Tasks,
          title: t("tasks"),
          content: <TasksTab userId={user?.id ?? ""} />,
        },
      ]}
    />
  );
};

export default UserProfile;
