import useMutation from "hooks/useMutation";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ProgramsService } from "services/programs";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";

const now = new Date();
const FundingUnitActions = ({ programData }: { programData: ProgramByIDResponse | null }) => {
  const { t } = useTranslation("Programs");
  const navigate = useNavigate();

  const { mutateAsync, loading } = useMutation({
    queryFn: async (id: number) => {
      return await ProgramsService.sendFundingUnitRequest(id);
    },
  });

  const onSendFundingRequest = async () => {
    if (!programData || loading) return;

    const res = await mutateAsync(programData.id);
    if (res && !res.hasError) {
      navigate(-1);
    }
  };

  const isProgramAdsDateEnded = now > new Date(programData?.adsEndDate ?? "");

  return (
    <>
      {isProgramAdsDateEnded && (
        <button className="btn btn-primary px-3" disabled={loading} onClick={onSendFundingRequest}>
          {loading ? <div className="custom-loader"></div> : t("Common:send")}
        </button>
      )}
      <button className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3" onClick={() => navigate(-1)}>
        {t("Common:cancel")}
      </button>
    </>
  );
};

export default FundingUnitActions;
