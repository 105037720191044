import LookupDropdown from "components/blocks/LookupDropdown";
import PaginatedLookupDropdown from "components/blocks/PaginatedLookupDropdown";
import { Control, Controller, FieldErrors, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CreateOrderRequest, OrderLocalItemResult } from "services/orders/models/OrdersTypes";

export default function LookupDropDowns({
  control,
  errors,
  setValue,
  order,
  isInspectMode,
}: {
  control: Control<CreateOrderRequest, any>;
  errors: FieldErrors<CreateOrderRequest>;
  setValue: UseFormSetValue<CreateOrderRequest>;
  order?: OrderLocalItemResult | null;
  isInspectMode?: boolean;
}) {
  const { t } = useTranslation(["Orders", "Common"]);

  return (
    <div className="d-flex flex-column gap-3">
      <Controller
        name="centreManagementId"
        control={control}
        render={({ field }) => (
          <PaginatedLookupDropdown
            wrapperClassName="flex-1 "
            label={t("researchesCenter")}
            labelClassName="fw-bold"
            queryKey="search"
            service="orderService"
            endpoint="getManagementCentersDropdown"
            textValueKey="centreManagementDetail.displayName"
            idValueKey="id"
            isPaginated
            error={errors.centreManagementId?.message}
            value={field.value}
            disabled={!!isInspectMode}
            defaultOptions={
              order
                ? [{ value: `${order.centreManagementId}`, label: order.centreManagementDetail?.displayName ?? "" }]
                : null
            }
            onChange={(value: string) => {
              setValue("centreManagementId", +value);
            }}
          />
        )}
      />
      <div className="d-flex gap-3 ">
        <Controller
          name="researchFieldId"
          control={control}
          render={({ field }) => (
            <LookupDropdown
              wrapperClassName="flex-1 "
              labelClassName="fw-bold"
              label={t("researchField")}
              service="lookupService"
              endpoint="getAllResearchFields"
              textValueKey="researchFieldDetails.displayName"
              idValueKey="id"
              noSelectionPlaceholder={t("researchField")}
              error={errors.researchFieldId?.message}
              value={field.value ? `${field.value}` : ""}
              onChange={(value: string) => {
                setValue("researchFieldId", +value);
              }}
            />
          )}
        />
        <Controller
          name="subspecialty"
          control={control}
          render={({ field }) => (
            <PaginatedLookupDropdown
              isCreatable
              isPaginated={false}
              wrapperClassName="flex-1 "
              labelClassName="fw-bold"
              label={t("subspecialty")}
              queryKey="search"
              service="lookupService"
              endpoint="getAllSubSpecialties"
              textValueKey="subspecialtyDetails.displayName"
              idValueKey="id"
              value={field.value}
              error={errors.subspecialty?.message}
              defaultOptions={order ? [{ value: order.subspecialty, label: order.subspecialty }] : null}
              onChange={(_: string, item) => {
                setValue("subspecialty", item.value);
              }}
            />
          )}
        />
      </div>
    </div>
  );
}
