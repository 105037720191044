import { HttpClient } from "helpers";
import { WorkFlowTaskWithFilesRequest } from "services/orders/models/OrdersTypes";
import { BaseResponseType, PagedResult } from "services/shared-models/sharedTypes";
import {
  CreateProgramOrderUpdateRequest,
  ProgramOrderUpdateResult,
  EvaluateProgramOrderUpdateRequest,
  EditProgramOrderUpdateRequest,
} from "./models";

export const ProgramOrderUpdateService = {
  createProgramOrderUpdate: async (request: CreateProgramOrderUpdateRequest) => {
    return HttpClient.post<BaseResponseType<ProgramOrderUpdateResult>>(
      "/api/ProgramOrderUpdates/CreateProgramOrderUpdate",
      request,
    );
  },

  evaluateProgramOrderUpdate: async (id: number, request: EvaluateProgramOrderUpdateRequest) => {
    return HttpClient.put<BaseResponseType<ProgramOrderUpdateResult>>(
      `/api/ProgramOrderUpdates/EvaluateProgramOrderUpdate/${id}`,
      request,
    );
  },

  editProgramOrderUpdate: async (id: number, request: EditProgramOrderUpdateRequest) => {
    return HttpClient.put<BaseResponseType<ProgramOrderUpdateResult>>(
      `/api/ProgramOrderUpdates/EditProgramOrderUpdate/${id}`,
      request,
    );
  },

  returnOrRejectProgramOrderUpdate: async (id: number, request: WorkFlowTaskWithFilesRequest) => {
    return HttpClient.put<BaseResponseType<boolean>>(
      `/api/ProgramOrderUpdates/ReturnProgramOrderUpdate/${id}`,
      request,
    );
  },

  getAllProgramOrderUpdates: async (programOrderId: number) => {
    return HttpClient.get<BaseResponseType<PagedResult<ProgramOrderUpdateResult>>>(
      `/api/ProgramOrderUpdates/GetAllProgramOrderUpdates/${programOrderId}`,
    );
  },
};
