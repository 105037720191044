import { useQuery } from "hooks/useQuery";
import React from "react";
import { OrdersService } from "services/orders";
import { ProgramsService } from "services/programs";
import { TasksService } from "services/tasks";
import { useCookieContext } from "contexts";
import { useParams } from "react-router-dom";
import { OrderFormTypes } from ".";

const useOrderForm = ({ orderFormType }: { orderFormType: keyof typeof OrderFormTypes }) => {
  const { isAr } = useCookieContext();

  const { orderId, serialGID } = useParams();
  const search = window.location.search;
  const queryPar = new URLSearchParams(search);
  const programId = queryPar.get("programId");

  //Open Task Api
  const { data: openedTask, loading: isTaskLoading } = useQuery({
    queryFn: async (_: string) => {
      const res = await TasksService.openFundingOrderTask(serialGID ?? "");
      return {
        data: res,
        hasError: false,
        code: 200,
        description: "",
      };
    },
    options: {
      enabled: !!serialGID && orderFormType !== OrderFormTypes.EditOrder,
    },
    triggers: [serialGID],
  });

  const { data: order, loading: orderLoading } = useQuery({
    queryFn: async () => {
      const id = orderId ? +orderId : openedTask?.orderId ? openedTask.orderId : 0;

      return await OrdersService.getOneById(id);
    },
    options: {
      enabled: !!orderId || !!openedTask?.orderId,
    },
    triggers: [orderId, openedTask?.orderId],
  });

  const { data: orderProgram, loading } = useQuery({
    queryFn: async () => {
      const id = order?.programId ? order.programId : programId ? +programId : 0;

      return await ProgramsService.getProgramById(id);
    },
    triggers: [order?.programId, isAr],
    options: { enabled: !!order?.programId || !!programId },
  });

  return {
    order,
    orderProgram,
    isLoading: loading || isTaskLoading || orderLoading,
    serialGID,
    actionNames: openedTask?.actionName,
  };
};

export default useOrderForm;
