import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import AttachmentCard from "components/blocks/AttachmentCard";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import { FileResult } from "services/shared-models/sharedTypes";

const Recommendations = ({ order }: { order: OrderLocalItemResult }) => {
  const { t } = useTranslation(["Orders"]);

  const { files, recommendations } = useMemo(() => {
    const shownFiles: FileResult[] = [];
    const shownRecommendations: string[] = [];

    order.selectedProgramOrderExaminers?.forEach((examiner) => {
      if (examiner.showFiles) {
        examiner.programOrderExaminer.examinerEvaluationFormValue.files.forEach((file) => {
          shownFiles.push(file);
        });
      }

      if (examiner.showRecommendation) {
        shownRecommendations.push(examiner.programOrderExaminer.examinerEvaluationFormValue.recommendations);
      }
    });

    return { files: shownFiles, recommendations: shownRecommendations };
  }, []);

  return !!recommendations.length || order.notes || !!files.length ? (
    <Accordion>
      <AccordionItem initiallyOpen title={t("recommendations")} elementId="examinersRecommendations">
        <div className="d-flex flex-column gap-3 ">
          {order.notes && (
            <div className="d-flex flex-column gap-2">
              <span className="fw-bold fs-6">{t("centralManagementNotes")}</span>
              <span>{order.notes}</span>
            </div>
          )}

          {!!recommendations.length && (
            <div className="d-flex flex-column gap-2">
              <span className="fw-bold fs-6">{t("examinersRecommendations")}</span>
              <div className="d-flex flex-column gap-2">
                {recommendations.map((recommendation, index) => (
                  <div key={index}>
                    {index + 1} - {recommendation}
                  </div>
                ))}
              </div>
            </div>
          )}

          {!!files.length && (
            <div className="d-flex flex-column gap-2">
              <span className="fw-bold fs-6">{t("attachments")}</span>
              <div className="d-flex  gap-2">
                {files.map((file) => (
                  <AttachmentCard
                    key={file.id}
                    attachment={{
                      extension: file.extension ?? "",
                      fileName: file.name ?? "",
                      id: file.id,
                      size: file.size ?? "",
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="divider"></div>
        </div>
      </AccordionItem>
    </Accordion>
  ) : (
    <></>
  );
};

export default Recommendations;
