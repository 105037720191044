import { DataGridRequest, PagedResponseType } from "services/shared-models/sharedTypes";
import { ResearcherPaymentLocalResult } from "./models/ResearcherPaymentsType";
import { HttpClient } from "helpers";
import { AxiosResponse } from "axios";

export const ResearcherPaymentsService = {
  getAllResearcherPaymentsAsync: async (data: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<ResearcherPaymentLocalResult>>(
      "/api/ResearchPayment/GetAllResearchPayments",
      data,
    );
  },
  exportResearcherPaymentsToExcelAsync: async (data: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>("/api/ResearchPayment/ExportResearchPaymentsToExcel", data, {
      responseType: "blob",
    });
  },
};
