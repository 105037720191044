import { Routes, Route, Navigate } from "react-router-dom";
import NotFoundLayout from "../../views/notfound/NotFoundLayout";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode"; 
import { RouteItemConfig } from "../../routes/models";
import { useCookieContext } from "../../contexts";
import WelcomePageView from "views/welcomePage/WelcomePageView";
import React from "react";

interface SwitchRouteProps {
  routes: Array<RouteItemConfig>;
}

// Interface for decoded JWT token roles
interface DecodedToken {
  role: string[];
}

export const SwitchRoute: React.FC<SwitchRouteProps> = ({ routes }) => {
  const { userSession } = useCookieContext();

  // Function to get roles from JWT token stored in cookies
  const getRolesFromToken = (): string[] => {
    const token = Cookies.get("Session");
    if (!token) return [];

    try {
      // Decode JWT and extract roles
     
      const decoded: DecodedToken = jwtDecode(JSON.parse(token)?.token);
      // @ts-ignore
      return decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]  || [];
    } catch (error) {
      console.error("Failed to decode token:", error);
      return [];
    }
  };

  const userRoles = getRolesFromToken();

  // Recursive function to generate routes with role checking
  const getRecursion = (value: RouteItemConfig): React.ReactNode[] => {
    const nestedRoutes: React.ReactNode[] = [];

    // If there are sub-menu items, recursively call getRecursion
    if (value.subMenu && value.subMenu.length > 0) {
      nestedRoutes.push(
          <Route
              path={value.path}
              element={<Navigate to={value.subMenu[0].path} replace />}
              key={`redirectRoute${value.path}`}
          />
      );

      value.subMenu.forEach((subValue) => {
        nestedRoutes.push(...getRecursion(subValue));
      });

      return [
        <Route path={value.path} element={<value.component />} key={`layoutRoute${value.path}`}>
          {nestedRoutes}
        </Route>,
      ];
    } else {
      const key = Math.floor(Math.random() * 1000);

      // Authorization check
      const requiresAuth = value.authorize;
      const hasRequiredRoles =
          value.roles.length === 0 || value.roles.some((role) => userRoles.includes(role));

      if (requiresAuth) {
        if (Cookies.get("Session") && userSession && hasRequiredRoles) {
          nestedRoutes.push(
              <Route path={value.path} element={<value.component />} key={`privateRoute${key}`} />
          );
        } else {
          nestedRoutes.push(
              <Route path={value.path} element={<Navigate to="/account" replace />} key={`redirectRoute${key}`} />
          );
        }
      } else {
        nestedRoutes.push(<Route path={value.path} element={<value.component />} key={`publicRoute${key}`} />);
      }

      return nestedRoutes;
    }
  };

  // Render default route
  const renderDefaultRoute = () => {
    return <Route path="/" element={<WelcomePageView />} key={`defaultRoute`} />;
  };

  return (
      <>
        {routes.length > 0 && (
            <Routes>
              {renderDefaultRoute()}
              {routes.map((routeItem) => {
                return getRecursion(routeItem);
              })}
              <Route path="*" element={<NotFoundLayout />} />
            </Routes>
        )}
      </>
  );
};
