import React from 'react';
import { FormBuilder } from '@formio/react';

interface FormBuilderComponentProps {
    formSchema: any;
    setFormSchema: (schema: any) => void;
}

const FormBuilderComponent: React.FC<FormBuilderComponentProps> = ({formSchema,setFormSchema}) => {
    
    const onChange = (schema: any) => {
        setFormSchema(schema);
        console.log('Form schema changed:', schema);
    };

    return (
        <div>
            <FormBuilder
                form={formSchema}
                onChange={onChange}
                
            />
        </div>
    );
};

export default FormBuilderComponent;