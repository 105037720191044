import { z } from "zod";

export const createProgramSchema = z
  .object({
    status: z.string(),
    programTypeId: z.number({ message: "fieldRequired" }).min(1, "fieldRequired"), // state placeholder (not sent to backend)
    cycleYear: z.number({ message: "fieldRequired" }).min(1, "fieldRequired"), // state placeholder (not sent to backend)

    programDetails: z.array(
      z.object({
        displayName: z.string().min(1, "fieldRequired"),
        description: z.string().min(3, "fieldLength"),
        ruleAndConditionName: z.string().min(1, "fieldRequired"),
        ruleAndConditionTemplate: z.string().min(1, "fieldRequired"),
        commitmentName: z.string().min(1, "fieldRequired"),
        commitmentTemplate: z.string().min(1, "fieldRequired"),
        locale: z.enum(["En", "Ar"]),
      }),
    ),
    classificationProgramId: z.number().min(1, "fieldRequired"),
    // min date 1/1/2023
    contractStartDate: z.date({ message: "fieldRequired" }).min(new Date(2023, 0, 1), "fieldRequired"),
    fundingDurationInMonths: z.number().min(1, "fieldRequired"),
    adsStartDate: z.date({ message: "fieldRequired" }).min(new Date(2023, 0, 1), "fieldRequired"),
    adsEndDate: z.date({ message: "fieldRequired" }).min(new Date(2023, 0, 1), "fieldRequired"),
    fundingAmount: z.number({ message: "fieldRequired" }).min(0, "fieldRequired"),
    audienceTypesIds: z.array(z.number()),
    programCycleId: z.number({ message: "fieldRequired" }).min(1, "fieldRequired"),
    adsImageId: z.string().min(1, "adImageRequired"),
    contractId: z.number({ message: "fieldRequired" }).min(1, "fieldRequired"),
    financingCalculationItems: z.array(
      z.object({
        financingTypeId: z.number({ message: "fieldRequired" }).min(1, "fieldRequired"),
        calculationWay: z.string().nullish(), // state placeholder (not sent to backend)
        notes: z.string().nullish(),
        fromValue: z.number({ message: "fieldRequired" }).min(1, "fieldRequired"),
        toValue: z.number({ message: "fieldRequired" }).min(1, "fieldRequired"),
      }),
    ),
    formBuilderSchema: z.string(),
    programAttachmentsIds: z.array(z.string()),
  })
  .refine((data) => data.adsEndDate > data.adsStartDate, {
    message: "adsEndDateGreaterThanAdsStartDate",
    path: ["adsEndDate"],
  });

export type CreateProgramRequest = z.infer<typeof createProgramSchema>;
