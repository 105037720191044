import { Link, useParams } from "react-router-dom";
import NavBar from "../NavBar";
import "./ProgramDetails.scss";
import { useQuery } from "hooks/useQuery";
import { ProgramsService } from "services/programs";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useTranslation } from "react-i18next";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import AttachmentCard from "components/blocks/AttachmentCard";
import RichTextEditor from "components/RichTextEditor";
import { useCookieContext } from "contexts";

const ProgramDetails = () => {
  const { userSession, isAr } = useCookieContext();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { t, i18n } = useTranslation("WelcomePage");
  const { id } = useParams();

  const { data: program, loading } = useQuery({
    queryFn: async () => {
      return await ProgramsService.getPublicProgramById(id ? +id : 0);
    },
    triggers: [id, isAr],
    options: { enabled: !!id },
  });

  const programDetails = program?.programDetails.find((x) => x.locale?.toLocaleLowerCase() === i18n.language);

  return (
    <div className=" bg-white d-flex flex-column gap-4 position-relative text-dark">
      <LoadingOverlay visible={loading} className="h-100" />

      <div className="shadow py-1  px-6rem ">
        <NavBar tapClassName="text-dark" />
      </div>

      <div className="program-details-container d-flex flex-column gap-4 ">
        <div className=" d-flex align-items-center  rounded-4  justify-content-center position-relative">
          <img className="logo " src={program?.adsImage} alt={programDetails?.description} />
          <div className="overlay">
            <h1 className=" text-white">{programDetails?.displayName}</h1>
          </div>
        </div>

        <div>
          <h5 className="fw-bold">{t("programDescription")} </h5>
          <span className="fs-6">{programDetails?.description}</span>
        </div>

        <div className="striped d-flex flex-column gap-2">
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold">{t("programNumber")}</span>
            <span className="flex-2"> {program?.serialNumber}</span>
          </div>
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold">{t("programClassification")}</span>
            <span className="flex-2">{program?.classificationProgramName} </span>
          </div>
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold"> {t("startContract")}</span>
            <span className="flex-2"> {toSystemThemeDateFormat(program?.contractStartDate)}</span>
          </div>
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold"> {t("fundingDuration")} </span>
            <span className="flex-2">
              {t("month")} {program?.fundingDurationInMonths}{" "}
            </span>
          </div>
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold"> {t("startProgramDate")} </span>
            <span className="flex-2"> {toSystemThemeDateFormat(program?.adsStartDate)}</span>
          </div>
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold"> {t("closeProgramDate")} </span>
            <span className="flex-2"> {toSystemThemeDateFormat(program?.adsEndDate)}</span>
          </div>
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold">{t("fundingAmount")}</span>
            <span className="flex-2">
              {" "}
              {program?.fundingAmount} {t("ryal")}
            </span>
          </div>
          <div className="d-flex p-3">
            <span className="flex-1 fw-bold">{t("audiencesType")}</span>
            <span className="flex-2">
              {program?.audienceTypes.map((x) => x.audienceTypeDetail.displayName).join(", ")}
            </span>
          </div>
        </div>

        <div>
          <h5 className="fw-bold"> {t("programRules")}</h5>
          {programDetails?.ruleAndConditionTemplate && (
            <RichTextEditor initialData={programDetails!.ruleAndConditionTemplate} isReadOnly />
          )}
        </div>

        <div>
          <h5 className="fw-bold">{t("requiredCommitment")} </h5>
          {programDetails?.commitmentTemplate && (
            <RichTextEditor initialData={programDetails.commitmentTemplate} isReadOnly />
          )}
        </div>

        <div>
          <h5 className="fw-bold"> {t("attachments")} </h5>
          <div className="d-flex flex-wrap gap-2 align-items-start">
            {program?.files.map((file) => (
              /* TODO: add download file after osama branch merge  */
              <AttachmentCard
                key={file.id}
                attachment={{
                  extension: file.extension ?? "", // TODO: this must be on only pdf or word ?
                  fileName: file.name ?? "",
                  size: file.size ?? 0,
                  id: file.id ?? 0,
                }}
              />
            ))}
          </div>
        </div>

        {/*TODO: Not exist in dto */}
        <div>
          <h5 className="fw-bold">{t("calculationTerms")} </h5>
          <div className="striped">
            {program?.financingCalculationItems?.map((item) => (
              <div className="d-flex p-3">
                <span className="flex-1 fw-bold">{t("calculationType")} </span>
                <span className="flex-2">{item.financingType.financingTypesDetail.displayName}</span>
              </div>
            ))}
          </div>
        </div>
        <Link
          to={!userSession ? `/account/login?programId=${program?.id}` : `/home/orders/add?programId=${program?.id}`}
          className="btn btn-primary p-2 my-4 rounded-3"
        >
          {t("sendOrder")}
        </Link>
      </div>
    </div>
  );
};

export default ProgramDetails;
