import OrderGridView from "../../../../orders/OrderGridView";
import React, { Dispatch, SetStateAction } from "react";
import { programUserTypes } from "../../../ProgramsGridView";
import FundingUnitOrdersTab from "./FundingUnitOrdersTab";
import ViceDeanOrdersTab from "./ViceDeanOrdersTab";
import ResearchersTab from "./ResearchersTab";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import { AddEditProgramMinutesRef } from "./ViceDeanOrdersTab/AddProgramMinutes";
import { TaskActionsNames } from "services/tasks/models/tasksTypes";

type OrdersTabProps = {
  userType: keyof typeof programUserTypes;
  orderData?: OrderLocalItemResult | null;
  program: ProgramByIDResponse;
  setIsResearcherAcceptanceButtonDisabled: Dispatch<SetStateAction<boolean>>;
  addEditProgramMinutesRef: React.RefObject<AddEditProgramMinutesRef>;
  serialGuid?: string | null;
  actionNames?: TaskActionsNames[] | null;
};

const OrdersTab: React.FC<OrdersTabProps> = ({
  program,
  userType,
  orderData,
  setIsResearcherAcceptanceButtonDisabled,
  addEditProgramMinutesRef,
  actionNames,
  serialGuid,
}) => {
  return (
    <div style={{ margin: "-1.5rem" }}>
      {userType === programUserTypes.Admin && <OrderGridView programId={program.id} />}
      {userType === programUserTypes.FundingUnit && <FundingUnitOrdersTab programId={program.id} />}
      {userType === programUserTypes.ViceDean && (
        <ViceDeanOrdersTab
          program={program}
          addEditProgramMinutesRef={addEditProgramMinutesRef}
          actionNames={actionNames}
          serialGuid={serialGuid}
        />
      )}
      {userType === programUserTypes.Researcher && (
        <ResearchersTab
          program={program}
          orderData={orderData}
          setIsResearcherAcceptanceButtonDisabled={setIsResearcherAcceptanceButtonDisabled}
        />
      )}
    </div>
  );
};

export default OrdersTab;
