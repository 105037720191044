import Modal from "components/blocks/Modal";
import { useTranslation } from "react-i18next";

const translations = {
  en: {
    modalTitle: "User Type",
    postGradStudents: {
      title: "Postgraduate Students",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    },
    specialist: {
      title: "Specialist",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      types: [
        {
          title: "Author",
          description: "Specialists in various engineering fields such as civil, electrical, mechanical and others",
        },
        {
          title: "Researcher",
          description: "Specialists in various medical and health fields",
        },
        {
          title: "Examiner",
          description: "Specialists in science fields such as physics, chemistry, biology and others",
        },
        {
          title: "Reviewer",
          description: "Specialists in programming, software development, information security and networks",
        },
      ],
    },
  },
  ar: {
    modalTitle: "نوع المستخدم",
    postGradStudents: {
      title: "طلاب دراسات عليا",
      description:
        "أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس نوستريد أنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس نوستريد أنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس نوستريد",
    },
    specialist: {
      title: "متخصص",
      description:
        "أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس",
      types: [
        {
          title: "مؤلف",
          description: "متخصصون في مجالات الهندسة المختلفة مثل الهندسة المدنية والكهربائية والميكانيكية وغيرها",
        },
        {
          title: "باحث",
          description: "متخصصون في المجالات الطبية والصحية المختلفة",
        },
        {
          title: "فاحص",
          description: "متخصصون في مجالات العلوم مثل الفيزياء والكيمياء والأحياء وغيرها",
        },
        {
          title: "محكم",
          description: "متخصصون في مجال البرمجة وتطوير البرمجيات وأمن المعلومات والشبكات",
        },
      ],
    },
  },
};

const UserTypeGuideModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const {
    i18n: { language },
  } = useTranslation("RegisterView");
  const t = translations[language as "en" | "ar"];

  return (
    <Modal
      bodyMargin="my-2"
      title={t.modalTitle}
      isOpen={isOpen}
      onClose={onClose}
      withHeaderBorder
      containerClassName="bg-white"
      size="lg"
    >
      <div className="user-type-guide-modal-title">
        <h5 className="fw-bold mb-0">{t.postGradStudents.title}</h5>
      </div>
      <p>{t.postGradStudents.description}</p>

      <div className="divider" />

      <div className="user-type-guide-modal-title">
        <h5 className="fw-bold mb-0">{t.specialist.title}</h5>
      </div>
      <p>{t.specialist.description}</p>

      <ul className="list-unstyled mt-3 p-0 overflow-auto">
        {t.specialist.types.map((type, index) => (
          <li key={index} className="mb-3">
            <p className="fw-bold mb-1">• {type.title}</p>
            <p className="text-muted me-2">{type.description}</p>
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default UserTypeGuideModal;
