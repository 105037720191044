import { SelectedFilesType } from "components/blocks/FileUploadWithPreview";
import useMutation from "hooks/useMutation";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FileService } from "services/fileService";
import { ProgramOrderUpdateService } from "services/programOrderUpdate";
import { CreateProgramOrderUpdateRequest, EditProgramOrderUpdateRequest } from "services/programOrderUpdate/models";
import { getResearcherUpdateFormSchema } from "./researcherUpdateForm.schema";
import { useNavigate, useParams } from "react-router-dom";

const useAddEditProgramOrderUpdateForm = ({
  orderId,
  programOrderUpdateId,
}: {
  orderId: number;
  programOrderUpdateId?: number;
}) => {
  const { serialGID } = useParams();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [selectedFile, setSelectedFile] = useState<SelectedFilesType>();
  const [triggerTableChange, setTriggerTableChange] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<CreateProgramOrderUpdateRequest>({
    defaultValues: {
      completionRate: 0,
      details: "",
      fileId: "",
      programOrderId: orderId,
    },
    resolver: getResearcherUpdateFormSchema(t),
  });

  useEffect(() => {
    setValue("programOrderId", orderId);
  }, [orderId]);

  const { mutateAsync: createProgramOrderUpdate, loading: createProgramOrderUpdateLoading } = useMutation({
    queryFn: async (req: CreateProgramOrderUpdateRequest) => {
      return await ProgramOrderUpdateService.createProgramOrderUpdate(req);
    },
  });

  const { mutateAsync: fileDownload, loading: fileDownloadLoading } = useMutation({
    queryFn: async (id: string) => {
      await FileService.downloadFile(id);
      return { hasError: false, data: null, description: "", code: 200 };
    },
  });

  const { mutateAsync: editProgramOrderUpdate, loading: editProgramOrderUpdateLoading } = useMutation({
    queryFn: async (req: EditProgramOrderUpdateRequest) => {
      return await ProgramOrderUpdateService.editProgramOrderUpdate(programOrderUpdateId ?? 0, req);
    },
  });

  const onSubmit = async (values: CreateProgramOrderUpdateRequest) => {
    const res = programOrderUpdateId
      ? await editProgramOrderUpdate({
          ...values,
          workFlowTaskRequest: {
            actionName: "Complete",
            notes: "",
            serialGuid: serialGID!,
          },
        })
      : await createProgramOrderUpdate(values);

    if (res && !res.hasError) {
      setTriggerTableChange((prev) => !prev);

      if (programOrderUpdateId) {
        navigate(`/home/tasks`);
      }
    }
  };

  return {
    selectedFile,
    setSelectedFile,
    errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    watch,
    createProgramOrderUpdate,
    isProgramOrderUpdateLoading: editProgramOrderUpdateLoading || createProgramOrderUpdateLoading,
    fileDownload,
    fileDownloadLoading,
    triggerTableChange,
    formRef,
  };
};

export default useAddEditProgramOrderUpdateForm;
