import useMutation from "hooks/useMutation";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { OrdersService } from "services/orders";
import { UpdateProgramExtendedRequest } from "services/programOrderUpdate/models";

const useEditExtendRequestForm = ({
  note,
  order,
  serialGuid,
  type,
  onModelClose,
}: {
  type: string;
  order: any;
  serialGuid: string;
  note: string;
  onModelClose: () => void;
}) => {
  const navigate = useNavigate();
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<UpdateProgramExtendedRequest>({
    defaultValues: {
      extendedTime: 0,
      fundingAmount: 0,
      notes: "",
    },
  });

  const formRef = useRef<HTMLFormElement | null>(null);
  const { mutateAsync: sendProgramOrderUpdateResponse, loading: sendProgramOrderUpdateResponseLoading } = useMutation({
    queryFn: async (req: UpdateProgramExtendedRequest) => {
      const res =
        type === "ApproveExtendRequest"
          ? await OrdersService.acceptProgramOrderExtendRequest(order?.id ?? 0, {
              actionName: "Approve",
              notes: note,
              serialGuid: serialGuid,
            })
          : await OrdersService.updateProgramOrderExtendRequest(order?.id ?? 0, {
              ...req,
              notes: note,
            });

      if (res && !res.hasError) {
        if (type !== "ApproveExtendRequest") {
          window.location.reload();
        } else {
          onModelClose();
        }
      }

      return res;
    },
  });

  useEffect(() => {
    setValue("extendedTime", order?.extendedPeriodInMonth ?? 0);
    setValue("fundingAmount", order?.fundingAmount ?? 0);
  }, [order, type]);

  return {
    formRef,
    sendProgramOrderUpdateResponse,
    sendProgramOrderUpdateResponseLoading,
    setValue,
    errors,
    watch,
    handleSubmit,
  };
};

export default useEditExtendRequestForm;
