import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import IconButton from "components/blocks/IconButton";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useCookieContext } from "contexts";
import { useQuery } from "hooks/useQuery";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OrdersService } from "services/orders";
import { ProgramMinutesResult } from "services/orders/models/OrdersTypes";

const PreviousRecords = ({
  programOrderId,
  shouldUpdateTable,
}: {
  programOrderId: number;
  shouldUpdateTable: boolean;
}) => {
  const { t } = useTranslation("Orders");
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { isAr } = useCookieContext();

  const { data, loading } = useQuery({
    queryFn: async () => {
      return await OrdersService.getAllProgramMinutesByProgramOrderId(programOrderId);
    },
    triggers: [shouldUpdateTable, isAr],
  });

  const previousRecordTableHeaders = useMemo<GridSchema[]>(() => {
    return [
      {
        accessor: "programMinutesNumber",
        field: "programMinutesNumber",
        displayName: t("recordNumber"),
        showOnMobile: true,
      },
      {
        accessor: "date",
        field: "date",
        displayName: t("date"),
        render: (row: ProgramMinutesResult) => <>{toSystemThemeDateFormat(row.date)}</>,
      },
      {
        accessor: "members",
        field: "members",
        displayName: t("members"),
        render: (row: ProgramMinutesResult) => <>{row.users.join(", ")}</>,
      },
      {
        accessor: "description",
        field: "description",
        displayName: t("recordDetails"),
      },
      {
        accessor: "files",
        field: "files",
        displayName: t("files"),
        showOnMobile: true,
        render(row) {
          return (
            <div className="d-flex ">
              <IconButton icon="icon-view" variant="transparent" innerIconColor="primary" onClick={() => {}} />
            </div>
          );
        },
      },
    ];
  }, [t, toSystemThemeDateFormat]);

  return (
    <div className="position-relative">
      <LoadingOverlay visible={loading} />
      <Grid gridSchema={previousRecordTableHeaders} data={data?.result ?? []} />
    </div>
  );
};

export default PreviousRecords;
