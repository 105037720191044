import useMutation from "hooks/useMutation";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { OrdersService } from "services/orders";

const useDeanOfScientificResearchActions = ({ orderId, serialGuid }: { orderId: number; serialGuid: string }) => {
  const navigate = useNavigate();
  const [rejectReturnModalProps, setRejectReturnModalProps] = useState({
    isOpen: false,
    isReturn: false,
  });
  const { mutateAsync, loading } = useMutation({
    queryFn: async ({ isReturn, notes }: { notes: string; isReturn: boolean }) => {
      return await OrdersService.returnOrRejectProgramOrderUpdateRequest(orderId, {
        notes: notes,
        serialGuid,
        actionName: isReturn ? "Return to Center" : "Reject",
      });
    },
  });

  const returnOrRejectProgramOrderUpdateRequest = async (notes: string) => {
    const res = await mutateAsync({ isReturn: rejectReturnModalProps.isReturn, notes });

    if (res && !res.hasError) {
      navigate("/home/tasks");
    }
  };

  return {
    returnOrRejectProgramOrderUpdateRequest,
    loading,
    rejectReturnModalProps,
    setRejectReturnModalProps,
  };
};

export default useDeanOfScientificResearchActions;
