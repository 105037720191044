import { HttpClient } from "helpers";
import { DataGridRequest, PagedResponseType } from "services/shared-models/sharedTypes";
import {
  GetAllTasksResponse,
  MyCompletedTaskResult,
  OpenFundingTaskResult,
  ReAssignRequest,
  ReAssignResponse,
} from "./models/tasksTypes";
import { AxiosResponse } from "axios";

export const TasksService = {
  getMyTasks: async (data: DataGridRequest) => {
    return await HttpClient.post<GetAllTasksResponse>("/api/Tasks/GetMyTasks", data);
  },
  getTasksByUserId: async (data: DataGridRequest, userId: string) => {
    return await HttpClient.post<GetAllTasksResponse>(`/api/Tasks/GetTasksByUserId/${userId}`, data);
  },
  exportTasksToExcel: async (data: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>("/api/Tasks/ExportTasksToExcel", data, {
      responseType: "blob",
    });
  },
  openFundingOrderTask: async (serialId: string) => {
    return await HttpClient.get<OpenFundingTaskResult>(`/api/Tasks/OpenFundingOrderTask/${serialId}`);
  },
  reAssignTask: async (data: ReAssignRequest) => {
    return await HttpClient.post<ReAssignResponse>(`/api/Tasks/ReAssignTask`, data);
  },
  getMyCompletedTasks: async (data: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<MyCompletedTaskResult>>("/api/Tasks/GetAllMyCompletedTasks", data);
  },
  exportAllMyCompletedTasksToExcel: async (data: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>("/api/Tasks/ExportAllMyCompletedTasksToExcel", data, {
      responseType: "blob",
    });
  },
};
