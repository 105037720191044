import IconButton from "components/blocks/IconButton";
import ActionModal from "components/blocks/Modal/ActionModal";
import { useNotification } from "hooks/useNotification";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProgramsService } from "services/programs";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";

const DeleteProgramButton = ({
  programId,
  programData,
  isAr,
}: {
  programId?: string;
  programData: ProgramByIDResponse | null;
  isAr: boolean;
}) => {
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const onDeleteModalConfirm = async () => {
    if (!programId) return;

    const res = await ProgramsService.deleteProgram(+programId);
    if (res.hasError) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
    } else {
      showNotification({
        type: "success",
        description: t("Common:deleteSuccess"),
      });
      setDeleteModalVisible(false);
      navigate("/home/programs");
    }
  };

  return (
    <>
      <IconButton
        icon="icon-delete"
        size="md"
        className="btn action-btn btn-sm rounded p-2"
        variant="light"
        innerIconColor="danger"
        onClick={() => setDeleteModalVisible(true)}
      />

      <ActionModal
        isOpen={deleteModalVisible}
        onClose={() => {
          setDeleteModalVisible(false);
        }}
        headerMsg={t("DeleteDialogTitle")}
        subMsg={t("deleteProgramModalSubMsg", {
          ProgramName: programData?.programDetails.find((x) => x.locale === (isAr ? "Ar" : "En"))?.displayName,
        })}
        actionIcon="icon-delete"
        confirmBtnText={t("delete")}
        iconWrapperColor={"danger"}
        onActionConfirm={onDeleteModalConfirm}
      />
    </>
  );
};

export default DeleteProgramButton;
