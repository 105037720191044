import LoadingOverlay from "components/blocks/LoadingOverlay";
import Modal from "components/blocks/Modal";
import FormViewComponent from "components/formBuilder/FormViewComponent";
import Grid from "components/GridView/components/Grid";
import { GridSchema } from "components/GridView/GridView.types";
import { useCookieContext } from "contexts";
import { useNotification } from "hooks/useNotification";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EvaluationFormsService } from "services/evaluationFormsService";
import { EvaluationFormPreview } from "services/evaluationFormsService/Models/evaluationFormsTypes";

type EvaluationFormPreviewModalProps = {
  evaluationFormId: number;
  title?: string;
  isOpen: boolean;
  size?: "sm" | "lg" | "xl";
  onClose: () => void;
};

const EvaluationFormPreviewModal: React.FC<EvaluationFormPreviewModalProps> = (props) => {
  const { isOpen, size, onClose, title, evaluationFormId } = props;
  const { t } = useTranslation("EvaluationForms");
  const { isAr } = useCookieContext();
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState(true);
  const [evaluationForm, setEvaluationForm] = useState<EvaluationFormPreview>();

  useEffect(() => {
    if (!isOpen || !!evaluationForm) return;
    fetchEvaluationForm(evaluationFormId);
  }, [evaluationFormId, isOpen]);

  const fetchEvaluationForm = async (evaluationFormId: number) => {
    setLoading(true);
    const response = await EvaluationFormsService.getById(evaluationFormId);
    if (response.hasError) {
      showNotification({ description: response.description, type: "error" });
    } else {
      const mappedResponse = {
        systemType: response.data.systemType.systemTypeDetails.displayName,
        userType: response.data.userType.userTypeDetails.displayName,
        status: t(`Common:${response.data.status}`),
        jsonSchema: response.data.jsonSchema || "",
        evaluationFormDetails:
          response.data.evaluationFormDetails.find((x) => x.locale === (isAr ? "Ar" : "En")) ??
          response.data.evaluationFormDetails[0],
        criteries: response.data.criteries.flatMap((x) => ({
          id: x.id,
          weight: x.weight,
          displayName: x.criteriaDetails.find((y) => y.locale === (isAr ? "Ar" : "En"))!.displayName,
        })),
      };

      setEvaluationForm(mappedResponse);
    }
    setLoading(false);
  };

  const criteriaGridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "displayName",
        displayName: t("criteriaName"),
        showOnMobile: true,
      },
      {
        field: "weight",
        displayName: t("weight"),
        showOnMobile: true,
        render: (row: EvaluationFormPreview["criteries"][0]) => `${row.weight}%`,
      },
    ],
    [],
  );

  return (
    <Modal
      containerClassName="bg-white"
      bodyMargin=""
      isOpen={isOpen}
      title={title ?? t("evaluationForm")}
      size={size ?? "xl"}
      onClose={onClose}
    >
      {loading ? (
        <LoadingOverlay visible />
      ) : !evaluationForm ? (
        <h3>something went wrong</h3>
      ) : (
        <div className="d-flex flex-column">
          <p>{evaluationForm.evaluationFormDetails.description}</p>

          <div className="divider" />

          <div className="d-flex align-items-center mb-4">
            <div>
              <div className="fs-14px text-gray">{t("status")}:</div>
              <div className="fs-14px fw-bold">{evaluationForm.status}</div>
            </div>
            <div className="header-circle text-gray mx-4" />
            <div>
              <div className="fs-14px text-gray">{t("systemType")}:</div>
              <div className="fs-14px fw-bold">{evaluationForm.systemType}</div>
            </div>
            <div className="header-circle text-gray mx-4" />
            <div>
              <div className="fs-14px text-gray">{t("userType")}:</div>
              <div className="fs-14px fw-bold">{evaluationForm.userType}</div>
            </div>
          </div>

          <Grid data={evaluationForm.criteries} gridSchema={criteriaGridSchema} />

          <h6 className="mt-2">{t("descriptionFields")}</h6>
          <FormViewComponent
            readOnly
            formDefinition={evaluationForm?.jsonSchema ? JSON.parse(evaluationForm.jsonSchema) : {}}
          />

          <button className="btn btn-primary px-4 align-self-end mt-3" onClick={onClose}>
            {t("Common:close")}
          </button>
        </div>
      )}
    </Modal>
  );
};

export default EvaluationFormPreviewModal;
