import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseResponseType } from "services/shared-models/sharedTypes";
import { useNotification } from "./useNotification";

type UseQueryOptions = {
  enabled?: boolean;
};

type UseQueryProps<T> = {
  triggers?: any[];
  queryFn: (...args: any[]) => Promise<BaseResponseType<T>>;
  options?: UseQueryOptions;
  onError?: (error: any) => void;
  initialLoading?: boolean;
};

export const useQuery = <T>({ queryFn, options, triggers, onError, initialLoading }: UseQueryProps<T>) => {
  const { t } = useTranslation("Common");
  const { showNotification } = useNotification();
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(initialLoading || false);

  const fetchData = useCallback(
    async (params?: any) => {
      setLoading(true);

      try {
        const response = await queryFn(params);
        if (response.hasError) {
          setData(null);
          showNotification({ description: response.description, type: "error" });
          onError && onError(response);
        } else {
          setData(response.data);
        }
      } catch (err) {
        showNotification({ description: t("Common:internalServerError"), type: "error" });
        setData(null);
        onError && onError(err);
      } finally {
        setLoading(false);
      }
    },
    [queryFn, t, options?.enabled],
  );

  useEffect(() => {
    if (options?.enabled ?? true) {
      fetchData();
    }
  }, [options?.enabled, JSON.stringify(triggers)]);

  return { data, loading, setData, refetch: fetchData };
};
