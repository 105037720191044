import { useCookieContext } from "contexts";

const LanguageToggleBtn = (props: { style?: React.CSSProperties; className?: string; variant?: string }) => {
  const { isAr, setCookie } = useCookieContext();

  const toggleLanguage = () => {
    setCookie("localization", JSON.stringify({ CurrentLanguage: isAr ? "en" : "ar", isRTL: !isAr }));
  };

  return (
    <button
      className={`btn btn-outline-${props.variant ?? "secondary"} d-flex align-items-center gap-2 ${props.className}`}
      style={props.style}
      onClick={toggleLanguage}
    >
      <span className="icon-change-lang fs-20px" />
      {isAr ? "EN" : "AR"}
    </button>
  );
};

export default LanguageToggleBtn;
