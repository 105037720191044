import { z } from "zod";

const evaluationFormDetailsSchema = z.object({
  displayName: z.string().min(1, "Common:requiredField"),
  description: z.string().min(1, "Common:requiredField"),
  locale: z.enum(["En", "Ar"]),
});

const criteriaSchema = z.object({
  id: z.number().optional(),
  weight: z.number({ message: "Common:requiredField" }).int().min(0, "minWeight").max(100, "maxWeight"),
  criteriaDetails: z.array(
    z.object({
      displayName: z.string().min(1, "Common:requiredField"),
      locale: z.enum(["En", "Ar"]),
    }),
  ),
});

export const createEvaluationFormSchema = z.object({
  status: z.enum(["Active", "InActive"]),
  systemTypeId: z.number({ message: "Common:requiredField" }).int().min(1, "Common:requiredField"),
  userTypeId: z.number({ message: "Common:requiredField" }).int().min(1, "Common:requiredField"),
  jsonSchema: z.string().nullable(),
  evaluationFormDetails: z.array(evaluationFormDetailsSchema),
  criteries: z.array(criteriaSchema),
});

export type CreateEvaluationFormRequest = z.infer<typeof createEvaluationFormSchema>;
