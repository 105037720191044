import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { ExaminerRewardsService } from "services/examinerRewards";
import { useNotification } from "hooks/useNotification";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import IconButton from "components/blocks/IconButton";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { ExaminerRewardLocalResult, ExaminerRewardStatus } from "services/examinerRewards/Models/ExaminerRewardsTypes";
import { useCookieContext } from "contexts";
import ExaminerRewardSettingsModal from "./components/ExaminerRewardSettingsModal";
import { SettingsService } from "services/settingsService";
import { FundingSettingConstants } from "shared/constants";
import { Spinner } from "react-bootstrap";
import ExaminerRewardConfirmationModal from "./components/ExaminerRewardConfirmationModal";
import { prepareFilters } from "components/GridView/components/GridFilter";

type RewardConfirmationModalProps = {
  examinerRewardId: number;
  isOpen: boolean;
  status: keyof typeof ExaminerRewardStatus;
  amount: number;
  examinerName: string;
};

export default function ExaminerRewardsGridView() {
  const { t } = useTranslation(["ExaminerRewards", "Common"]);
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { showNotification } = useNotification();
  const { setBreadcrumbs } = useBreadcrumb();
  const { isAr } = useCookieContext();

  const [rewardSettingsModalVisible, setRewardSettingsModalVisible] = useState(false);
  const [modalProps, setModalProps] = useState<RewardConfirmationModalProps>({
    examinerRewardId: 0,
    isOpen: false,
    status: "Pending",
    amount: 0,
    examinerName: "",
  });

  const [loading, setLoading] = useState(false);
  const [ExaminerRewards, setExaminerRewards] = useState<ExaminerRewardLocalResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const [rewardAmount, setRewardAmount] = useState<number | null>();
  const ready = useRef(false);

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchExaminerRewards = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await ExaminerRewardsService.getAllExaminersRewards({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: prepareFilters(filters),
      });

      setExaminerRewards(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchRewardAmount = async () => {
    try {
      const response = await SettingsService.getSettings(FundingSettingConstants.rewardAmount);
      if (!response.hasError) {
        setRewardAmount(+response.data.value);
      } else {
        setRewardAmount(null);
      }
    } catch (error) {
      setRewardAmount(null);
    }
  };

  useEffect(() => {
    fetchRewardAmount();
  }, []);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchExaminerRewards();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchExaminerRewards({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  const onConfirmationModalOpen = (row: ExaminerRewardLocalResult) => {
    setModalProps({
      isOpen: true,
      amount: row.value,
      examinerRewardId: row.id,
      status: row.status,
      examinerName: (isAr ? row.examiner?.arabicName : row.examiner?.englishName) ?? "",
    });
  };

  const onConfirmationModalClose = (refetch = false) => {
    setModalProps({
      isOpen: false,
      amount: 0,
      status: "Pending",
      examinerName: "",
      examinerRewardId: 0,
    });

    if (refetch) {
      fetchExaminerRewards({ resetPagination: true });
    }
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "examinerRewards",
        path: "/home/paymentsAndRewards/examinerRewards",
        menuItemId: "examinerRewards",
      },
    ]);
  }, []);

  const showRewardSettings = () => {
    if (rewardAmount === null) {
      fetchRewardAmount();
      return;
    }
    setRewardSettingsModalVisible(true);
  };

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "examiner.arabicName",
        field: "Examiner.ArabicName",
        displayName: t("examinerName"),
        showOnMobile: true,
        sortable: true,
        render: (row: ExaminerRewardLocalResult) => {
          return (isAr ? row.examiner?.arabicName : row.examiner?.englishName) ?? "";
        },
      },
      {
        field: "orderNumber",
        displayName: t("orderNumber"),
        render: (row: ExaminerRewardLocalResult) => {
          return <Link to={`/home/orders/view/${row.programOrderId}`}>{row.orderNumber ?? t("orderNumber")}</Link>;
        },
      },
      {
        field: "centreManagement",
        displayName: t("researchesCenter"),
        render(row) {
          return <span>{row.centreManagement?.displayName}</span>;
        },
      },
      {
        field: "appliedUser",
        displayName: t("applicantName"),
        render(row) {
          return <span>{isAr ? row.appliedUser?.arabicName : row.appliedUser?.englishName}</span>;
        },
      },
      {
        field: "ApprovalDate",
        displayName: t("rewardConfirmationDate"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "date",
        },
        render(row: ExaminerRewardLocalResult) {
          return <>{toSystemThemeDateFormat(row.approvalDate)}</>;
        },
      },
      {
        field: "value",
        displayName: t("amount"),
        sortable: true,
        filterable: true,
        render(row: ExaminerRewardLocalResult) {
          return (
            <span>
              {row.value} {t("riyal")}
            </span>
          );
        },
      },
      {
        field: "status",
        displayName: t("status"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        filterOptions: {
          type: "select",
          options: [
            { id: "Paid", value: t("paid") },
            { id: "NotPaid", value: t("notpaid") },
            { id: "Reject", value: t("reject") },
            { id: "Pending", value: t("pending") },
          ],
        },
        render(row: ExaminerRewardLocalResult) {
          const statusClassName =
            row.status === "NotPaid" || row.status === "Reject"
              ? "bg-danger text-danger"
              : row.status === "Paid"
              ? "bg-success text-success"
              : "bg-primary text-primary";
          return (
            <span className={`badge rounded-4 ${statusClassName} bg-opacity-10 py-2`}>
              {t(row.status.toLowerCase())}
            </span>
          );
        },
      },
      {
        field: "actions",
        displayName: t("Common:actions"),
        showOnMobile: true,
        render: (row: ExaminerRewardLocalResult) => {
          if (row.status === "Paid" || row.status === "Reject") {
            return null;
          }

          return (
            <IconButton
              icon="icon-edit"
              fitToIconWidth
              innerIconColor="primary"
              size="md"
              onClick={() => onConfirmationModalOpen(row)}
            />
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("allExaminerRewards"),
    singularName: t("examinerReward"),
    totalCount: totalCount,
    actionsComponent: (
      <button className="btn btn-primary px-4" onClick={showRewardSettings}>
        {rewardAmount === undefined ? (
          <Spinner size="sm" />
        ) : rewardAmount === null ? (
          t("errorLoadingRewardAmount-reload")
        ) : (
          t("rewardAmountRiyal", { amount: rewardAmount })
        )}
      </button>
    ),
  };

  return (
    <div>
      <GridView
        loading={loading}
        gridProps={{
          data: ExaminerRewards ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />

      <ExaminerRewardConfirmationModal
        isOpen={modalProps.isOpen}
        onClose={onConfirmationModalClose}
        data={modalProps}
      />

      {!!rewardAmount && (
        <ExaminerRewardSettingsModal
          initialRewardAmount={rewardAmount}
          isOpen={rewardSettingsModalVisible}
          onClose={() => {
            setRewardAmount(undefined);
            fetchRewardAmount();
            setRewardSettingsModalVisible(false);
          }}
        />
      )}
    </div>
  );
}
