import Dropdown, { DropdownItemType } from "components/blocks/Dropdown";
import FormInput from "components/blocks/FormInput";
import LookupDropdown from "components/blocks/LookupDropdown";
import Modal from "components/blocks/Modal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FinancingTermsRequest } from "services/closeOrder/models";
import { FinancingTypes, ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import { getCreateOrderSchema } from "./createFundingTerm.schem";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import { useNotification } from "hooks/useNotification";
import { useQuery } from "hooks/useQuery";
import { AccountService } from "services/accountService";
import { useCookieContext } from "contexts";

export type FinancingDropdownType = DropdownItemType & {
  calculationWay: ProgramByIDResponse["financingCalculationItems"][0]["financingType"]["calculationWay"];
  fromValue: number;
  toValue: number;
  isPrimaryParticipantType?: boolean;
};

const AddEditFundingTermModal = ({
  isOpen,
  onClose,
  selectedFundingTerm,
  orderProgram,
  order,
  currentTotalFunding,
  selectedTerms,
}: {
  isOpen: boolean;
  onClose: (newTerm?: FinancingTermsRequest) => void;
  selectedFundingTerm?: FinancingTermsRequest | null;
  orderProgram?: ProgramByIDResponse | null;
  order?: OrderLocalItemResult | null;
  currentTotalFunding: number;
  selectedTerms?: FinancingTermsRequest[] | undefined;
}) => {
  const { showNotification } = useNotification();
  const { userSession, isAr } = useCookieContext();
  const { t } = useTranslation(["Orders", "UserProfile"]);
  const [selectedType, setSelectedType] = useState<FinancingDropdownType>();
  const {
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    reset,
  } = useForm<FinancingTermsRequest>({
    defaultValues: {
      financingTypeId: 0,
      timeInMonth: 0,
      value: 0,
      name: "",
      degreeId: 0,
      specializationId: 0,
      email: "",
      id: 0,
      nationalityId: 0,
      notes: "",
      passportNumber: "",
      workAddress: "",
    },
    resolver: getCreateOrderSchema(t, selectedType),
  });

  useEffect(() => {
    if (selectedFundingTerm) {
      reset(selectedFundingTerm);

      const selected = orderProgram?.financingCalculationItems.find(
        (item) => item.financingType.id === selectedFundingTerm.financingTypeId,
      );

      if (selected) {
        setSelectedType({
          id: selected.financingType.id,
          value: selected.financingType.financingTypesDetail.displayName,
          calculationWay: selected.financingType.calculationWay,
          fromValue: selected.fromValue,
          toValue: selected.toValue,
          isPrimaryParticipantType: selected.financingType.id === FinancingTypes.PrimaryParticipant,
        });
      }
    }
  }, [selectedFundingTerm, orderProgram]);

  const onSubmit = (values: FinancingTermsRequest) => {
    const newTerm: FinancingTermsRequest = {
      financingTypeId: 0,
      timeInMonth: 0,
      financingType: {
        calculationWay: selectedType?.calculationWay,
        financingTypesDetail: {
          displayName: selectedType?.value ?? "",
        },
        id: 0,
      },
      value: 0,
      name: "",
      degreeId: 0,
      specializationId: 0,
      email: "",
      id: values.id,
      nationalityId: 0,
      notes: "",
      passportNumber: "",
      workAddress: "",
    };

    let totalAmount = 0;
    if (!selectedType?.calculationWay) {
      newTerm.financingTypeId = values.financingTypeId;
      newTerm.value = values.value;
      newTerm.notes = values.notes;
      totalAmount = values.value;
    }

    if (selectedType?.calculationWay) {
      newTerm.financingTypeId = values.financingTypeId;
      newTerm.timeInMonth = values.timeInMonth;
      newTerm.value = values.value;
      newTerm.name = values.name;
      newTerm.degreeId = values.degreeId;
      newTerm.specializationId = values.specializationId;
      newTerm.nationalityId = values.nationalityId;
      newTerm.passportNumber = values.passportNumber;
      newTerm.workAddress = values.workAddress;
      newTerm.email = values.email;
      totalAmount = values.value * values.timeInMonth;
    }

    if (currentTotalFunding + totalAmount > (order?.fundingAmount ?? 0)) {
      showNotification({
        description: t("fundingAmountExceeds"),
        type: "error",
      });
      return;
    }

    onModalClose(newTerm);
  };

  const { data: userInfo } = useQuery({
    queryFn: async () => {
      const user = userSession ? JSON.parse(userSession) : undefined;

      return await AccountService.getUserByIdAsync(user ? user.id : 0);
    },
    options: {
      enabled: !!userSession,
    },
  });

  useEffect(() => {
    if (selectedType?.isPrimaryParticipantType && userInfo) {
      setValue("nationalityId", userInfo?.nationalityId ?? 0);
      setValue("degreeId", userInfo.degreeId ?? 0);
      setValue("specializationId", userInfo?.specializationId ?? 0);
      // setValue("workAddress", userInfo?.address ?? "");
      setValue("email", userInfo?.email ?? "");
      // setValue("passportNumber", userInfo?.passportNumber ?? "");
      setValue("name", (isAr ? userInfo?.arabicName : userInfo.englishName) ?? "");
    }
  }, [selectedType, userInfo]);

  const onModalClose = (newTerm?: FinancingTermsRequest) => {
    onClose(newTerm);
    reset({
      financingTypeId: 0,
      timeInMonth: 0,
      value: 0,
      name: "",
      degreeId: 0,
      specializationId: 0,
      email: "",
      id: 0,
      financingType: undefined,
      nationalityId: 0,
      notes: "",
      passportNumber: "",
      workAddress: "",
    });
    setSelectedType(undefined);
  };

  const fundingTerms = orderProgram?.financingCalculationItems
    .filter(
      (item) =>
        !!selectedFundingTerm || // edit mode
        !item.financingType.repetitionAllowed ||
        item.financingType.repetitionAllowed >
          (selectedTerms?.reduce((acc, ele) => {
            return ele.financingTypeId === item.financingType.id ? acc + 1 : acc;
          }, 0) ?? 0),
    )
    .map((item) => ({
      id: item.financingType.id,
      value: item.financingType.financingTypesDetail.displayName,
      calculationWay: item.financingType.calculationWay,
      fromValue: item.fromValue,
      toValue: item.toValue,
      isPrimaryParticipantType: item.financingType.id === FinancingTypes.PrimaryParticipant,
    })) as FinancingDropdownType[];

  const currentValue = selectedType?.calculationWay ? watch("value") * watch("timeInMonth") : watch("value");

  return (
    <Modal
      containerClassName="bg-white pb-2"
      withHeaderBorder
      size="lg"
      titleClassName="fs-6 text-dark"
      bodyMargin="py-3"
      isOpen={isOpen}
      title={t("addFundingTerm")}
      onClose={() => onModalClose()}
    >
      <form noValidate className="d-flex flex-column gap-3" onSubmit={handleSubmit(onSubmit)}>
        {!selectedType || selectedType.calculationWay ? (
          <>
            <div className="d-flex gap-3">
              <Dropdown
                label={t("agreementTerms")}
                data={fundingTerms}
                wrapperClassName="flex-1"
                onChange={(v, item) => {
                  setValue("financingTypeId", +v);
                  setSelectedType(item as FinancingDropdownType);
                }}
                noSelectionPlaceholder={t("agreementTerms")}
                error={errors.financingTypeId?.message}
                value={watch("financingTypeId") ? watch("financingTypeId") : ""}
              />
              <FormInput
                label={t("count")}
                leftElement={<span className="text-primary mx-2">{t("month")}</span>}
                type="number"
                wrapperClassName="flex-1"
                value={watch("timeInMonth")}
                onChange={(e) => {
                  setValue("timeInMonth", +e.target.value);
                }}
                error={errors.timeInMonth?.message}
              />
            </div>
            <div className="d-flex gap-3">
              <FormInput
                label={`${t("amountWithoutUnit")} ${
                  selectedType ? `(${selectedType?.fromValue}-${selectedType?.toValue})` : ""
                }`}
                leftElement={<span className="text-primary mx-2">{t("ryal")}</span>}
                type="number"
                wrapperClassName="flex-1"
                value={watch("value")}
                onChange={(e) => {
                  setValue("value", +e.target.value);
                }}
                error={errors.value?.message}
              />
              <FormInput
                label={t("name")}
                wrapperClassName="flex-1"
                value={watch("name")}
                onChange={(e) => {
                  setValue("name", e.target.value);
                }}
                error={errors.name?.message}
                readOnly={selectedType?.isPrimaryParticipantType && !!(userInfo?.arabicName || userInfo?.englishName)}
              />
            </div>
            <div className="d-flex gap-3">
              <LookupDropdown
                label={t("academicDegree")}
                wrapperClassName="flex-1"
                service="lookupService"
                endpoint="getDegrees"
                idValueKey="id"
                noSelectionPlaceholder={t("academicDegree")}
                textValueKey="degreeDetail.displayName"
                onChange={(v) => {
                  setValue("degreeId", +v);
                }}
                error={errors.degreeId?.message}
                value={watch("degreeId") ? watch("degreeId") : ""}
                readOnly={selectedType?.isPrimaryParticipantType && !!userInfo?.degreeId}
              />

              <LookupDropdown
                label={t("UserProfile:specialization")}
                wrapperClassName="flex-1"
                service="lookupService"
                endpoint="getAllGeneralSpecialization"
                idValueKey="id"
                textValueKey="generalSpecializationDetail.displayName"
                noSelectionPlaceholder={t("UserProfile:specialization") as string}
                onChange={(v) => {
                  setValue("specializationId", +v);
                }}
                error={errors.specializationId?.message}
                value={watch("specializationId") ? watch("specializationId") : ""}
                readOnly={selectedType?.isPrimaryParticipantType && !!userInfo?.specializationId}
              />
            </div>
            <div className="d-flex gap-3">
              <LookupDropdown
                label={t("UserProfile:nationality")}
                wrapperClassName="flex-1"
                service="lookupService"
                endpoint="getNationalities"
                idValueKey="id"
                textValueKey="countryDetail.displayName"
                noSelectionPlaceholder={t("UserProfile:nationality") as string}
                onChange={(v) => {
                  setValue("nationalityId", +v);
                }}
                error={errors.nationalityId?.message}
                value={watch("nationalityId") ? watch("nationalityId") : ""}
                readOnly={selectedType?.isPrimaryParticipantType && !!userInfo?.nationalityId}
              />

              <FormInput
                label={t("passportNumber")}
                placeholder={t("passportNumber")}
                wrapperClassName="flex-1"
                value={watch("passportNumber")}
                onChange={(e) => {
                  setValue("passportNumber", e.target.value);
                }}
                error={errors.passportNumber?.message}
              />
            </div>
            <div className="d-flex gap-3">
              <FormInput
                label={t("workingPlace")}
                wrapperClassName="flex-1"
                placeholder={t("workingPlace")}
                value={watch("workAddress")}
                onChange={(e) => {
                  setValue("workAddress", e.target.value);
                }}
                error={errors.workAddress?.message}
              />
              <FormInput
                label={t("email")}
                wrapperClassName="flex-1"
                placeholder={t("email")}
                value={watch("email")}
                onChange={(e) => {
                  setValue("email", e.target.value);
                }}
                error={errors.email?.message}
                readOnly={selectedType?.isPrimaryParticipantType && !!userInfo?.email}
              />
            </div>
            <div>
              <FormInput
                label={t("currentTotalFunding / orderTotalFunding")}
                wrapperClassName="flex-1"
                value={`${order?.fundingAmount ?? 0} / ${currentTotalFunding + currentValue} `}
                readOnly
                onChange={(e) => {
                  setValue("notes", e.target.value);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex gap-3">
              <Dropdown
                label={t("agreementTerms")}
                data={fundingTerms}
                wrapperClassName="flex-1"
                onChange={(v, item) => {
                  setValue("financingTypeId", +v);
                  setSelectedType(item as FinancingDropdownType);
                }}
                noSelectionPlaceholder={t("agreementTerms")}
                error={errors.financingTypeId?.message}
                value={watch("financingTypeId") ? watch("financingTypeId") : ""}
              />
              <FormInput
                label={t("amount", {
                  unit: `${selectedType?.fromValue}-${selectedType?.toValue}`,
                })}
                leftElement={<span className="text-primary mx-2">{t("ryal")}</span>}
                type="number"
                wrapperClassName="flex-1"
                value={watch("value")}
                onChange={(e) => {
                  setValue("value", +e.target.value);
                }}
                error={errors.value?.message}
              />
            </div>
            <FormInput
              label={t("notes")}
              wrapperClassName="flex-1"
              value={watch("notes")}
              onChange={(e) => {
                setValue("notes", e.target.value);
              }}
              error={errors.notes?.message}
            />
          </>
        )}
        <div className="divider "></div>

        <div className="d-flex justify-content-center gap-3 w-100">
          <button type="button" className="btn border text-dark rounded-3 p-10-32P" onClick={() => onModalClose()}>
            {t("Common:cancel")}
          </button>
          <button
            type="submit"
            className="btn btn-primary rounded-3 p-10-32P" /* disabled={loading || !field.value?.length} */
          >
            {t("Common:add")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddEditFundingTermModal;
