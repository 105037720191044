import Button from "components/blocks/Button";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import ExtendRequestModal from "./Modals/ExtendRequestModal";
import EditRequestModal from "./Modals/EditRequestModal";
import ApologizeRequestModal from "./Modals/ApologizeRequestModal";
import { OrderActivePages } from "views/home/orders/OrderView";
import RequestAnQueryModal from "./Modals/RequestAnQueryModal";

const ResearcherActions = ({
  orderId,
  setActivePage,
}: {
  orderId: number;
  setActivePage: Dispatch<SetStateAction<keyof typeof OrderActivePages>>;
}) => {
  const { t } = useTranslation("Orders");
  const [modalProps, setModalProps] = useState<{
    isOpen: boolean;
    type: "edit" | "extend" | "apologize" | "none" | "query";
  }>({
    isOpen: false,
    type: "none",
  });

  const onClickButton = (type: "edit" | "extend" | "apologize" | "query") => {
    setModalProps({ isOpen: true, type });
  };

  return (
    <div className="d-flex gap-2">
      <Button
        variant="primary"
        innerIconColor="white"
        icon="icon-yes"
        label={t("closeRequest")}
        className="p-2 "
        onClick={() => setActivePage("ResearchCloseRequest")}
      />
      <Button
        icon="icon-edit"
        innerIconColor="secondary"
        label={t("updateRequest")}
        className="p-2 border"
        onClick={() => onClickButton("edit")}
      />
      <Button
        icon="icon-add-time"
        innerIconColor="secondary"
        label={t("extendRequest")}
        className="p-2 border"
        onClick={() => onClickButton("extend")}
      />
      <Button
        icon="icon-apologize"
        innerIconColor="secondary"
        label={t("apologizeRequest")}
        className="p-2 border"
        onClick={() => onClickButton("apologize")}
      />

      <Button
        icon="icon-document"
        innerIconColor="secondary"
        label={t("queryRequest")}
        className="p-2 border"
        onClick={() => onClickButton("query")}
      />

      <ExtendRequestModal
        isOpen={modalProps.isOpen && modalProps.type === "extend"}
        onClose={() => setModalProps({ type: "none", isOpen: false })}
        onConfirm={() => setModalProps({ type: "none", isOpen: false })}
        orderId={orderId}
      />

      <EditRequestModal
        isOpen={modalProps.isOpen && modalProps.type === "edit"}
        onClose={() => setModalProps({ type: "none", isOpen: false })}
        onConfirm={() => setModalProps({ type: "none", isOpen: false })}
        orderId={orderId}
      />

      <ApologizeRequestModal
        isOpen={modalProps.isOpen && modalProps.type === "apologize"}
        onClose={() => setModalProps({ type: "none", isOpen: false })}
        onConfirm={() => setModalProps({ type: "none", isOpen: false })}
        orderId={orderId}
      />

      <RequestAnQueryModal
        isOpen={modalProps.isOpen && modalProps.type === "query"}
        onClose={() => setModalProps({ type: "none", isOpen: false })}
        onConfirm={() => setModalProps({ type: "none", isOpen: false })}
        orderId={orderId}
      />
    </div>
  );
};

export default ResearcherActions;
