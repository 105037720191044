import React from "react";

import { Outlet } from "react-router-dom";
import Header from "../../views/home/shared/header";
import SideMenu from "../../views/home/shared/sideMenu";

function HomeLayout() {
  return (
    <div className={"text-darkGray h-100"}>
      <Header />
      <div className="main-container m-15p">
        <div
          id="side-menu-wrapper"
          className="calc-h-100 rounded-4 position-relative mx-7P side-menu-wrapper collapsed"
        >
          <SideMenu />
          <div
            className="backdrop"
            onClick={() => {
              document.getElementById("side-menu-wrapper")?.classList.toggle("collapsed");
            }}
          />
        </div>
        <main className="calc-h-100 bg-white rounded-4 overflow-auto z-0">
          {/* TODO: find a way to se this dynamically from children bg-white rounded-4 */}
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default HomeLayout;
