import IconButton from "components/blocks/IconButton";
import useMutation from "hooks/useMutation";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { AccountService } from "services/accountService";

const ExternalExaminerSection = ({
  externalExaminersEmail,
  setExternalExaminersEmail,
}: {
  externalExaminersEmail: { email: string; isSentInvitation: boolean }[];
  setExternalExaminersEmail: React.Dispatch<React.SetStateAction<{ email: string; isSentInvitation: boolean }[]>>;
}) => {
  const { t } = useTranslation(["Orders"]);
  const clickedEmailToInvite = useRef<string | null>(null);
  const { loading, mutateAsync } = useMutation({
    queryFn: async (email: string) => {
      return await AccountService.sendInvitations({
        emails: [email],
      });
    },
  });

  const sendInvitation = async (email: string) => {
    clickedEmailToInvite.current = email;
    await mutateAsync(email);
    clickedEmailToInvite.current = null;
    setExternalExaminersEmail((old) => old.map((u) => (u.email === email ? { ...u, isSentInvitation: true } : u)));
  };

  return (
    <div className="d-flex flex-column gap-2 ">
      {externalExaminersEmail && !!externalExaminersEmail.length && (
        <div className="d-flex gap-1 bg-light p-2 rounded-4 fw-bold border pe-4">
          <IconButton
            icon="icon-close"
            variant="transparent"
            innerIconColor="secondary"
            fitToIconWidth
            onClick={() => {
              setExternalExaminersEmail([]);
            }}
          />
          <span className="fs-6">{t("Email")}</span>
        </div>
      )}
      {externalExaminersEmail.map((user) => {
        return (
          <div key={user.email}>
            <div className="d-flex gap-2 align-items-center justify-content-between">
              <div className="d-flex gap-1 align-items-center flex-1">
                <IconButton
                  icon="icon-close"
                  variant="transparent"
                  innerIconColor="secondary"
                  fitToIconWidth
                  onClick={() => {
                    setExternalExaminersEmail(externalExaminersEmail.filter((u) => u.email !== user.email));
                  }}
                />
                <span className="fs-6">{user.email}</span>
              </div>
              {loading && user.email === clickedEmailToInvite.current ? (
                <div className="custom-loader"></div>
              ) : !user.isSentInvitation ? (
                <button type="button" className="btn btn-link" onClick={() => sendInvitation(user.email)}>
                  {t("sendInvitation")}
                </button>
              ) : (
                <span className="text-success">{t("invitationSent")}</span>
              )}
            </div>
            <div className="divider" />
          </div>
        );
      })}
    </div>
  );
};

export default ExternalExaminerSection;
