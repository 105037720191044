import { DataGridRequest, PagedResponseType } from "services/shared-models/sharedTypes";
import { ResearcherLocalResult } from "./models/ResearcherTypes";
import { HttpClient } from "helpers";
import { AxiosResponse } from "axios";

export const ResearchersService = {
  getAllResearchersAsync: async (data: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<ResearcherLocalResult>>("/api/Researchers/GetAllResearchers", data);
  },
  exportResearchersToExcelAsync: async (data: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>("/api/Researchers/ExportResearchersToExcel", data, {
      responseType: "blob",
    });
  },
};
