import { HttpClient } from "helpers";
import { BaseResponseType, DataGridRequest, PagedResponseType } from "services/shared-models/sharedTypes";
import {
  CurrentExaminerRewardLocalResult,
  ExaminerRewardItemResult,
  ExaminerRewardLocalResult,
  UpdateExaminerRewardRequest,
} from "./Models/ExaminerRewardsTypes";

export const ExaminerRewardsService = {
  updateExaminerReward: async (id: number, request: UpdateExaminerRewardRequest) => {
    return await HttpClient.put<BaseResponseType<ExaminerRewardItemResult>>(
      `/api/ExaminerRewards/UpdateExaminerReward/${id}`,
      request,
    );
  },
  rejectExaminerReward: async (id: number) => {
    return await HttpClient.put<BaseResponseType<ExaminerRewardItemResult>>(
      `/api/ExaminerRewards/RejectExaminerReward/${id}`,
    );
  },
  getExaminersRewardById: async (id: number) => {
    return await HttpClient.get<BaseResponseType<ExaminerRewardItemResult>>(
      `/api/ExaminerRewards/GetExaminersRewardById/${id}`,
    );
  },
  getAllExaminersRewards: async (request: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<ExaminerRewardLocalResult>>(
      `/api/ExaminerRewards/GetAllExaminersRewards`,
      request,
    );
  },
  getAllExaminerRewardsForCurrentUser: async (request: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<CurrentExaminerRewardLocalResult>>(
      `/api/ExaminerRewards/GetAllExaminerRewardsForCurrentUser`,
      request,
    );
  },
  exportExaminersRewardsToExcel: async (request: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<ExaminerRewardLocalResult>>(
      `/api/ExaminerRewards/ExportExaminersRewardsToExcel`,
      request,
    );
  },
};
