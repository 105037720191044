import FormInput from "components/blocks/FormInput";
import { UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CriteriaValueResult } from "services/examiners/Model/ExaminerTypes";
import { SubmittedExaminerOrderEvaluationFormRequestFormValueType } from "./createExaminerEvaluation.schema";
import { useState } from "react";

const textLengthToRows = (len: number) => {
  if (len > 60) {
    return 5;
  }

  if (len > 40) {
    return 4;
  }

  if (len > 20) {
    return 3;
  }

  return 1;
};

const ExaminationCriteriaField = ({
  criteriaValue,
  index,
  errors,
  setValue,
  evaluationPercentage,
}: {
  criteriaValue?: Omit<CriteriaValueResult, "criteriaValueDetails" | "criteriaId" | "id">;
  index: number;
  setValue: UseFormSetValue<SubmittedExaminerOrderEvaluationFormRequestFormValueType>;
  errors: {
    criteriaValue?: string;
    notes?: string;
  };
  evaluationPercentage: number;
}) => {
  const { t } = useTranslation(["ExaminerTask", "Common"]);
  const [showOutOfRangeError, setShowOutOfRangeError] = useState(false);

  const onCriteriaValueChange = (value: number) => {
    if (!criteriaValue || value < 0 || value > 100) {
      !errors.criteriaValue && setShowOutOfRangeError(true);
      return;
    }

    setShowOutOfRangeError(false);

    const weight = criteriaValue.criteria.weight ?? 0;
    const oldValue = criteriaValue.value ?? 0;
    setValue(`criteriaValuesRequest.${index}.value`, value);
    setValue(`criteriaValuesRequest.${index}.criteriaId`, criteriaValue.criteria.id);

    const newEvaluationPercentage = evaluationPercentage + ((value - oldValue) * weight) / 100;
    setValue("evaluationPercentage", newEvaluationPercentage);
  };

  const displayName = criteriaValue?.criteria.criteriaDetail.displayName;

  return (
    <div className="row mb-4 hide-number-controls align-items-center">
      <div className="col-sm-12 col-md-3">
        <label className="mb-1">{`${t("evaluationCriteria")} ${index + 1}`}</label>
        <textarea
          className="form-control"
          value={displayName}
          disabled
          style={{
            textWrap: "wrap",
            resize: "none",
          }}
          rows={textLengthToRows(displayName?.length ?? 0)}
        />
      </div>
      <FormInput
        disabled
        wrapperClassName="col-sm-12 col-md-2"
        label={t("wight")}
        placeholder={t("wight")}
        leftElement={<span className="fs-24px text-primary">%</span>}
        value={criteriaValue?.criteria.weight ?? 0}
      />
      <FormInput
        wrapperClassName="col-sm-12 col-md-2"
        label={t("result")}
        placeholder={t("result")}
        leftElement={<span className="fs-24px text-primary">%</span>}
        type="number"
        value={criteriaValue?.value}
        error={errors.criteriaValue}
        onChange={(e) => onCriteriaValueChange(Number(e.target.value))}
      />
      <FormInput
        wrapperClassName="col-sm-12 col-md-5"
        label={t("notes")}
        placeholder={t("notes")}
        value={criteriaValue?.notes}
        onChange={(e) => setValue(`criteriaValuesRequest.${index}.notes`, e.target.value)}
        error={errors.notes}
      />
      {showOutOfRangeError && <span className="invalid-feedback my-2 d-block">{t("resultShouldBeBetween100&1")}</span>}
    </div>
  );
};

export default ExaminationCriteriaField;
