import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import useGridStore from "components/GridView/grid.store";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import Dropdown from "components/blocks/Dropdown";
import { useCookieContext } from "contexts";
import { useNotification } from "hooks/useNotification";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { OrdersService } from "services/orders";
import { OrderLocalResult } from "services/orders/models/OrdersTypes";
import { useShallow } from "zustand/react/shallow";
import AddProgramMinutes, { AddEditProgramMinutesRef } from "./AddProgramMinutes";
import ResearcherTypeDropdown from "./ResearcherTypeDropdown";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import { TaskActionsNames } from "services/tasks/models/tasksTypes";
import { prepareFilters } from "components/GridView/components/GridFilter";

const ViceDeanOrdersTab = ({
  program,
  addEditProgramMinutesRef,
  actionNames,
  serialGuid,
}: {
  program: ProgramByIDResponse;
  addEditProgramMinutesRef: React.RefObject<AddEditProgramMinutesRef>;
  serialGuid?: string | null;
  actionNames?: TaskActionsNames[] | null;
}) => {
  const { t } = useTranslation(["Programs", "Orders"]);
  const { showNotification } = useNotification();
  const { isAr } = useCookieContext();

  const ready = useRef(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<OrderLocalResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchOrders = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await OrdersService.getAllOrdersAsync({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: [
          ...prepareFilters(filters),
          ...(program
            ? [
                {
                  field: "ProgramId",
                  operator: "equal",
                  value: program.id,
                },
              ]
            : []),
        ],
      });

      setOrders(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchOrders();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchOrders({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "id",
        field: "id",
        displayName: t("Orders:orderNumber"),
        sortable: true,
        render: (row: OrderLocalResult) => {
          return <Link to={`/home/orders/view/${row.id}`}>{row.serialNumber}</Link>;
        },
      },
      {
        accessor: "appliedUserName",
        field: "appliedUserName",
        displayName: t("Orders:applicantName"),
        sortable: false,
        filterable: true,
      },
      {
        accessor: "college",
        field: "college",
        displayName: t("Programs:college"),
        sortable: false,
      },
      {
        accessor: "degree",
        field: "degree",
        displayName: t("Programs:degree"),
      },
      {
        field: "centreManagementDetail.displayName",
        displayName: t("Orders:researchesCenter"),
        sortable: false,
        render(row: OrderLocalResult) {
          return <>{row.centreManagementDetail.displayName}</>;
        },
      },
      {
        field: "centreManagementDetailRecommendation",
        displayName: t("Programs:centreManagementDetailRecommendation"),
      },
      {
        field: "recommendationNotes",
        displayName: t("recommendationNotes"),
        sortable: false,
      },
      {
        field: "submissionDate",
        displayName: t("submissionDate"),
        sortable: false,
      },
      {
        field: "finalEvaluation",
        displayName: t("finalEvaluation"),
        sortable: false,
      },
      {
        field: "orderStatus",
        displayName: t("orderStatus"),
        sortable: false,
      },
      {
        field: "orderPhase",
        displayName: t("orderPhase"),
        sortable: false,
      },
      {
        field: "suggestedTitle",
        displayName: t("suggestedTitle"),
        sortable: false,
      },
      {
        field: "finalCommitteeDecision",
        displayName: t("finalCommitteeDecision"),
        sortable: false,
        render(row: OrderLocalResult) {
          return <ResearcherTypeDropdown order={row} />;
        },
      },
    ],
    [t],
  );

  const viewHeader: GridViewHeaderProps = {
    title: "",
    singularName: "",
    totalCount: totalCount,
    hideAddButton: true,
    onExportClick: async () => {
      /* setLoading(true);
      const response = await OrdersService.exportToExcel({
        pageIndex: pagination.currentPage,
        pageSize: 10,
        search: searchValue,
      });
      await downloadFile(response, "orders.xlsx");
      setLoading(false); */
    },
  };

  return (
    <div className="bg-white rounded-4 h-100">
      <Accordion>
        <AccordionItem initiallyOpen title={t("orders")} elementId="orders">
          <GridView
            loading={loading}
            gridProps={{
              data: orders ?? [],
              gridSchema,
            }}
            viewHeaderProps={viewHeader}
          />
        </AccordionItem>
      </Accordion>

      <AddProgramMinutes
        program={program}
        ref={addEditProgramMinutesRef}
        orders={orders}
        serialGuid={serialGuid}
        actionNames={actionNames}
      />
    </div>
  );
};

export default ViceDeanOrdersTab;
