import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useNotification } from "hooks/useNotification";
import { t } from "i18next";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProgramsService } from "services/programs";
import { programUserTypes } from "../ProgramsGridView";
import { useQuery } from "hooks/useQuery";
import { OrdersService } from "services/orders";
import { TasksService } from "services/tasks";

const useViewProgram = ({ userType }: { userType: keyof typeof programUserTypes }) => {
  const { programId, serialGID } = useParams();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { setBreadcrumbs } = useBreadcrumb();

  const { data, loading: openOrderLoading } = useQuery({
    queryFn: async () => {
      const task = await TasksService.openFundingOrderTask(serialGID ? serialGID : "");

      return {
        code: 200,
        description: "",
        data: task,
        hasError: false,
      };
    },
    options: {
      enabled: !!serialGID,
    },
    initialLoading: !!serialGID,
  });

  const { data: orderData, loading: orderLoading } = useQuery({
    queryFn: async () => {
      return await OrdersService.getOneById(data?.orderId ?? 0);
    },
    options: {
      enabled: !!serialGID && !!data?.orderId,
    },
  });

  const { data: programData, loading } = useQuery({
    queryFn: async () => {
      const proId = orderData?.programId ? orderData?.programId : programId ? +programId : data?.programId ?? 0;
      const res = await ProgramsService.getProgramById(proId);

      if (res && res.hasError) {
        showNotification({ description: res.description, type: "error" });
        navigate(-1);
      }

      return res;
    },
    options: {
      enabled: !!programId || !!data?.programId || !!orderData?.programId,
    },
    initialLoading: true,
  });

  useEffect(() => {
    if (!openOrderLoading && !loading && !orderLoading && !programData) {
      showNotification({ description: t("Common:recordNotFound"), type: "error" });
      navigate(-1);
    }
  }, [programData, openOrderLoading, orderLoading, loading]);

  useEffect(() => {
    if (data && !data.orderId && !data.programId) {
      showNotification({ description: t("Common:recordNotFound"), type: "error" });
      navigate(-1);
    }
  }, [data]);

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: userType === programUserTypes.ViceDean && serialGID ? "tasks" : "allPrograms",
        path:
          (userType === programUserTypes.ViceDean || userType === programUserTypes.Researcher) && serialGID
            ? "/home/tasks"
            : "/home/programs",
      },
      {
        localizationKey: "program",
        path:
          userType === programUserTypes.Admin
            ? `/home/programs/view/${programId}`
            : userType === programUserTypes.FundingUnit
            ? `/home/programs/fundingUnitPrograms/${programId}`
            : userType === programUserTypes.ViceDean
            ? !!serialGID
              ? `/home/tasks/viceDeanTask/${programId}`
              : `/home/viceDeanPrograms/${programId}`
            : `/home/tasks/researcherSignContractTask/${serialGID}`,
        localizationParameters: { programNumber: programData?.serialNumber ?? "" },
      },
    ]);
  }, [programData]);

  return {
    programData: programData,
    loading: loading || orderLoading || openOrderLoading,
    programId,
    orderData,
    serialGID,
    actionNames: data?.actionName,
  };
};

export default useViewProgram;
