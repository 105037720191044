import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { OrderActivePages, OrderUserTypes } from "../../../..";
import IconButton from "components/blocks/IconButton";
import Button from "components/blocks/Button";
import AddEditFundingTermModal from "./AddEditFundingTermModal";
import DeleteTermModal from "./DeleteTermModal";
import { CloseOrderFormProps } from "views/home/orders/OrderView/ResearcherCloseRequestPage";
import { FinancingTermsRequest } from "services/closeOrder/models";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";

const FundingTerms = ({
  activePage,
  userType,
  closeOrderFormProps,
  orderProgram,
  order,
}: {
  userType: keyof typeof OrderUserTypes;
  activePage: keyof typeof OrderActivePages;
  closeOrderFormProps?: CloseOrderFormProps;
  orderProgram?: ProgramByIDResponse | null;
  order: OrderLocalItemResult | null;
}) => {
  const isResearcherMode =
    userType === OrderUserTypes.Researcher || userType === OrderUserTypes.ResearcherSendCloseResponseAfterReturnRequest;
  const { t } = useTranslation("Orders");
  const [addFundingTermModalProps, setAddFundingTermModalOpenedProps] = useState<{
    isOpen: boolean;
    selectedFundingTerm: FinancingTermsRequest | null;
    selectedIdx?: number;
  }>({
    isOpen: false,
    selectedFundingTerm: null,
    selectedIdx: 0,
  });

  const financingTermsRequest =
    closeOrderFormProps?.watch("financingTermsRequest") ?? order?.closeOrder?.financingTerms;
  const fundingTermsTableHeaders = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "financingTypeId",
        field: "financingTypeId",
        displayName: t("agreementTerms"),
        showOnMobile: true,
        render: (row: FinancingTermsRequest) => {
          return <>{row.financingType.financingTypesDetail.displayName}</>;
        },
      },
      {
        accessor: "name",
        field: "name",
        displayName: t("name"),
        render: (row: FinancingTermsRequest) => {
          return <>{row.name ? row.name : "-"}</>;
        },
      },
      {
        accessor: "timeInMonth",
        field: "timeInMonth",
        displayName: t("counts"),
        render: (row: FinancingTermsRequest) => {
          return <>{row.financingType.calculationWay ? `${row.timeInMonth} ${t("month")}` : "-"}</>;
        },
      },
      {
        accessor: "value",
        field: "value",
        displayName: t("amount", { unit: t("ryal") }),
      },
      {
        accessor: "totalAmount",
        field: "totalAmount",
        displayName: t("totalAmount", { unit: t("ryal") }),
        render: (row: FinancingTermsRequest) => {
          return <>{row.financingType.calculationWay ? row.value * row.timeInMonth : row.value}</>;
        },
      },
      {
        accessor: "actions",
        field: "actions",
        showOnMobile: true,
        isHidden: !isResearcherMode,
        displayName: t("Common:actions"),
        render: (row: FinancingTermsRequest, index) => {
          return (
            <div className="d-flex gap-2">
              <IconButton
                icon="icon-edit"
                innerIconColor="secondary"
                fitToIconWidth
                onClick={() => {
                  setAddFundingTermModalOpenedProps({
                    isOpen: true,
                    selectedFundingTerm: row,
                    selectedIdx: index,
                  });
                }}
              />
              <DeleteTermModal
                index={index}
                financingTermsRequest={financingTermsRequest}
                setValue={closeOrderFormProps?.setValue}
              />
            </div>
          );
        },
      },
    ],
    [financingTermsRequest],
  );

  const tableFooter = useMemo(() => {
    return [
      [
        {
          content: "",
          colSpan: 3,
        },
        {
          content: (
            <div>
              <span className="p-2 d-flex justify-content-end fw-bold">
                {`${t("exchangeRate")} : ${financingTermsRequest?.reduce((acc, curr) => acc + curr.value, 0) ?? 0}`}
              </span>
            </div>
          ),
          colSpan: 1,
        },
        {
          content: (
            <div>
              <span className="p-2 d-flex  fw-bold">
                {`${t("totalAmount")} : ${
                  financingTermsRequest?.reduce(
                    (acc, curr) =>
                      acc + (curr.financingType.calculationWay ? curr.value * curr.timeInMonth : curr.value),
                    0,
                  ) ?? 0
                }
                   ${t("ryal")}`}
              </span>
            </div>
          ),
          colSpan: activePage === OrderActivePages.Default ? 1 : 2,
        },
      ],
    ];
  }, [financingTermsRequest]);

  const onCloseAddEditTermModal = (newTerm?: FinancingTermsRequest) => {
    if (newTerm) {
      if (addFundingTermModalProps.selectedIdx) {
        const newTerms = [...(financingTermsRequest ?? [])];
        newTerms[addFundingTermModalProps.selectedIdx] = newTerm;
        closeOrderFormProps?.setValue("financingTermsRequest", newTerms);
      } else {
        closeOrderFormProps?.setValue("financingTermsRequest", [...(financingTermsRequest ?? []), newTerm]);
      }
    }

    setAddFundingTermModalOpenedProps({
      isOpen: false,
      selectedFundingTerm: null,
      selectedIdx: 0,
    });
  };

  return (
    <Accordion>
      <AccordionItem
        initiallyOpen
        title={t("fundingTerms")}
        elementId={4}
        extraHeaders={
          isResearcherMode && (
            <Button
              variant="primary"
              label={t("addFundingTerm")}
              className="p-3"
              onClick={() => {
                setAddFundingTermModalOpenedProps({
                  isOpen: true,
                  selectedFundingTerm: null,
                });
              }}
            />
          )
        }
        mainHeaderClassName="align-items-center ps-4"
      >
        <Grid gridSchema={fundingTermsTableHeaders} data={financingTermsRequest ?? []} gridFooter={tableFooter} />
        <div className="divider"></div>
      </AccordionItem>

      <AddEditFundingTermModal
        isOpen={addFundingTermModalProps.isOpen}
        onClose={onCloseAddEditTermModal}
        selectedFundingTerm={addFundingTermModalProps.selectedFundingTerm}
        orderProgram={orderProgram}
        order={order}
        currentTotalFunding={
          financingTermsRequest?.reduce(
            (acc, curr) => acc + (curr.financingType.calculationWay ? curr.value * curr.timeInMonth : curr.value),
            0,
          ) ?? 0
        }
        selectedTerms={financingTermsRequest}
      />
    </Accordion>
  );
};

export default FundingTerms;
