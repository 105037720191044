import { useCookieContext } from "contexts";
import { useEffect, useRef, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import { BackendServices } from "services";

export interface DropdownItemType extends Record<string, any> {
  value: string;
  label: string;
}

type PaginatedLookupDropdownProps<T extends keyof typeof BackendServices> = {
  service: T;
  endpoint: keyof (typeof BackendServices)[T];
  onError?: (error: any) => void;
  textValueKey: string;
  idValueKey: string;
  isPaginated?: boolean;
  disabled?: boolean;
  value?: string | number | string[];
  noSelectionPlaceholder?: string;
  wrapperClassName?: string;
  label?: string;
  queryKey: string;
  error?: string;
  multiselect?: boolean;
  labelClassName?: string;
  isCreatable?: boolean;
  defaultOptions?: DropdownItemType[] | null;
  filterOption?: (option: DropdownItemType, inputValue: string) => boolean;
  validateCreatedOption?: (inputValue: string) => boolean;
  onDropdownReady?: (items: DropdownItemType[]) => void;
} & (
  | ({ multiselect?: false } & {
      onChange?: (value: string, item: DropdownItemType, options: any[]) => void;
      value?: string | number;
    })
  | ({ multiselect: true } & { onChange?: (value: string[], options: any[]) => void; value?: string[] })
);

// TODO: Arrow icon coloring (primary)
const access = (path: string, object: any) => path.split(".").reduce((o, i) => o[i], object);

function PaginatedLookupDropdown<T extends keyof typeof BackendServices>(props: PaginatedLookupDropdownProps<T>) {
  const {
    endpoint,
    textValueKey,
    idValueKey,
    onError,
    service,
    isPaginated,
    disabled,
    queryKey,
    label,
    noSelectionPlaceholder,
    wrapperClassName,
    onChange,
    value,
    error,
    multiselect,
    defaultOptions,
    labelClassName,
    filterOption,
    onDropdownReady,
  } = props;

  const debounceSearchRef = useRef<NodeJS.Timeout>();
  const isInitialLoad = useRef(true);
  const dropdownReady = useRef(false);
  const selectedValues = useRef<{ [key: string]: DropdownItemType }>({});
  const [data, setData] = useState<any>();
  const [options, setOptions] = useState<DropdownItemType[]>([]);
  const { isAr } = useCookieContext();

  const onLocalChange = (e: SingleValue<DropdownItemType>) => {
    !multiselect && onChange?.(`${e?.value}`, { value: `${e?.value}`, label: e?.label ?? "" }, data);
  };

  const onMultiLocalChange = (e: MultiValue<DropdownItemType>) => {
    const values = e.map((item) => item.value);
    multiselect && onChange?.(values, data);
  };

  const getData = async (inputValue: string) => {
    const queryParams = isPaginated
      ? { [queryKey]: inputValue, pageIndex: 1, pageSize: 10 }
      : { [queryKey]: inputValue };

    const res = await (BackendServices[service][endpoint] as any)(queryParams);
    if (!res.hasError) {
      const responseData = isPaginated ? res.data.result : res.data;

      const items = responseData.map((item: any) => ({
        value: access(idValueKey, item),
        label: access(textValueKey, item),
      }));

      Object.keys(selectedValues.current).forEach((key) => {
        if (!items.find((item: any) => `${access(idValueKey, item)}` === key)) {
          responseData.push(selectedValues.current[key]);
          items.push(selectedValues.current[key]);
        }
      });

      setData(responseData);
      setOptions(items);

      if (!dropdownReady.current) {
        onDropdownReady?.(items);
        dropdownReady.current = true;
      }
      return items;
    } else {
      onError?.(res.description);
      return [];
    }
  };

  useEffect(() => {
    getData("");
  }, [isAr]);

  const Select = props.isCreatable ? CreatableSelect : ReactSelect;

  useEffect(() => {
    if (isInitialLoad.current && defaultOptions) {
      isInitialLoad.current = false;

      if (props.isCreatable) {
        const newOptions = options.concat(
          defaultOptions.filter((item) => !options.find((option) => option.label === item.label)),
        );
        setOptions(newOptions);
      } else {
        setOptions(
          options.concat(defaultOptions.filter((item) => !options.find((option) => option.value === item.value))),
        );
      }
    }
  }, [options, defaultOptions]);

  const onCreateOption = (value: string) => {
    if (props.validateCreatedOption) {
      const isValid = props.validateCreatedOption(value);
      if (!isValid) {
        // refetch data to remove the created option
        getData("");
        return;
      }
    }

    selectedValues.current[value] = { value, label: value };
    setOptions([...options, { value, label: value }]);

    if (multiselect) {
      const oldSelectedValues = [];
      for (const key in selectedValues.current) {
        if (props.value?.includes(key)) {
          oldSelectedValues.push(selectedValues.current[key]);
        }
      }
      onMultiLocalChange([...oldSelectedValues, { value, label: value }]);
    } else {
      onLocalChange({ value, label: value });
    }
  };

  return (
    <div className={`${wrapperClassName}`} style={{ minWidth: 150 }}>
      {label && <label className={`mb-1 ${labelClassName}`}>{label}</label>}
      <Select
        isMulti={!!multiselect}
        placeholder={noSelectionPlaceholder ?? label}
        isDisabled={disabled}
        value={
          multiselect
            ? !props.isCreatable
              ? value?.map((ele) => options.find((item) => item.value === ele))
              : options.filter((item) => value?.includes(item.label))
            : !props.isCreatable
            ? options.find((item) => item.value === value)
            : options.find((item) => item.label === value)
        }
        styles={{
          // make it exactly like bootstrap 5
          control: (styles) => ({
            ...styles,
            borderColor: error ? "#dc3545" : "#e5e5e5",
            padding: "0.3rem 0.75rem",
            backgroundColor: error ? "#f8d7da" : "",
          }),
          valueContainer: (styles) => ({ ...styles, padding: "0" }),
          input: (styles) => ({ ...styles, margin: "0" }),
          indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
          dropdownIndicator: (styles) => ({ ...styles, padding: "0.3rem 0.0rem" }),
          singleValue: (styles) => ({ ...styles, padding: "0.3rem 0.1rem", fontSize: "1rem" }),
          placeholder: (styles) => ({ ...styles, padding: "0.3rem 0.75rem" }),
        }}
        // loadOptions={props.isCreatable ? getData : undefined}
        options={options}
        onCreateOption={onCreateOption}
        filterOption={filterOption}
        onChange={(e) => {
          if (multiselect) {
            onMultiLocalChange(e as MultiValue<DropdownItemType>);
          } else {
            onLocalChange(e as SingleValue<DropdownItemType>);
          }
        }}
        onInputChange={(inputValue) => {
          if (debounceSearchRef.current) {
            clearTimeout(debounceSearchRef.current);
          }

          debounceSearchRef.current = setTimeout(() => {
            getData(inputValue);
          }, 500);
        }}
      />
      {error && (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {error}
        </div>
      )}
    </div>
  );
}

export default PaginatedLookupDropdown;
