import Modal from "components/blocks/Modal";
import useMutation from "hooks/useMutation";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OrdersService } from "services/orders";
import { WorkFlowTaskRequest } from "services/orders/models/OrdersTypes";

const ApologizeRequestModal = ({
  isOpen,
  onClose,
  onConfirm,
  orderId,
  serialGuid,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  orderId: number;
  serialGuid: string;
}) => {
  const { t } = useTranslation("Orders");
  const [notes, setNotes] = useState("");
  const { loading: isSubmitting, mutateAsync } = useMutation({
    queryFn: async ({ id, request }: { id: number; request: WorkFlowTaskRequest }) => {
      return await OrdersService.programOrderResearcherApologiesContract(id, request);
    },
    notificationSuccessMessage: t("Common:success"),
  });

  const onCancel = () => {
    onClose?.();
  };

  const onSubmit = async (notes: string) => {
    await mutateAsync({
      id: orderId,
      request: {
        notes,
        actionName: "Apology",
        serialGuid: serialGuid,
      },
    });
    onConfirm();
  };

  return (
    <Modal
      containerClassName="bg-white pb-2"
      isOpen={isOpen}
      onClose={onClose}
      title={t("apologizeRequest")}
      withHeaderBorder
      size="lg"
      titleClassName="fs-6 text-dark"
      bodyMargin="py-3"
    >
      <label className="form-label mt-2">{t("Common:notes")}</label>
      <textarea
        className={`form-control`}
        rows={5}
        placeholder={t("Common:notes")}
        onChange={(e) => setNotes(e.target.value)}
        style={{ resize: "none" }}
      />

      <div className="divider mt-3"></div>

      <div className="d-flex justify-content-center gap-2 w-100">
        <button type="button" className="btn text-dark rounded-3 p-10-32P" onClick={onCancel}>
          {t("Common:cancel")}
        </button>
        <button
          type="button"
          className="btn btn-primary rounded-3 p-10-32P"
          disabled={!notes.length || isSubmitting}
          onClick={async () => {
            if (!notes.length) return;

            await onSubmit(notes);
          }}
        >
          {!isSubmitting ? t("send") : <div className="custom-loader"></div>}
        </button>
      </div>
    </Modal>
  );
};

export default ApologizeRequestModal;
