import IconButton from "components/blocks/IconButton";
import { t } from "i18next";
import React from "react";
import DeleteProgramButton from "./DeleteProgramButton";
import { useNotification } from "hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";

const AdminActions = ({
  programId,
  programData,
  isAr,
}: {
  programId?: string;
  programData: ProgramByIDResponse | null;
  isAr: boolean;
}) => {
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  return (
    <>
      <IconButton
        icon="icon-share"
        size="md"
        className="btn action-btn btn-sm rounded p-2"
        variant="light"
        innerIconColor="secondary"
        onClick={() => {
          navigator.clipboard.writeText(
            `${window.location.protocol}//${window.location.host}/programDetails/${programId}`,
          );
          showNotification({ type: "success", description: t("Common:linkCopiedToClipboard") });
        }}
      />
      <IconButton
        icon="icon-edit"
        size="md"
        className="btn action-btn btn-sm rounded p-2"
        variant="light"
        innerIconColor="secondary"
        onClick={() => navigate(`/home/programs/form?id=${programId}`)}
      />
      <DeleteProgramButton programId={programId} programData={programData} isAr={isAr} />
    </>
  );
};

export default AdminActions;
