import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FilePreview from "components/blocks/FileInput/FilePreview";
import PaginatedLookupDropdown from "components/blocks/PaginatedLookupDropdown";
import useMutation from "hooks/useMutation";
import { useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { CentreManagerAcceptRequest, CloseOrderDetailsRequest, CloseOrderResult } from "services/closeOrder/models";
import { ProgramsService } from "services/programs";
import { PDFTemplateByIDResponse } from "services/programs/models/ProgramTypes";
import { BaseResponseType } from "services/shared-models/sharedTypes";
import { ApproveByCenterManagerSubmitHandlerRef } from "../../../../PageActions/useCloseRequestActions";
import { CloseOrderService } from "services/closeOrder";
import { useNavigate } from "react-router-dom";
import EditPdfTemplateModal from "./EditPdfTemplateModal";
import { useNotification } from "hooks/useNotification";
import useFileDownload from "hooks/useFileDownload";

const ProgressCertificate = ({
  approveByCenterManagerSubmitHandlerRef,
  closeOrder,
  isForEdit,
}: {
  approveByCenterManagerSubmitHandlerRef: React.MutableRefObject<ApproveByCenterManagerSubmitHandlerRef | null>;
  closeOrder?: CloseOrderResult;
  isForEdit?: boolean;
}) => {
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { t, i18n } = useTranslation("Orders");
  const downloadFile = useFileDownload();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTemplateDetails, setSelectedTemplateDetails] = useState<
    (CloseOrderDetailsRequest & {
      id: number;
    })[]
  >([]);

  const arabicTemplate = selectedTemplateDetails.find((item) => item.locale.toLowerCase() === "ar");
  const englishTemplate = selectedTemplateDetails.find((item) => item.locale.toLowerCase() === "en");

  const { mutateAsync, loading } = useMutation({
    queryFn: async (id: number) => {
      return await ProgramsService.getPdfTemplateById(id);
    },
  });

  const { mutateAsync: acceptCloseRequestByManagerAsync } = useMutation({
    queryFn: async ({ id, req }: { id: number; req: CentreManagerAcceptRequest }) => {
      return await CloseOrderService.centreManagerAccept(id, req);
    },
  });

  const { mutateAsync: downloadProgressCertificateAsync, loading: downloadProgressCertificate } = useMutation({
    queryFn: async (id: number) => {
      const res = await CloseOrderService.downloadProgressCertificate(id);
      downloadFile(res, "ProgressCertificate.docx");
    },
  });

  useEffect(() => {
    console.log(closeOrder);

    if (closeOrder?.closeOrderDetails) {
      const arTemplate = closeOrder.closeOrderDetails.find((item) => item.locale.toLowerCase() === "ar");
      const enTemplate = closeOrder.closeOrderDetails.find((item) => item.locale.toLowerCase() === "en");
      setSelectedTemplateDetails([
        {
          displayName: arTemplate?.displayName ?? "",
          locale: "ar",
          templateContent: arTemplate?.templateContent ?? "",
          id: 1,
        },
        {
          displayName: enTemplate?.displayName ?? "",
          locale: "en",
          templateContent: enTemplate?.templateContent ?? "",
          id: 2,
        },
      ]);
    }
  }, [closeOrder]);

  useImperativeHandle(approveByCenterManagerSubmitHandlerRef, () => ({
    acceptProgramOrderUpdateByCenterManager: async (requestTask) => {
      if (selectedTemplateDetails.length === 0) {
        showNotification({
          description: t("Orders:pleaseSelectProgressCertificate"),
          type: "error",
        });
        return;
      }

      const res = await acceptCloseRequestByManagerAsync({
        id: closeOrder!.id,
        req: {
          centreManagerAcceptWorkflowRequest: requestTask,
          closeOrderDetailsRequest: selectedTemplateDetails ?? [],
        },
      });

      if (res && !res.hasError) {
        navigate("/home/tasks");
      }
    },
  }));

  const onTemplateChange = async (id: number) => {
    const selectedItem = (await mutateAsync(+id)) as BaseResponseType<PDFTemplateByIDResponse>;
    const arabicTemplate = selectedItem.data.pdfTemplateDetails.find((item) => item.locale.toLowerCase() === "ar");
    const englishTemplate = selectedItem.data.pdfTemplateDetails.find((item) => item.locale.toLowerCase() === "en");

    setSelectedTemplateDetails([
      {
        id: selectedItem.data.id,
        locale: "ar",
        displayName: arabicTemplate?.displayName ?? "",
        templateContent: arabicTemplate?.templateContent ?? "",
      },
      {
        id: selectedItem.data.id,
        locale: "en",
        displayName: englishTemplate?.displayName ?? "",
        templateContent: englishTemplate?.templateContent ?? "",
      },
    ]);
  };

  return (
    <Accordion>
      <AccordionItem initiallyOpen elementId="ProgressCertificate" title={t("Orders:progressCertificate")}>
        <div className="d-flex flex-column gap-4 position-relative">
          {isForEdit && (
            <PaginatedLookupDropdown
              service="orderService"
              endpoint="getAllPdfTemplates"
              queryKey="search"
              noSelectionPlaceholder={t("Orders:templates")}
              idValueKey="id"
              textValueKey="pdfTemplateDetail.displayName"
              isPaginated
              onChange={(id) => onTemplateChange(+id)}
            />
          )}
          {loading ? (
            <div className="custom-loader"></div>
          ) : (
            !!selectedTemplateDetails.length && (
              <FilePreview
                title={
                  i18n.language.toLowerCase() === "ar" ? arabicTemplate?.displayName : englishTemplate?.displayName
                }
                callbackValue={i18n.language.toLowerCase() === "ar" ? arabicTemplate?.id : englishTemplate?.id}
                onShow={async () => {
                  if (isForEdit) {
                    setIsEditModalOpen(true);
                  } else {
                    await downloadProgressCertificateAsync(closeOrder!.id);
                  }
                }}
                showFileSize={false}
                hideDeleteButton
                showButtonLabel={isForEdit ? t("editAndView") : undefined}
              />
            )
          )}
        </div>

        <div className="divider"></div>
      </AccordionItem>

      <EditPdfTemplateModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSaveClicked={(res) => {
          setSelectedTemplateDetails([
            {
              ...selectedTemplateDetails.find((item) => item.locale.toLowerCase() === "ar")!,
              templateContent: res.ar,
            },
            {
              ...selectedTemplateDetails.find((item) => item.locale.toLowerCase() === "en")!,
              templateContent: res.en,
            },
          ]);
          setIsEditModalOpen(false);
        }}
        details={selectedTemplateDetails}
      />
    </Accordion>
  );
};

export default ProgressCertificate;
