import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useQuery } from "hooks/useQuery";
import { useEffect, useState } from "react";
import { OrdersService } from "services/orders";
import { ProgramsService } from "services/programs";
import { TasksService } from "services/tasks";
import { OrderUserTypes } from ".";
import { useNavigate } from "react-router-dom";

const useOrderView = ({
  serialGID,
  userType,
  orderId,
}: {
  serialGID?: string;
  userType?: keyof typeof OrderUserTypes;
  orderId: number;
}) => {
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();

  const [programOrderTaskState, setProgramOrderTaskState] = useState<{
    actions: string[];
    canReturn: boolean;
    orderId: number;
  }>();

  //Open Task Api
  const { data: openedTask, loading: isTaskLoading } = useQuery({
    queryFn: async (_: string) => {
      const res = await TasksService.openFundingOrderTask(serialGID ?? "");
      return {
        data: res,
        hasError: false,
        code: 200,
        description: "",
      };
    },
    options: {
      enabled: !!serialGID && userType !== OrderUserTypes.Admin,
    },
    triggers: [serialGID],
  });

  //Order Api
  const { data: order, loading } = useQuery({
    queryFn: async () => {
      const id = orderId ? orderId : openedTask?.orderId ? openedTask.orderId : 0;

      return await OrdersService.getOneById(id);
    },
    options: {
      enabled: !!openedTask?.orderId || !!orderId,
    },
    triggers: [openedTask?.orderId, orderId],
    onError: () => {
      navigate("/home/tasks");
    },
    initialLoading: !!orderId,
  });

  //Program Api
  const { data: orderProgram, loading: programLoading } = useQuery({
    queryFn: async () => {
      const id = order?.programId ? order.programId : 0;

      return await ProgramsService.getProgramById(id);
    },
    triggers: [order?.programId],
    options: { enabled: !!order?.programId },
  });

  useEffect(() => {
    if (openedTask?.orderId) {
      setProgramOrderTaskState({
        actions: openedTask.actionName.filter((a: string) => a !== "Return"),
        orderId: openedTask.orderId,
        canReturn: openedTask.actionName.includes("Return"),
      });
    }
  }, [openedTask]);

  useEffect(() => {
    if ((openedTask && !openedTask.orderId) || (orderId && !order && !loading)) {
      navigate(!!serialGID ? "/home/tasks" : "/home/orders");
    }
  }, [openedTask]);

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: userType === OrderUserTypes.Admin ? "allOrders" : "allTasks",
        path: userType === OrderUserTypes.Admin ? "/home/orders" : "/home/tasks",
        menuItemId: userType === OrderUserTypes.Admin ? "allOrders" : "allTasks",
      },
      {
        localizationKey: userType === OrderUserTypes.Admin ? "order" : "task",
        path: `/home/orders/view/${order?.id ?? ""}`,
        localizationParameters:
          userType === OrderUserTypes.Admin
            ? { orderNumber: order?.serialNumber }
            : {
                taskNumber: order?.serialNumber,
              },
      },
    ]);
  }, [order]);

  return {
    loading: loading || programLoading || isTaskLoading,
    programOrderTaskState,
    order: order,
    orderProgram,
    openedTask,
  };
};

export default useOrderView;
