import { WorkFlowTaskRequest } from "services/orders/models/OrdersTypes";
import { FileResult } from "services/shared-models/sharedTypes";

export type CreateProgramOrderUpdateRequest = {
  fileId: string;
  completionRate: number;
  details: string;
  programOrderId: number;
};

export type EditProgramOrderUpdateRequest = {
  fileId: string;
  completionRate: number;
  details: string;
  programOrderId: number;
  workFlowTaskRequest: WorkFlowTaskRequest;
};

export type EvaluateProgramOrderUpdateRequest = {
  evaluationType: number;
  centerManagerNotes: string;
  workFlowTaskRequest: WorkFlowTaskRequest;
};

export type ProgramOrderUpdateResult = {
  id: number;
  programOrderId: number;
  details?: string;
  completionRate: number;
  fileId?: string;
  file: FileResult;
  files: FileResult[];
  returnNotes?: string;
  updateOrderEvaluationType?: keyof typeof UpdateOrderEvaluationType;
  centerManagerNotes?: string;
  createdOn?: string;
};

export type UpdateProgramExtendedRequest = {
  extendedTime: number;
  fundingAmount: number;
  notes?: string;
};

export type SendProgramExtendedRequest = {
  extendedTime: number;
  workFlowTaskRequest: WorkFlowTaskRequest;
  notes?: string;
};

export const UpdateOrderEvaluationType = {
  Satisfied: {
    value: 1,
    label: "Acceptable",
  },
  NotSatisfied: {
    value: 2,
    label: "NotAcceptable",
  },
};
