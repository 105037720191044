import ActionModal from "components/blocks/Modal/ActionModal";
import useMutation from "hooks/useMutation";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExaminersService } from "services/examiners";
import { TaskActionsNames } from "services/tasks/models/tasksTypes";

const ApologizeButton = ({
  t,
  examinerProgramOrderId,
  serialGuid,
}: {
  t: any;
  examinerProgramOrderId: number;
  serialGuid: string;
}) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, loading, mutateAsync, reset } = useMutation({
    queryFn: async ({
      examinerProgramOrderId,
      actionName,
      serialGuid,
    }: {
      examinerProgramOrderId: number;
      actionName: TaskActionsNames;
      serialGuid: string;
    }) => {
      return await ExaminersService.rejectExaminerOrderEvaluationForm(examinerProgramOrderId, {
        actionName,
        serialGuid,
      });
    },
  });

  const apologize = async () => {
    await mutateAsync({
      actionName: "Apology",
      examinerProgramOrderId,
      serialGuid: serialGuid,
    });
  };

  return (
    <>
      {loading ? (
        <div className="custom-loader"></div>
      ) : (
        <button className="btn btn-outline-danger px-4" onClick={() => setIsModalOpen(true)}>
          {t("apologizeOfTheTask")}
        </button>
      )}
      <ActionModal
        isOpen={isModalOpen}
        iconWrapperColor="danger"
        actionIcon="icon-warning-circle"
        headerMsg={t("confirmApologizeHeaderMsg")}
        subMsg={t("confirmApologizeSubMsg")}
        loading={loading}
        onActionConfirm={async () => {
          await apologize();
          navigate(-1);
          reset();
        }}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default ApologizeButton;
