import useMutation from "hooks/useMutation";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OrdersService } from "services/orders";
import { WorkFlowTaskRequest } from "services/orders/models/OrdersTypes";

const useDeanOfScientificResearch = ({
  programOrderId,
  serialGuid,
}: {
  programOrderId: number;
  serialGuid: string;
}) => {
  const { t } = useTranslation("Orders");
  const navigate = useNavigate();
  const [isReturnContractModalOpen, setIsReturnContractModalOpen] = useState(false);

  const { mutateAsync: DeanOfScientificResearch, loading: DeanOfScientificResearchLoading } = useMutation({
    queryFn: async ({ programOrderId, data }: { programOrderId: number; data: WorkFlowTaskRequest }) => {
      return await OrdersService.programOrderDSRSignContract(programOrderId, data);
    },
    notificationSuccessMessage: t("Common:success"),
  });

  const { mutateAsync: returnContract, loading: returnContractLoading } = useMutation({
    queryFn: async ({ programOrderId, data }: { programOrderId: number; data: WorkFlowTaskRequest }) => {
      return await OrdersService.programOrderDSRReturnContract(programOrderId, data);
    },
  });

  const onAcceptSignContract = async () => {
    const res = await DeanOfScientificResearch({
      programOrderId,
      data: {
        actionName: "Approve",
        serialGuid,
      },
    });

    if (res && !res.hasError) {
      navigate(`/home/tasks`);
    }
  };

  const onReturnContract = async (notes: string, isReturnToResearcher: boolean) => {
    const res = await returnContract({
      programOrderId,
      data: {
        actionName: "Return",
        serialGuid,
        notes,
      },
    });

    if (res && !res.hasError) {
      setIsReturnContractModalOpen(false);
      navigate(`/home/tasks`);
    }
  };

  return {
    onAcceptSignContract,
    deanOfScientificResearchLoading: DeanOfScientificResearchLoading,
    onReturnContract,
    returnContractLoading,
    isReturnContractModalOpen,
    setIsReturnContractModalOpen,
  };
};

export default useDeanOfScientificResearch;
