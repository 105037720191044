import IconButton from "components/blocks/IconButton";
import PaginatedLookupDropdown, { DropdownItemType } from "components/blocks/PaginatedLookupDropdown";
import { useNotification } from "hooks/useNotification";
import { useTranslation } from "react-i18next";
import { UserResult } from "services/orders/models/OrdersTypes";
import { SelectedExaminerType } from ".";
import { Dispatch, SetStateAction } from "react";

const ExaminersDropDown = ({
  selectedExaminer,
  setSelectedExaminer,
  externalExaminersEmail,
  setExternalExaminersEmail,
  selectedInternalExaminers,
  setSelectedInternalExaminers,
}: {
  selectedExaminer: UserResult | undefined;
  setSelectedExaminer: Dispatch<SetStateAction<UserResult | undefined>>;
  externalExaminersEmail: {
    email: string;
    isSentInvitation: boolean;
  }[];
  setExternalExaminersEmail: Dispatch<SetStateAction<{ email: string; isSentInvitation: boolean }[]>>;
  selectedInternalExaminers: SelectedExaminerType[];
  setSelectedInternalExaminers: Dispatch<SetStateAction<SelectedExaminerType[]>>;
}) => {
  const { t, i18n } = useTranslation("Orders");
  const { showNotification } = useNotification();

  const isValidEmail = (email: string) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (!isValid) {
      showNotification({
        description: t("invalidEmail"),
        type: "error",
      });
    }

    return isValid;
  };

  const onSelectExaminer = (value: string, item: DropdownItemType, options: any[]) => {
    const user = options?.find((user) => user.id === item.value);

    if (user) {
      if (!selectedInternalExaminers.some((u) => u.id === user?.id)) {
        setSelectedExaminer(user);
      }
    } else if (isValidEmail(value) && !externalExaminersEmail.some((u) => u.email === value)) {
      setSelectedExaminer({ email: value, isSentInvitation: false } as any);
    }
  };

  const onConfirmSelectExaminer = () => {
    if (!!selectedExaminer?.id) {
      setSelectedInternalExaminers([...selectedInternalExaminers, selectedExaminer]);
    } else if (selectedExaminer?.email) {
      setExternalExaminersEmail([
        ...externalExaminersEmail,
        {
          email: selectedExaminer.email,
          isSentInvitation: false,
        },
      ]);
    }

    setSelectedExaminer(undefined);
  };

  return (
    <div className="d-flex align-items-end gap-2">
      <PaginatedLookupDropdown
        wrapperClassName="flex-grow-1"
        queryKey="search"
        service="accountService"
        endpoint="getUsersDropdown"
        idValueKey="id"
        textValueKey={i18n.language === "ar" ? "arabicName" : "englishName"}
        label={t("examinerName")}
        isPaginated
        isCreatable
        validateCreatedOption={(inputValue) => isValidEmail(inputValue)}
        multiselect={false}
        value={selectedExaminer?.id ? selectedExaminer.id.toString() : selectedExaminer?.email}
        onChange={onSelectExaminer}
      />
      <IconButton
        icon="icon-plus"
        innerIconColor="white"
        size="xl"
        variant="primary"
        className="p-3"
        disabled={!selectedExaminer}
        onClick={() => {
          onConfirmSelectExaminer();
        }}
      />
    </div>
  );
};

export default ExaminersDropDown;
