import { useTranslation } from "react-i18next";

type FilePreviewProps = {
  file?: File;
  callbackValue?: string | number;
  title?: string;
  onDelete?: (value: File | string | number) => void;
  onShow: (value: File | string | number) => void;
  showFileSize?: boolean;
  showButtonLabel?: string;
  loading?: boolean;
  disabled?: boolean;
  wrapperClassName?: string;
  fileSize?: string;
  hideDeleteButton?: boolean;
};

const FilePreview: React.FC<FilePreviewProps> = (props) => {
  const {
    showFileSize = true,
    onDelete,
    onShow,
    file,
    callbackValue,
    title,
    showButtonLabel,
    loading,
    disabled,
    wrapperClassName,
    fileSize,
    hideDeleteButton,
  } = props;
  const { t } = useTranslation("Common");

  if (!file && !callbackValue) {
    return null;
  }

  return (
    <div
      className={`d-flex align-items-center justify-content-between border p-2 rounded-3 bg-body gap-4 ${wrapperClassName}`}
    >
      <div className="d-flex align-items-center gap-2">
        <span className="icon-file fs-5 text-primary" />
        <span>{title ?? file?.name}</span>
        <span>•</span>

        {loading ? (
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <span
            className={`text-${disabled ? "secondary" : "primary"} fw-bold`}
            role="button"
            onClick={() => !disabled && onShow(file ?? callbackValue!)}
          >
            {showButtonLabel ?? t("show")}
          </span>
        )}
      </div>
      <div className="d-flex align-items-center gap-2">
        {showFileSize && fileSize && <span style={{ fontSize: 14 }}>{fileSize}</span>}
        {showFileSize && !fileSize && file instanceof File && (
          <span style={{ fontSize: 14 }}>{(file.size / 1024 / 1024).toFixed(2)}MB</span>
        )}
        {hideDeleteButton ? null : (
          <span
            className="text-secondary fs-4 d-flex align-content-center"
            role="button"
            onClick={() => !disabled && onDelete && onDelete(file ?? callbackValue!)}
          >
            <i className="icon-x" />
          </span>
        )}
      </div>
    </div>
  );
};

export default FilePreview;
