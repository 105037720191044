import { zodResolver } from "@hookform/resolvers/zod";
import { CreateProgramSpeechesRequest } from "services/orders/models/OrdersTypes";
import { z } from "zod";

export const getSpeechesSchema = (t: any) => {
  const schema = z.object({
    programOrderId: z.number().min(1, t("Common:invalidValue")),
    pdfTemplateId: z.number().min(1, t("Common:invalidValue")),
    description: z.string().min(1, t("Common:invalidValue")),
    selectedLocale: z.string().min(1, t("Common:invalidValue")),
  }) as z.ZodType<CreateProgramSpeechesRequest>;

  return zodResolver(schema);
};