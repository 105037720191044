import { useEffect, useRef } from "react";

const SimpleDonutChart = ({ size, percentage }: { size: number; percentage: number }) => {
  const canvasRef = useRef<any>(null);

  if (percentage > 100 || percentage < 0) {
    throw new Error("Percentage should be between 0 and 100");
  }

  const colors = ["#3992A4", "#E5E5E5"];
  const values = [percentage, 100 - percentage];

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      dmbChart(ctx, size / 2, size / 2, size / 3, size / 15, values);
    }
  }, [values]);

  const dmbChart = (ctx: any, cx: number, cy: number, radius: number, arcwidth: number, values: number[]) => {
    let tot = 0;
    let accum = 0;
    const PI = Math.PI;
    const PI2 = PI * 2;
    const offset = -PI / 2;
    ctx.lineWidth = arcwidth;
    for (let i = 0; i < values.length; i++) {
      tot += values[i];
    }
    for (let i = 0; i < values.length; i++) {
      ctx.beginPath();
      ctx.arc(cx, cy, radius, offset + PI2 * (accum / tot), offset + PI2 * ((accum + values[i]) / tot));
      ctx.strokeStyle = colors[i];
      ctx.stroke();
      accum += values[i];
    }
    var innerRadius = radius - arcwidth - 3;
    ctx.beginPath();
    ctx.arc(cx, cy, innerRadius, 0, PI2);
    ctx.textAlign = "center";
    ctx.textBaseline = "center";
    ctx.fillStyle = colors[0];
    ctx.font = innerRadius / 2 + "px verdana";
    let value = values[0].toFixed(2)
    ctx.fillText( value + "%", cx, cy + innerRadius * 0.25);
  };

  return <canvas ref={canvasRef} width={size} height={size}></canvas>;
};

export default SimpleDonutChart;
