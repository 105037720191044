import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { prepareFilters } from "components/GridView/components/GridFilter";
import useGridStore from "components/GridView/grid.store";
import FormInput from "components/blocks/FormInput";
import { useCookieContext } from "contexts";
import { useNotification } from "hooks/useNotification";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { OrdersService } from "services/orders";
import { OrderLocalResult } from "services/orders/models/OrdersTypes";
import { useShallow } from "zustand/react/shallow";

const FundingUnitOrdersTab = ({ programId }: { programId?: number }) => {
  const { t } = useTranslation(["Orders", "Programs"]);
  const { showNotification } = useNotification();
  const { isAr } = useCookieContext();

  const ready = useRef(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<OrderLocalResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchOrders = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await OrdersService.getAllOrdersAsync({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: [
          ...prepareFilters(filters),
          ...(programId
            ? [
                {
                  field: "ProgramId",
                  operator: "equal",
                  value: programId,
                },
              ]
            : []),
        ],
      });

      setOrders(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchOrders();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchOrders({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "id",
        field: "id",
        displayName: t("orderNumber"),
        sortable: true,
        render: (row: OrderLocalResult) => {
          return <Link to={`/home/orders/view/${row.id}`}>{row.serialNumber}</Link>;
        },
      },
      {
        accessor: "appliedUserName",
        field: "appliedUserName",
        displayName: t("applicantName"),
        sortable: false,
        filterable: true,
      },
      {
        accessor: "college",
        field: "college",
        displayName: t("Programs:college"),
        sortable: false,
      },
      {
        accessor: "degree",
        field: "degree",
        displayName: t("Programs:degree"),
      },
      {
        field: "centreManagementDetail.displayName",
        displayName: t("researchesCenter"),
        render(row: OrderLocalResult) {
          return <>{row.centreManagementDetail.displayName}</>;
        },
      },
      {
        field: "centreManagementDetailRecommendation",
        displayName: t("Programs:centreManagementDetailRecommendation"),
      },
    ],
    [t],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("allOrders"),
    singularName: t("order"),
    addPageRoute: "/home/orders/add",
    totalCount: totalCount,
    hideAddButton: true,
    onExportClick: async () => {
      /* setLoading(true);
      const response = await OrdersService.exportToExcel({
        pageIndex: pagination.currentPage,
        pageSize: 10,
        search: searchValue,
      });
      await downloadFile(response, "orders.xlsx");
      setLoading(false); */
    },
  };

  return (
    <div className="bg-white rounded-4 h-100">
      <GridView
        loading={loading}
        gridProps={{
          data: orders ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />

      <FormInput wrapperClassName="mt-3" label={t("notes")} placeholder={t("notes")} />
    </div>
  );
};

export default FundingUnitOrdersTab;
