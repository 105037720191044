import Dropdown from "components/blocks/Dropdown";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import useMutation from "hooks/useMutation";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { ExaminersService } from "services/examiners";
import {
  AccreditationTypes,
  ExaminerListResult,
  UpdateExaminerAccreditationTypeRequest,
} from "services/examiners/Model/ExaminerTypes";

const AcceptRejectExaminerEvaluationDDL = ({
  row,
  setTriggerTableUpdate,
}: {
  row: ExaminerListResult;
  setTriggerTableUpdate: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation(["Orders"]);

  const { mutateAsync, loading } = useMutation({
    queryFn: async ({
      programOrderExaminerId,
      request,
    }: {
      programOrderExaminerId: number;
      request: UpdateExaminerAccreditationTypeRequest;
    }) => {
      return await ExaminersService.updateExaminerAccreditationType(programOrderExaminerId, request);
    },
  });

  const onApproveRejectExaminer = async (accreditationType: string) => {
    const parsedType = parseInt(accreditationType);
    await mutateAsync({
      programOrderExaminerId: row.id,
      request: {
        type: parsedType,
      },
    });

    setTriggerTableUpdate((prev) => !prev);
  };

  return (
    <div className="position-relative">
      <LoadingOverlay visible={loading} />
      <Dropdown
        data={[
          { id: `${AccreditationTypes.Accreditation.value}`, value: t("approveExaminerResult") },
          { id: `${AccreditationTypes.Reject.value}`, value: t("rejectExaminerResult") },
        ]}
        disabled={loading}
        value={
          !row.accreditationType
            ? ""
            : row.accreditationType === AccreditationTypes.Accreditation.label
            ? `${AccreditationTypes.Accreditation.value}`
            : `${AccreditationTypes.Reject.value}`
        }
        noSelectionPlaceholder={t("selectExaminerEvaluationResult")}
        onChange={onApproveRejectExaminer}
      />
    </div>
  );
};

export default AcceptRejectExaminerEvaluationDDL;
