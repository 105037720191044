import ApplicantInfo from "./components/ApplicantInfo";
import Updates from "./components/Updates";
import AchievementFile from "./components/AchievementFile";
import FundingTerms from "./components/FundingTerms";
import ResearchesCenter from "./components/ResearchesCenter";
import Procedures from "./components/Procedures";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import ExaminersSection from "./components/ExaminersSection";
import { OrderActivePages, OrderUserTypes } from "../..";
import { Dispatch, SetStateAction } from "react";
import BankingInformation from "./components/BankingInformation";
import { OpenFundingTaskResult } from "services/tasks/models/tasksTypes";
import { EvaluateProgramUpdateRef } from "./components/Updates/useProgramOrderUpdateActions";
import UpdateNotes from "./components/UpdateNotes";
import { CloseOrderFormProps } from "../../ResearcherCloseRequestPage";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import ProgressCertificate from "./components/ProgressCertificate";
import { ApproveByCenterManagerSubmitHandlerRef } from "../../PageActions/useCloseRequestActions";

const sections = {
  applicantInfo: "applicantInfo",
  updates: "updates",
  achievementFile: "achievementFile",
  fundingTerms: "fundingTerms",
  researchesCenter: "researchesCenter",
  procedures: "procedures",
  examinerSection: "examinerSection",
  userBankingInfo: "userBankingInfo",
  updateNotesSection: "updateNotesSection",
  progressCertificate: "ProgressCertificate",
};

const getActiveSectionsIds = (userType: keyof typeof OrderUserTypes, activePage: keyof typeof OrderActivePages) => {
  if (userType === OrderUserTypes.ResearchCenterManager) {
    return [sections.applicantInfo, sections.examinerSection, sections.procedures];
  }

  if (userType === OrderUserTypes.FundingUnit) {
    return [sections.applicantInfo, sections.researchesCenter, sections.procedures];
  }

  if (userType === OrderUserTypes.Researcher) {
    if (activePage === OrderActivePages.ResearchCloseRequest) {
      return [sections.achievementFile, sections.fundingTerms, sections.userBankingInfo];
    }

    return [sections.applicantInfo, sections.updates];
  }

  if (userType === OrderUserTypes.ResearcherSendCloseResponseAfterReturnRequest) {
    return [sections.updateNotesSection, sections.achievementFile, sections.fundingTerms, sections.userBankingInfo];
  }

  if (userType === OrderUserTypes.DeanOfScientificResearch) {
    return [sections.applicantInfo, sections.procedures];
  }

  if (userType === OrderUserTypes.ResearchCenterManagerForOrderUpdate) {
    return [
      sections.updateNotesSection,
      sections.applicantInfo,
      sections.updates,
      sections.achievementFile,
      sections.fundingTerms,
      sections.researchesCenter,
      sections.procedures,
    ];
  }

  if (userType === OrderUserTypes.ResearcherSendUpdateResponseAfterReturnRequest) {
    return [sections.updateNotesSection, sections.applicantInfo, sections.updates];
  }

  if (userType === OrderUserTypes.ResearcherSendApologizeResponseAfterReturnRequest) {
    return [sections.updateNotesSection, sections.applicantInfo, sections.updates];
  }

  if (userType === OrderUserTypes.ResearcherSendExtendResponseAfterReturnRequest) {
    return [sections.updateNotesSection, sections.applicantInfo, sections.updates];
  }

  if (userType === OrderUserTypes.DeanOfScientificResearchForResearcherExtendRequest) {
    return [sections.updateNotesSection, sections.applicantInfo, sections.updates, sections.procedures];
  }

  if (userType === OrderUserTypes.ResearchCenterManagerQueryRequest) {
    return [sections.updateNotesSection, sections.applicantInfo, sections.updates];
  }

  if (userType === OrderUserTypes.ResearcherSendQueryResponse) {
    return [sections.updateNotesSection, sections.applicantInfo, sections.updates];
  }

  if (userType === OrderUserTypes.ResearchCenterManagerForCloseRequest) {
    return [
      sections.updateNotesSection,
      sections.applicantInfo,
      sections.updates,
      sections.achievementFile,
      sections.fundingTerms,
      sections.researchesCenter,
      sections.progressCertificate,
      sections.procedures,
    ];
  }

  if (userType === OrderUserTypes.FinanceDepartmentForCloseRequest) {
    return [
      sections.updateNotesSection,
      sections.applicantInfo,
      sections.updates,
      sections.achievementFile,
      sections.fundingTerms,
      sections.researchesCenter,
      sections.progressCertificate,
      sections.procedures,
    ];
  }

  if (userType === OrderUserTypes.FundFinanceForCloseRequest) {
    return [
      sections.updateNotesSection,
      sections.applicantInfo,
      sections.updates,
      sections.achievementFile,
      sections.fundingTerms,
      sections.researchesCenter,
      sections.progressCertificate,
      sections.procedures,
    ];
  }

  if (userType === OrderUserTypes.VPofDeanForCloseRequest) {
    return [
      sections.updateNotesSection,
      sections.applicantInfo,
      sections.updates,
      sections.achievementFile,
      sections.fundingTerms,
      sections.researchesCenter,
      sections.progressCertificate,
      sections.procedures,
    ];
  }

  if (userType === OrderUserTypes.DeanOfScientificResearchForCloseRequest) {
    return [
      sections.updateNotesSection,
      sections.applicantInfo,
      sections.updates,
      sections.achievementFile,
      sections.fundingTerms,
      sections.researchesCenter,
      sections.progressCertificate,
      sections.procedures,
    ];
  }

  if (userType === OrderUserTypes.FundingUnitForCloseRequest) {
    return [
      sections.updateNotesSection,
      sections.applicantInfo,
      sections.updates,
      sections.achievementFile,
      sections.fundingTerms,
      sections.researchesCenter,
      sections.progressCertificate,
      sections.procedures,
    ];
  }

  return [
    // sections.updateNotesSection,
    sections.applicantInfo,
    sections.updates,
    sections.achievementFile,
    sections.fundingTerms,
    sections.researchesCenter,
    sections.procedures,
  ];
};

export const OrderInfoTab = ({
  order,
  userType,
  activePage,
  setActivePage,
  openedTask,
  programUpdateHandlersRef,
  closeOrderFormProps,
  orderProgram,
  approveByCenterManagerSubmitHandlerRef,
}: {
  order: OrderLocalItemResult | null;
  userType: keyof typeof OrderUserTypes;
  activePage: keyof typeof OrderActivePages;
  setActivePage: Dispatch<SetStateAction<keyof typeof OrderActivePages>>;
  openedTask: OpenFundingTaskResult | null;
  programUpdateHandlersRef: React.MutableRefObject<EvaluateProgramUpdateRef | null>;
  approveByCenterManagerSubmitHandlerRef: React.MutableRefObject<ApproveByCenterManagerSubmitHandlerRef | null>;
  closeOrderFormProps?: CloseOrderFormProps;
  orderProgram?: ProgramByIDResponse | null;
}) => {
  const activeSectionsIds = getActiveSectionsIds(userType, activePage);

  return (
    <div className="position-relative">
      {activeSectionsIds.map((sectionId) => {
        switch (sectionId) {
          case sections.updateNotesSection:
            return <UpdateNotes key={sectionId} order={order} userType={userType} />;
          case sections.applicantInfo:
            return (
              <ApplicantInfo
                key={sectionId}
                appliedUser={order?.appliedUser}
                files={order?.files}
                formData={{
                  subject: order?.formSubject ?? "",
                  value: order?.formValue ?? "{}",
                  schema: orderProgram?.formBuilderSchema,
                }}
                userType={userType}
              />
            );
          case sections.updates:
            return (
              <Updates
                key={sectionId}
                userType={userType}
                setActivePage={setActivePage}
                programOrderUpdateId={openedTask?.programOrderUpdateId ?? 0}
                order={order}
                programUpdateHandlersRef={programUpdateHandlersRef}
              />
            );
          case sections.achievementFile:
            return (
              <AchievementFile
                key={sectionId}
                userType={userType}
                activePage={activePage}
                closeOrderFormProps={closeOrderFormProps}
                order={order}
              />
            );
          case sections.fundingTerms:
            return (
              <FundingTerms
                key={sectionId}
                userType={userType}
                activePage={activePage}
                closeOrderFormProps={closeOrderFormProps}
                orderProgram={orderProgram}
                order={order}
              />
            );
          case sections.researchesCenter:
            return <ResearchesCenter key={sectionId} order={order} />;
          case sections.procedures:
            return <Procedures key={sectionId} orderId={order?.id ?? 0} userType={userType} />;
          case sections.examinerSection:
            return <ExaminersSection order={order} key={sectionId} />;
          case sections.userBankingInfo:
            return <BankingInformation key={sectionId} closeOrderFormProps={closeOrderFormProps} />;
          case sections.progressCertificate:
            return (
              <ProgressCertificate
                key={sectionId}
                approveByCenterManagerSubmitHandlerRef={approveByCenterManagerSubmitHandlerRef}
                closeOrder={order?.closeOrder}
                isForEdit={userType === OrderUserTypes.ResearchCenterManagerForCloseRequest}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};
