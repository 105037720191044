import Modal, { ModalProps } from "components/blocks/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AddEditProgramMinutesRef } from "../../tabs/OrdersTap/ViceDeanOrdersTab/AddProgramMinutes";
import { useNotification } from "hooks/useNotification";

const SendContractModal: React.FC<
  ModalProps & {
    addEditProgramMinutesRef: React.RefObject<AddEditProgramMinutesRef>;
  }
> = (props) => {
  const { isOpen, onClose } = props;
  const { showNotification } = useNotification();
  const { t } = useTranslation(["Programs"]);
  const navigate = useNavigate();

  const onCancel = () => {
    onClose?.();
  };

  const onSend = async () => {
    const res = await props.addEditProgramMinutesRef.current?.submitAddProgramMinuteForm();

    if (res && !res?.hasError) {
      navigate("/home/programs");
    }

    if (!res) {
      // form is not valid
      showNotification({
        description: t("pleaseFillAllRequiredFields"),
        type: "error",
      });
    }
  };

  return (
    <Modal
      containerClassName="bg-white pb-2"
      isOpen={isOpen}
      onClose={onClose}
      title={t("Programs:sendContractNotes")}
      withHeaderBorder
      size="lg"
      titleClassName="fs-6 text-dark"
    >
      <form noValidate>
        <label className="form-label mt-2">{t("Common:notes")}</label>
        <textarea
          className={`form-control bg-white`}
          rows={5}
          disabled
          placeholder={t("Common:notes")}
          style={{ resize: "none" }}
          defaultValue={t("sendContractNotesMessage")}
        />
        <div className="divider my-3"></div>
        <div className="d-flex justify-content-center gap-2 w-100">
          <button type="button" className="btn border text-dark rounded-3 p-10-32P" onClick={onCancel}>
            {t("Common:cancel")}
          </button>
          <button type="button" className="btn btn-primary rounded-3 p-10-32P" onClick={onSend}>
            {t("Common:send")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default SendContractModal;
