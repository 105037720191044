import { SelectedFilesType } from "components/blocks/FileUploadWithPreview";
import { FileService } from "./../../../../../services/fileService/index";
import useMutation from "hooks/useMutation";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CloseOrderService } from "services/closeOrder";
import { CloseOrderRequest, CloseOrderRequestFormProps } from "services/closeOrder/models";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import { getCreateCloseRequest } from "./createCloseRequest.schema";

const useResearcherActions = ({ order, serialGuid }: { order?: OrderLocalItemResult | null; serialGuid: string }) => {
  const { t } = useTranslation("Orders");
  const navigate = useNavigate();

  const [files, setFiles] = useState<SelectedFilesType[]>([]);
  const {
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    reset,
  } = useForm<CloseOrderRequestFormProps>({
    defaultValues: {
      filesIds: [],
      financingTermsRequest: [],
      journalClassificationId: 0,
      journalName: "",
      journalRankingId: 0,
      journalSearchLink: "",
      notes: "",
      workFlowTaskRequest: serialGuid
        ? {
            actionName: "",
            notes: "",
            serialGuid: "",
          }
        : undefined,
    },
    resolver: getCreateCloseRequest(t),
  });

  const { mutateAsync: confirmResearcherCloseContract, loading: confirmResearcherCloseContractLoading } = useMutation({
    queryFn: async (data: any) => {
      return await CloseOrderService.updateCloseProgram(order?.closeOrder?.id ?? 0, data);
    },
    notificationSuccessMessage: t("Common:success"),
  });

  const onConfirmResearcherCloseContract = async (values: CloseOrderRequestFormProps) => {
    const closeRequest: CloseOrderRequest = { ...values };
    closeRequest.filesIds = files.map((file) => file.uuid);
    closeRequest.financingTermsRequest = values.financingTermsRequest.map((term) => {
      return {
        ...term,
        financingType: null,
      };
    });

    if (serialGuid) {
      closeRequest.workFlowTaskRequest = {
        actionName: "Complete",
        notes: values.workFlowTaskRequest?.notes,
        serialGuid: serialGuid,
      };
    } else {
      closeRequest.workFlowTaskRequest = undefined;
    }

    const res = await confirmResearcherCloseContract(closeRequest);

    if (res && !res.hasError) {
      navigate(`/home/tasks`);
    }
  };

  useEffect(() => {
    if (order?.closeOrder) {
      reset({
        filesIds: order.closeOrder.files.map((file) => file.id),
        financingTermsRequest: order.closeOrder.financingTerms,
        journalClassificationId: order.closeOrder.journalClassification?.id,
        journalName: order.closeOrder.journalName,
        journalRankingId: order.closeOrder.journalRanking?.id,
        journalSearchLink: order.closeOrder.journalSearchLink,
        notes: order.closeOrder.notes,
        workFlowTaskRequest: serialGuid
          ? {
              actionName: "",
              notes: "", // TODO: getNotes
              serialGuid: serialGuid,
            }
          : undefined,
      });

      setFiles(
        order.closeOrder.files.map((file) => ({
          file: new File([], file.name ?? ""),
          uuid: file.id,
          fileName: file.name ?? "",
          size: file.size,
        })),
      );
    }
  }, [order]);

  return {
    onConfirmResearcherCloseContract: handleSubmit(onConfirmResearcherCloseContract),
    confirmResearcherCloseContractLoading,
    watch,
    setValue,
    errors,
    files,
    setFiles,
  };
};

export default useResearcherActions;
