import PageWithTabs from "components/PageWithTabs";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Switch from "components/blocks/Switch";
import { ProgramsService } from "services/programs";
import { useNotification } from "hooks/useNotification";
import { useCallback, useEffect, useState } from "react";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useCookieContext } from "contexts";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import AboutProgramTab from "views/home/programs/ViewProgram/tabs/AboutProgramTab";
import MySignedWordOrderTab from "./Tabs/Order";

type TapsIds = "about" | "order";
const tabsIds: { [key in TapsIds]: TapsIds } = {
  about: "about",
  order: "order",
};

const MySignedWorkView = () => {
  // Hooks
  const { showNotification } = useNotification();
  const { t } = useTranslation("OrdersMyWork");
  const { id: programId } = useParams();
  const navigate = useNavigate();
  const { isAr } = useCookieContext();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  // State
  const [programData, setProgramData] = useState<ProgramByIDResponse>();
  const [loading, setLoading] = useState(false);

  // Functions
  const fetchProgram = useCallback(async () => {
    if (!programId) {
      showNotification({ description: "programId is required", type: "error" });
      return navigate(-1);
    }
    try {
      setLoading(true);
      const response = await ProgramsService.getProgramById(+programId);
      if (response.hasError) {
        showNotification({ description: response.description, type: "error" });
        navigate(-1);
      } else {
        setProgramData(response.data);
      }
    } catch {
      showNotification({ description: t("Common:internalServerError"), type: "error" });
      // TODO: check if we need to navigate back
      //   navigate(-1);
    } finally {
      setLoading(false);
    }
  }, []);

  // Effects
  useEffect(() => {
    fetchProgram();
  }, [fetchProgram, programId, isAr]);

  const programName = programData
    ? `${programData?.programDetails.find((x) => x.locale === (isAr ? "Ar" : "En"))?.displayName}`
    : "";

  return (
    <>
      <LoadingOverlay visible={loading} />
      <PageWithTabs
        titleImgSrc={
          programData
            ? `https://apiGateway.aaltair.net/FileManagerApi/File/Download/${programData.adsImageId}`
            : undefined
        }
        title={
          <div className="d-flex gap-2">
            {programName}
            <Switch checked onChange={() => {}} />
          </div>
        }
        subTitle={`رقم البرنامج: ${programData?.serialNumber}`}
        defaultTabId={tabsIds.order}
        keepTabsMounted
        pageHeaders={
          !programData
            ? []
            : [
                {
                  title: t("orderNumber"),
                  value: `12345678`,
                },
                {
                  title: t("Programs:contractStart"),
                  value: `${toSystemThemeDateFormat(programData.contractStartDate)}`,
                },
                {
                  title: t("OrdersUnsignedWork:researchCenter"),
                  value: `مركز البحوث العلمية`,
                },
                {
                  title: t("Programs:funding"),
                  value: `${programData.fundingDurationInMonths} ${t("months")} - ${programData.fundingAmount}${t(
                    "Common:riyal",
                  )}`,
                },
                {
                  title: t("Programs:fundingType"),
                  // TODO: there is multiple financing types - which one to show?
                  value: programData.financingCalculationItems?.[0].financingType.financingTypesDetail.displayName,
                },
                {
                  title: t("approvedOn"),
                  // TODO: get the value from backend
                  value: (
                    <span className="text-primary fw-bold" role="button">
                      شروط العقد
                    </span>
                  ),
                },
              ]
        }
        tabs={[
          {
            id: tabsIds.order,
            title: t("order"),
            content: programData ? <MySignedWordOrderTab /> : null,
          },
          {
            id: tabsIds.about,
            title: t("Programs:aboutProgram"),
            content: programData ? <AboutProgramTab program={programData} /> : null,
          },
        ]}
      />
    </>
  );
};

export default MySignedWorkView;
