import { zodResolver } from "@hookform/resolvers/zod";
import { FinancingTermsRequest } from "services/closeOrder/models";
import { z } from "zod";
import { FinancingDropdownType } from "./AddEditFundingTermModal";

export const getCreateOrderSchema = (t: any, selectedType?: FinancingDropdownType) => {
  const getRequiredFieldsBasedOnCalculationWay = () => {
    if (selectedType && selectedType.calculationWay) {
      return {
        financingTypeId: z.number().min(1, t("Common:invalidValue")),
        timeInMonth: z.number().min(1, t("Common:invalidValue")),
        value: z
          .number()
          .min(selectedType.fromValue, t("Common:invalidValue"))
          .max(selectedType.toValue, t("Common:invalidValue")),
        name: z.string().min(1, t("Common:invalidValue")),
        degreeId: z.number().min(1, t("Common:invalidValue")),
        specializationId: z.number().min(1, t("Common:invalidValue")),
        email: z.string().email({
          message: t("Common:invalidValue"),
        }),
        id: z.number(),
        nationalityId: z.number().min(1, t("Common:invalidValue")),
        notes: z.string().optional(),
        passportNumber: z.string().min(1, t("Common:invalidValue")),
        workAddress: z.string().min(1, t("Common:invalidValue")),
        financingType: z.any().optional(),
      };
    }

    return {
      financingTypeId: z.number().min(1, t("Common:invalidValue")),
      value: selectedType
        ? z
            .number()
            .min(selectedType.fromValue, t("Common:invalidValue"))
            .max(selectedType.toValue, t("Common:invalidValue"))
        : z.number().min(1, t("Common:invalidValue")),
      notes: z.string().min(1, t("Common:invalidValue")),

      // hidden fields
      timeInMonth: z.number().optional(),
      name: z.string().optional(),
      degreeId: z.number().optional(),
      specializationId: z.number().optional(),
      email: z.string().optional(),
      id: z.number(),
      nationalityId: z.number().optional(),
      passportNumber: z.string().optional(),
      workAddress: z.string().optional(),
      financingType: z.any().optional(),
    };
  };

  const schema = z.object(getRequiredFieldsBasedOnCalculationWay()) as z.ZodType<FinancingTermsRequest>;

  return zodResolver(schema);
};
