import { AccountService } from "./accountService";
import { LookupService } from "./lookupService";
import { OrdersService } from "./orders";
import { ProgramsService } from "./programs";
import { TasksService } from "./tasks";
import { EvaluationFormsService } from "./evaluationFormsService";
import { ExaminersService } from "./examiners";
import { ExaminerRewardsService } from "./examinerRewards";
import { SettingsService } from "./settingsService";

export const BackendServices = {
  lookupService: LookupService,
  programService: ProgramsService,
  accountService: AccountService,
  orderService: OrdersService,
  tasksService: TasksService,
  evaluationFormsService: EvaluationFormsService,
  examinersService: ExaminersService,
  examinerRewardsService: ExaminerRewardsService,
  settingsService: SettingsService,
};
