import AttachmentCard from "components/blocks/AttachmentCard";
import { useTranslation } from "react-i18next";
import { ProgramOrderUpdateResult } from "services/programOrderUpdate/models";

export const UpdateNotes = ({ programOrderUpdate }: { programOrderUpdate: ProgramOrderUpdateResult | undefined }) => {
  const { t } = useTranslation("Orders");

  return (
    <div className="d-flex flex-column gap-2">
      {programOrderUpdate?.returnNotes && (
        <div>
          <h6 className="fw-bold">{t("researchCenterManagementNotes")}</h6>
          <p>{programOrderUpdate?.returnNotes}</p>
        </div>
      )}

      {!!programOrderUpdate?.files.length && (
        <div>
          <h6 className="fw-bold">{t("Common:attachments")}</h6>
          <div className="d-flex gap-2">
            {programOrderUpdate?.files.map((file) => (
              <AttachmentCard
                key={file.id}
                attachment={{
                  extension: file.extension ?? "",
                  id: file.id,
                  fileName: file.name ?? "",
                  size: file.size ?? 0,
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
