import { useEffect, useState } from "react";

type ScreenSize = "mobile" | "sm" | "md" | "lg" | "xl";

const useScreenSize = () => {
  const getScreenSize = () => {
    if (window.innerWidth < 576) {
      return "mobile";
    } else if (window.innerWidth < 768) {
      return "sm";
    } else if (window.innerWidth < 992) {
      return "md";
    } else if (window.innerWidth < 1200) {
      return "lg";
    } else {
      return "xl";
    }
  };

  const [screenSize, setScreenSize] = useState<ScreenSize>(getScreenSize());

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return screenSize;
};

export default useScreenSize;
