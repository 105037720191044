import DatePicker from "components/blocks/DatePicker";
import FormInput from "components/blocks/FormInput";
import LookupDropdown from "components/blocks/LookupDropdown";
import { Control, Controller, FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UpdateMyProfileRequest } from "services/accountService/models/AccountType";

export type CreateProfileSectionProps = {
  control: Control<UpdateMyProfileRequest, any>;
  errors: FieldErrors<UpdateMyProfileRequest>;
  register: UseFormRegister<UpdateMyProfileRequest>;
  setValue: UseFormSetValue<UpdateMyProfileRequest>;
};

const PersonalInformation = ({ errors, register, setValue, control }: CreateProfileSectionProps) => {
  const { t, i18n } = useTranslation("UserProfile");

  return (
    <div className="d-flex flex-wrap flex-column gap-2">
      <div className="d-flex flex-wrap gap-2">
        <FormInput
          label={t("nationalId")}
          {...register("nationalId")}
          wrapperClassName="flex-1"
          error={errors.nationalId?.message}
        />
        <FormInput
          label={t("fullNameArabic")}
          wrapperClassName="flex-1"
          {...register("fullNameArabic")}
          error={errors.fullNameArabic?.message}
        />
        <FormInput
          label={t("fullNameEnglish")}
          wrapperClassName="flex-1"
          {...register("fullNameEnglish")}
          error={errors.fullNameEnglish?.message}
        />
        <FormInput label={t("email")} wrapperClassName="flex-1" {...register("email")} error={errors.email?.message} />
      </div>
      <div className="d-flex flex-wrap gap-2">
        <div className="flex-1">
          <label className={`mb-1`}>{t("phoneNumber")}</label>
          <div className={`${i18n.language === "ar" ? "d-flex" : "d-flex flex-row-reverse "} gap-2 `}>
            <FormInput wrapperClassName="flex-2" {...register("phoneNumber")} error={errors.phoneNumber?.message} />
            <Controller
              name="phoneCountryCode"
              control={control}
              render={({ field }: { field: any }) => (
                <LookupDropdown
                  withOutMinWidth
                  labelClassName="invisible"
                  wrapperClassName="flex-1"
                  selectClassName="phone-number"
                  service="lookupService"
                  endpoint="getCountryCodes"
                  idValueKey="countryCode"
                  textValueKey="countryCode"
                  value={field.value || ""}
                  onChange={(v) => {
                    setValue("phoneCountryCode", v);
                  }}
                  error={errors.phoneCountryCode?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="flex-1">
          <label className={`mb-1`}>{t("workPhoneNumber")}</label>
          <div className={`${i18n.language === "ar" ? "d-flex" : "d-flex flex-row-reverse "} gap-2 `}>
            <FormInput
              wrapperClassName="flex-2"
              {...register("workPhoneNumber")}
              error={errors.workPhoneNumber?.message}
            />
            <Controller
              name="workPhoneNumberCountryCode"
              control={control}
              render={({ field }: { field: any }) => (
                <LookupDropdown
                  withOutMinWidth
                  labelClassName="invisible"
                  wrapperClassName="flex-1"
                  selectClassName="phone-number"
                  service="lookupService"
                  endpoint="getCountryCodes"
                  idValueKey="countryCode"
                  textValueKey="countryCode"
                  value={field.value}
                  onChange={(v) => {
                    setValue("workPhoneNumberCountryCode", v);
                  }}
                  error={errors.workPhoneNumberCountryCode?.message}
                />
              )}
            />
          </div>
        </div>
        <Controller
          name="nationality"
          control={control}
          render={({ field }: { field: any }) => (
            <LookupDropdown
              label={t("nationality")}
              wrapperClassName="flex-1"
              service="lookupService"
              endpoint="getNationalities"
              idValueKey="countryDetail.displayName"
              textValueKey="countryDetail.displayName"
              value={field.value || ""}
              onChange={(v) => {
                setValue("nationality", v);
              }}
              error={errors.nationality?.message}
            />
          )}
        />

        <Controller
          name="genderId"
          control={control}
          render={({ field }: { field: any }) => (
            <LookupDropdown
              label={t("gender")}
              wrapperClassName="flex-1"
              service="lookupService"
              endpoint="getGenders"
              idValueKey="id"
              textValueKey="genderDetail.displayName"
              value={field.value || ""}
              onChange={(v) => {
                setValue("genderId", +v);
              }}
              error={errors.genderId?.message}
            />
          )}
        />
      </div>
      <div className="d-flex flex-wrap gap-2">
        <Controller
          name="birthDate"
          control={control}
          render={({ field }: { field: any }) => (
            <DatePicker
              label={t("birthDate")}
              wrapperClassName="flex-1"
              value={field.value}
              onChange={(v) => {
                if (v) setValue("birthDate", v.toISOString());
              }}
              error={errors.birthDate?.message}
            />
          )}
        />

        <FormInput
          label={t("address")}
          wrapperClassName="flex-1"
          {...register("address")}
          error={errors.address?.message}
        />
        <FormInput label={t("city")} wrapperClassName="flex-1" {...register("city")} error={errors.city?.message} />
        <FormInput
          label={t("postCode")}
          wrapperClassName="flex-1"
          {...register("postCode")}
          error={errors.postCode?.message}
        />
      </div>
    </div>
  );
};

export default PersonalInformation;
