import { useTranslation } from "react-i18next";
import useGridStore from "../grid.store";
import { useShallow } from "zustand/react/shallow";
import { useEffect, useState } from "react";
import useScreenSize from "hooks/useScreenSize";

// fixme: an issue with the ... placeholder when the max != 3
const MAX_NUMBER_OF_VISIBLE_PAGES = 3;

const Pagination: React.FC = () => {
  const [goToPageNumber, setGoToPageNumber] = useState(1);
  const { t, i18n } = useTranslation("Common");
  const screenSize = useScreenSize();
  const {
    pagination: { currentPage, totalPages },
    setPagination,
  } = useGridStore(
    useShallow((state) => ({
      pagination: state.pagination,
      setPagination: state.setPagination,
    })),
  );

  useEffect(() => {
    if (goToPageNumber !== currentPage) {
      setGoToPageNumber(currentPage);
    }
    // adding currentPage to the dependencies will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const onPaginate = (page: number) => {
    if (page < 1 || page > totalPages || page === currentPage) {
      return;
    }

    setPagination({ currentPage: page });
  };

  if (totalPages < 2) {
    return null;
  }

  return (
    <div
      className={`d-flex justify-content-center align-items-center pagination-container ${
        screenSize === "mobile" ? "flex-column" : ""
      }`}
    >
      <div className="d-flex align-items-center">
        <div className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button className="page-link" onClick={() => onPaginate(currentPage - 1)}>
            {i18n.language === "en" ? <i className="icon-arrow-left"></i> : <i className="icon-arrow-right"></i>}
            {t("previous")}
          </button>
        </div>

        {currentPage > MAX_NUMBER_OF_VISIBLE_PAGES - 1 && (
          <>
            <div className="page-item">
              <button className="page-link" onClick={() => onPaginate(1)}>
                1
              </button>
            </div>
            {currentPage !== MAX_NUMBER_OF_VISIBLE_PAGES && (
              <div className="page-item disabled">
                <span className="page-link">...</span>
              </div>
            )}
          </>
        )}

        {Array.from(
          { length: MAX_NUMBER_OF_VISIBLE_PAGES },
          (_, i) => currentPage + i - Math.floor(MAX_NUMBER_OF_VISIBLE_PAGES / 2),
        ).map(
          (pageNumber) =>
            pageNumber <= totalPages &&
            pageNumber > 0 && (
              <div key={pageNumber} className={`page-item ${pageNumber === currentPage ? "active" : ""}`}>
                <button className="page-link" onClick={() => onPaginate(pageNumber)}>
                  {pageNumber}
                </button>
              </div>
            ),
        )}

        {currentPage <= totalPages - MAX_NUMBER_OF_VISIBLE_PAGES + 1 && (
          <>
            {currentPage !== totalPages - MAX_NUMBER_OF_VISIBLE_PAGES + 1 && (
              <div className="page-item disabled">
                <span className="page-link">...</span>
              </div>
            )}
            <div className="page-item">
              <button className="page-link" onClick={() => onPaginate(totalPages)}>
                {totalPages}
              </button>
            </div>
          </>
        )}

        <div className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
          <button className="page-link" onClick={() => onPaginate(currentPage + 1)}>
            {t("next")}
            {i18n.language === "en" ? <i className="icon-arrow-right"></i> : <i className="icon-arrow-left"></i>}
          </button>
        </div>
      </div>

      <div className="d-flex align-items-center me-3">
        <span className="mx-2">{t("page")}</span>
        <input
          className="form-control"
          value={goToPageNumber}
          min="1"
          max={totalPages}
          style={{ width: 60, height: 32 }}
          type="number"
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value > 0 && value <= totalPages) {
              setGoToPageNumber(value);
            }
          }}
        />
        <button
          id="pagination-go-btn"
          className="btn text-primary mx-2"
          type="button"
          onClick={() => onPaginate(goToPageNumber)}
        >
          {t("go")}
        </button>
      </div>
    </div>
  );
};

export default Pagination;
