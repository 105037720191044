import { Fragment } from "react";
import { useMenuContext } from "../../../contexts/menu/menuContext";
import { HomeRoutes } from "../../../routes/homeRoutes/homeRoutes";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RouteItemConfig } from "../../../routes/models";
import { OverlayTrigger } from "react-bootstrap";
import { useCookieContext } from "contexts";
import useScreenSize from "hooks/useScreenSize";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";// Install with `npm install jwt-decode`

// Interface for decoded JWT roles
interface DecodedToken {
  role: string[];
}

function SideMenu() {
  const { isMenuOpen: isMenuOpenState, toggleMenu, setSelectedMenuItem, selectedMenuItem } = useMenuContext();
  const { t } = useTranslation("Shared");
  const { isRTL } = useCookieContext();
  const screenSize = useScreenSize();
  const isMenuOpen = screenSize !== "mobile" ? isMenuOpenState : true;

  // Function to decode JWT and extract roles
  const getRolesFromToken = (): string[] => {
    const token = Cookies.get("Session");
    if (!token) return [];
    try {
    
      const decoded: DecodedToken = jwtDecode(JSON.parse(token)?.token);
      
      
      // @ts-ignore
      return decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]   || [];
      
    } catch (error) {
      console.error("Failed to decode token:", error);
      return [];
    }
  };

  const userRoles = getRolesFromToken();

  // Function to check if a user has at least one required role for a route
  const hasRequiredRole = (routeRoles: string[] = []) => {
    return routeRoles.length === 0 || routeRoles.some((role) => userRoles.includes(role));
  };

  const onLinkedClicked = (route: RouteItemConfig) => {
    setSelectedMenuItem(route);
    document.getElementById("side-menu-wrapper")?.classList.toggle("collapsed");
  };

  const groupList = (): any[] => {
    let groupsName: any[] = [];
    HomeRoutes.forEach((route) => {
      if (groupsName.indexOf(route.groupName) === -1) {
        groupsName.push(route.groupName);
      }
    });
    return groupsName;
  };

  const onToggleMenu = () => {
    if (isMenuOpen) {
      document.querySelectorAll(".submenu").forEach((submenu) => {
        submenu.classList.remove("d-block");
      });
      document.querySelectorAll(".side-dropdown-toggle").forEach((toggle) => {
        toggle.classList.remove("active");
      });
    }
    toggleMenu();
  };

  const openSubMenu = (route: RouteItemConfig) => {
    const subMenu = document.getElementById(`${route.path}-menu`);
    const toggle = document.getElementById(`${route.path}`);
    if (subMenu) {
      if (subMenu.classList.contains("d-block")) {
        subMenu.classList.remove("d-block");
      } else {
        if (!isMenuOpen) toggleMenu();
        subMenu.classList.add("d-block");
      }
    }
    if (toggle) {
      toggle.classList.toggle("active");
    }
  };

  const isActivated = (route: RouteItemConfig) => {
    return selectedMenuItem?.path === route.path || route.path === window.location.pathname ? "active" : "";
  };

  return (
      <>
        <div id="side-menu" className={`side-menu h-100 rounded-4 overflow-auto ${isMenuOpen ? "" : "collapsed"}`}>
          {groupList().map((group: any, index) => {
            return (
                <div key={`group-${group}-${index}`} className="side-menu-section">
                  {group && <p className="text-darkGray fs-6">{t(group)}</p>}
                  {HomeRoutes.filter(
                      (route) => route.groupName === group && route.showInMenu && hasRequiredRole(route.roles)
                  ).map((route) => {
                    if (route.subMenu && route.subMenu.filter((subRoute) => subRoute.showInMenu && hasRequiredRole(subRoute.roles)).length > 0) {
                      return (
                          <Fragment key={`${route.path}`}>
                            <OverlayTrigger
                                show={isMenuOpen ? false : undefined}
                                key={route.path}
                                trigger={["focus", "hover"]}
                                placement={isRTL ? "left" : "right"}
                                offset={[0, 10]}
                                overlay={(props) => (
                                    <div {...props}>
                                      <div className="bg-white rounded px-3 py-2 shadow-sm">{t(route.name)}</div>
                                    </div>
                                )}
                            >
                              <div
                                  onClick={() => openSubMenu(route)}
                                  className="d-flex gap-2 align-items-center my-2 text-gray border-0 menu-item btn p-13-16P cursor-pointer"
                              >
                                <div>
                                  <i className={`${route.icon ?? "icon-workflow"}`}></i>
                                </div>
                                <div className="menu-item-text">{t(route.name)}</div>
                                <i id={route.path} className="side-dropdown-toggle" />
                              </div>
                            </OverlayTrigger>
                            <ul id={`${route.path}-menu`} className="submenu">
                              {route.subMenu
                                  .filter((sub) => sub.showInMenu && hasRequiredRole(sub.roles))
                                  .map((subRoute, index) => {
                                    return (
                                        <li key={`${subRoute.path}-${index}`}>
                                          <Link
                                              to={subRoute.path}
                                              onClick={() => onLinkedClicked(subRoute)}
                                              className={`btn p-13-16P text-start ${
                                                  isActivated(subRoute) ? "text-primary bg-primary bg-opacity-10" : "text-gray"
                                              } `}
                                          >
                                            <span className={"mx-2 fw-bolder "}>&#x2022;</span> {t(subRoute.name)}
                                          </Link>
                                        </li>
                                    );
                                  })}
                            </ul>
                          </Fragment>
                      );
                    }

                    return (
                        <OverlayTrigger
                            show={isMenuOpen ? false : undefined}
                            key={route.path}
                            trigger={["focus", "hover"]}
                            placement={isRTL ? "left" : "right"}
                            offset={[0, 10]}
                            overlay={(props) => (
                                <div {...props}>
                                  <div className="bg-white rounded px-3 py-2 shadow-sm">{t(route.name)}</div>
                                </div>
                            )}
                        >
                          <Link
                              to={route.path}
                              key={route.path}
                              onClick={() => onLinkedClicked(route)}
                              className={`d-flex gap-2 align-items-center my-2 ${
                                  isActivated(route) ? "text-primary bg-primary bg-opacity-10" : "text-gray"
                              } menu-item btn p-13-16P ${selectedMenuItem?.path === route.path ? "active" : ""}`}
                          >
                            <div>
                              <i className={route.icon}></i>
                            </div>
                            <div className="menu-item-text">{t(route.name)}</div>
                          </Link>
                        </OverlayTrigger>
                    );
                  })}
                </div>
            );
          })}
          <div id="menu-button" className="d-none d-sm-flex position-absolute menu-button" onClick={onToggleMenu}></div>
        </div>
      </>
  );
}

export default SideMenu;
