import { BaseResponseType } from "services/shared-models/sharedTypes";
import { useNotification } from "./useNotification";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";

type MutationProps<T, P> = {
  queryFn: (props: P) => Promise<BaseResponseType<T>> | Promise<void> | undefined;
  notificationSuccessMessage?: string;
};

const useMutation = <T, P>({ queryFn, notificationSuccessMessage }: MutationProps<T, P>) => {
  const { t } = useTranslation("Common");
  const { showNotification } = useNotification();
  const [data, setData] = useState<T | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (params: P) => {
    setLoading(true);

    try {
      const response = await queryFn(params);

      if (response?.hasError) {
        setData(undefined);
        showNotification({ description: response.description, type: "error" });
      } else {
        setData(response?.data);

        if (notificationSuccessMessage) {
          showNotification({ description: notificationSuccessMessage, type: "success" });
        }
      }

      return response;
    } catch (err) {
      showNotification({ description: t("Common:internalServerError"), type: "error" });
      setData(undefined);
      return {
        hasError: true,
        description: t("Common:internalServerError"),
        code: 500,
        data: null,
      };
    } finally {
      setLoading(false);
    }
  };

  const reset = () => {
    setData(undefined);
  };

  return { data, loading, mutateAsync: fetchData, reset };
};

export default useMutation;
