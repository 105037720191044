import { RouteItemConfig } from "../models";
import DashboardView from "../../views/home/dashboard/dashboardView";
import ProgramsGridView from "views/home/programs/ProgramsGridView";
import ProgramFormView from "views/home/programs/Forms/ProgramFormView";
import OrderGridView from "views/home/orders/OrderGridView";
import OrderView from "views/home/orders/OrderView";
import SamplesView from "views/home/programs/SamplesView";
import TasksGridView from "views/home/tasks/TasksGridView";
import ViewProgram from "views/home/programs/ViewProgram";
import OrderForm from "views/home/orders/OrderForm";
import ExaminerTaskView from "views/home/tasks/ExaminerTaskView";
import ResearchersGridView from "views/home/researchers/ResearchersGridView";
import UserProfile from "views/home/userProfile";
import ResearcherPaymentsGridView from "views/home/researcherPayments/ResearcherPaymentsGridView";
import { Outlet } from "react-router-dom";
import ExaminerRewardsGridView from "views/home/examinerRewards/ExaminerRewardsGridView";
import MyUnsignedWorkView from "views/home/researcherOrders/MyUnsignedWork/MyUnsignedWorkView";
import MyUnsignedWorkGridView from "views/home/researcherOrders/MyUnsignedWork/MyUnsignedWorkGridView";
import MySignedWorkGridView from "views/home/researcherOrders/MySignedWork/MySignedWorkGridView";
import MySignedWorkView from "views/home/researcherOrders/MySignedWork/MySignedWorkView";
import EvaluationFormsGridView from "views/home/evaluationForms/EvaluationFormsGridView.tsx";
import EvaluationFormAddUpdateView from "views/home/evaluationForms/FormView";
import PublicProgramsGridView from "views/home/publicPrograms/publicProgramsGridView";
import ExaminersGridView from "views/home/examiners/ExaminersGridView";
import ExaminersCompletedTasksGridView from "views/home/examinersCompletedTasksGridView/ExaminersCompletedTasksGridView";
import EditUserProfileForm from "views/home/userProfile/EditUserProfileForm";
import MyRewardsGridView from "views/home/examinerRewards/MyRewardsGridView";

export const HomeRoutes: RouteItemConfig[] = [
  {
    path: "/home/dashboard",
    name: "dashboard",
    component: DashboardView,
    icon: "icon-dashboard",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [
      "FundingDashboardRead",
      "FundingDashboardWrite",
      "FundingDashboardDelete",
      "FundingDashboardAcceptReject",
      "FundingDashboardCreate",
    ],
    showInMenu: true,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/programs",
    name: "allPrograms",
    component: () => <ProgramsGridView userType="Admin" />,
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [
      "FundingAllProgramsRead",
      "FundingAllProgramsWrite",
      "FundingAllProgramsDelete",
      "FundingAllProgramsAcceptReject",
      "FundingAllProgramsCreate",
    ],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/viceDeanPrograms",
    name: "viceDeanPrograms",
    component: () => <ProgramsGridView userType="ViceDean" columnsToHide={["Status"]} />,
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [
      "FundingViceDeanProgramsRead",
      "FundingViceDeanProgramsWrite",
      "FundingViceDeanProgramsDelete",
      "FundingViceDeanProgramsAcceptReject",
      "FundingViceDeanProgramsCreate",
    ],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/fundingUnitPrograms",
    name: "fundingUnitPrograms",
    component: () => <ProgramsGridView userType="FundingUnit" />,
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [
      "FundingFundingUnitProgramsRead",
      "FundingFundingUnitProgramsWrite",
      "FundingFundingUnitProgramsDelete",
      "FundingFundingUnitProgramsAcceptReject",
      "FundingFundingUnitProgramsCreate",
    ],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/programs/form",
    name: "",
    component: ProgramFormView,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: "/home/programs/:programId",
    name: "",
    component: () => <ViewProgram userType="Admin" />,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },

  {
    path: "/home/tasks/researcherSignContractTask/:serialGID",
    name: "",
    component: () => <ViewProgram userType="Researcher" />,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: "/home/fundingUnitPrograms/:programId",
    name: "",
    component: () => <ViewProgram userType="FundingUnit" />,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: "/home/viceDeanPrograms/:programId",
    name: "",
    component: () => <ViewProgram userType="ViceDean" />,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: "/home/tasks/viceDeanTask/:serialGID",
    name: "",
    component: () => <ViewProgram userType="ViceDean" />,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: "/home/programs/public",
    name: "publicPrograms",
    component: PublicProgramsGridView,
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [
      "FundingPublicProgramsRead",
      "FundingPublicProgramsWrite",
      "FundingPublicProgramsDelete",
      "FundingPublicProgramsAcceptReject",
      "FundingPublicProgramsCreate",
    ],
    showInMenu: true,
    isRoute: true,
    groupName: "essentials",
  },
  {
    path: "/home/samples",
    name: "",
    component: SamplesView,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: "/home/orders",
    name: "allOrders",
    component: OrderGridView,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [
      "FundingAllOrdersRead",
      "FundingAllOrdersWrite",
      "FundingAllOrdersDelete",
      "FundingAllOrdersAcceptReject",
      "FundingAllOrdersCreate",
    ],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/centralManagerOrders",
    name: "centralManagerOrders",
    component: OrderGridView,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [
      "FundingCentralManagerOrdersRead",
      "FundingCentralManagerOrdersWrite",
      "FundingCentralManagerOrdersDelete",
      "FundingCentralManagerOrdersAcceptReject",
      "FundingCentralManagerOrdersCreate"
    ],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/orders/view/:orderId",
    name: "",
    component: () => <OrderView userType="Admin" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/ResearchCenterManagerUpdateTask/:serialGID",
    name: "",
    component: () => <OrderView userType="ResearchCenterManagerForOrderUpdate" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/ResearchCenterManagerUpdateRequestTask/:serialGID",
    name: "",
    component: () => <OrderView userType="ResearchCenterManagerForResearcherUpdateRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/ResearcherUpdateProgramOrderTask/:serialGID",
    name: "",
    component: () => <OrderView userType="ResearcherEditProgramOrderTask" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/ResearchCenterManagerApologiesRequestTask/:serialGID",
    name: "",
    component: () => <OrderView userType="ResearchCenterManagerForResearcherApologiesRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/ResearchCenterManagerExtendRequestTask/:serialGID",
    name: "",
    component: () => <OrderView userType="ResearchCenterManagerForResearcherExtendRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/DeanOfScientificResearchExtendRequestTask/:serialGID",
    name: "",
    component: () => <OrderView userType="DeanOfScientificResearchForResearcherExtendRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/ResearchCenterManagerQueryRequestTask/:serialGID",
    name: "",
    component: () => <OrderView userType="ResearchCenterManagerQueryRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/ResearcherSendQueryResponseTask/:serialGID",
    name: "",
    component: () => <OrderView userType="ResearcherSendQueryResponse" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/ResearcherSendExtendResponseTask/:serialGID",
    name: "",
    component: () => <OrderView userType="ResearcherSendExtendResponseAfterReturnRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/fundingunit/:serialGID",
    name: "",
    component: () => <OrderView userType="FundingUnit" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/DeanOfScientificResearchApologiesRequestTask/:serialGID",
    name: "",
    component: () => <OrderView userType="DeanOfScientificResearchForResearcherApologiesRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/ResearchCenterManagerCloseRequestTask/:serialGID",
    name: "",
    component: () => <OrderView userType="ResearchCenterManagerForCloseRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/FundingUnitCloseRequestTask/:serialGID",
    name: "",
    component: () => <OrderView userType="FundingUnitForCloseRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/VPofDeanCloseRequestTask/:serialGID",
    name: "",
    component: () => <OrderView userType="VPofDeanForCloseRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/FundFinanceCloseRequestTask/:serialGID",
    name: "",
    component: () => <OrderView userType="FundFinanceForCloseRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/DeanOfScientificResearchCloseRequestTask/:serialGID",
    name: "",
    component: () => <OrderView userType="DeanOfScientificResearchForCloseRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/FinanceDepartmentCloseRequestTask/:serialGID",
    name: "",
    component: () => <OrderView userType="FinanceDepartmentForCloseRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/ResearcherSendCloseResponseTask/:serialGID",
    name: "",
    component: () => <OrderView userType="ResearcherSendCloseResponseAfterReturnRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/ResearcherSendUpdateRequestTask/:serialGID",
    name: "",
    component: () => <OrderView userType="ResearcherSendUpdateResponseAfterReturnRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/ResearcherSendApologizeRequestTask/:serialGID",
    name: "",
    component: () => <OrderView userType="ResearcherSendApologizeResponseAfterReturnRequest" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/DeanOfScientificResearch/:serialGID",
    name: "",
    component: () => <OrderView userType="DeanOfScientificResearch" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/researcher/:orderId",
    name: "",
    component: () => <OrderView userType="Researcher" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/centralManagement/:serialGID",
    name: "allOrders",
    component: () => <OrderView userType="ResearchCenterManager" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/edit/:orderId",
    name: "editOrder",
    component: () => <OrderForm orderFormType="EditOrder" />,
    icon: "icon-orders",
    layout: "/home",
    default: false,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/editOrderTask/:serialGID",
    name: "editOrder",
    component: () => <OrderForm orderFormType="ReturnedOrder" />,
    icon: "icon-orders",
    layout: "/home",
    default: false,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/editOrderByDeanOfScientificResearchTask/:serialGID",
    name: "editOrder",
    component: () => <OrderForm orderFormType="EditOrderByDeanOfScientificResearch" />,
    icon: "icon-orders",
    layout: "/home",
    default: false,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/add",
    name: "allOrders",
    component: () => <OrderForm orderFormType="AddOrder" />,
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/tasks",
    name: "tasks",
    component: TasksGridView,
    icon: "icon-tasks",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [
      "FundingTasksRead",
      "FundingTasksWrite",
      "FundingTasksDelete",
      "FundingTasksAcceptReject",
      "FundingTasksCreate",
    ],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/examinerCompletedTasks",
    name: "examinerCompletedTasks",
    component: ExaminersCompletedTasksGridView,
    icon: "icon-check-list",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [
      "FundingExaminerCompletedTasksRead",
      "FundingExaminerCompletedTasksWrite",
      "FundingExaminerCompletedTasksDelete",
      "FundingExaminerCompletedTasksAcceptReject",
      "FundingExaminerCompletedTasksCreate",
    ],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/tasks/examiner/:serialGID",
    name: "examiner-task",
    component: ExaminerTaskView,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/researchersManagement",
    name: "researchersManagement",
    component: Outlet,
    icon: "icon-researchers",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [
      "FundingResearchersManagementRead",
      "FundingResearchersManagementWrite",
      "FundingResearchersManagementDelete",
      "FundingResearchersManagementAcceptReject",
      "FundingResearchersManagementCreate",
    ],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
    subMenu: [
      {
        path: "/home/researchersManagement/researchers",
        name: "researchersInfo",
        component: ResearchersGridView,
        icon: "icon-researchers",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [
          "FundingResearchersInfoRead",
          "FundingResearchersInfoWrite",
          "FundingResearchersInfoDelete",
          "FundingResearchersInfoAcceptReject",
          "FundingResearchersInfoCreate",
        ],
        showInMenu: true,
        isRoute: true,
        id: 1,
        groupName: "essentials",
      },
      {
        path: "/home/researchersManagement/researchers/view",
        name: "researcherInfo",
        component: UserProfile,
        icon: "icon-researchers",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: false,
        isRoute: true,
        id: 1,
      },
      {
        path: "/home/researchersManagement/researcherPayments",
        name: "researcherPayments",
        component: ResearcherPaymentsGridView,
        icon: "icon-dashboard",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [
          "FundingResearcherPaymentsRead",
          "FundingResearcherPaymentsWrite",
          "FundingResearcherPaymentsDelete",
          "FundingResearcherPaymentsAcceptReject",
          "FundingResearcherPaymentsCreate",
        ],
        showInMenu: true,
        isRoute: true,
      },
    ],
  },
  {
    path: "/home/examinersManagement",
    name: "examinersManagement",
    component: Outlet,
    icon: "icon-researchers",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [
      "FundingExaminersManagementRead",
      "FundingExaminersManagementWrite",
      "FundingExaminersManagementDelete",
      "FundingExaminersManagementAcceptReject",
      "FundingExaminersManagementCreate",
    ],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
    subMenu: [
      {
        path: "/home/examinersManagement/examiners",
        name: "examinersInfo",
        component: ExaminersGridView,
        icon: "icon-researcher",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [
          "FundingExaminersInfoRead",
          "FundingExaminersInfoWrite",
          "FundingExaminersInfoDelete",
          "FundingExaminersInfoAcceptReject",
          "FundingExaminersInfoCreate",
        ],
        showInMenu: true,
        isRoute: true,
      },
      {
        path: "/home/examinersManagement/examiners/view",
        name: "researcherInfo",
        component: UserProfile,
        icon: "icon-researchers",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: false,
        isRoute: true,
        id: 1,
      },
      {
        path: "/home/examinersManagement/examinerRewards",
        name: "examinerRewards",
        component: ExaminerRewardsGridView,
        icon: "icon-dashboard",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [
          "FundingExaminerRewardsRead",
          "FundingExaminerRewardsWrite",
          "FundingExaminerRewardsDelete",
          "FundingExaminerRewardsAcceptReject",
          "FundingExaminerRewardsCreate",
        ],
        showInMenu: true,
        isRoute: true,
      },
    ],
  },
  {
    path: "/home/userProfile",
    name: "researcherInfo",
    component: UserProfile,
    icon: "icon-researchers",
    layout: "/home",
    default: false,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/editUserProfile/:userId",
    name: "researcherInfo",
    component: EditUserProfileForm,
    icon: "icon-researchers",
    layout: "/home",
    default: false,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/researcherOrders",
    name: "orders",
    component: Outlet,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [
      "FundingOrdersRead",
      "FundingOrdersWrite",
      "FundingOrdersDelete",
      "FundingOrdersAcceptReject",
      "FundingOrdersCreate",
    ],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
    subMenu: [
      {
        path: "/home/orders/researcherOrders/myWork",
        name: "mySignedWork",
        component: MySignedWorkGridView,
        icon: "",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [
          "FundingMySignedWorkRead",
          "FundingMySignedWorkWrite",
          "FundingMySignedWorkDelete",
          "FundingMySignedWorkAcceptReject",
          "FundingMySignedWorkCreate",
        ],
        showInMenu: true,
        isRoute: true,
      },
      {
        path: "/home/orders/researcherOrders/allUnsignedOrders",
        name: "allUnsignedOrders",
        component: MyUnsignedWorkGridView,
        icon: "",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [
          "FundingAllUnsignedOrdersRead",
          "FundingAllUnsignedOrdersWrite",
          "FundingAllUnsignedOrdersDelete",
          "FundingAllUnsignedOrdersAcceptReject",
          "FundingAllUnsignedOrdersCreate",
        ],
        showInMenu: true,
        isRoute: true,
      },
      {
        path: "/home/orders/researcherOrders/allUnsignedOrders/:id",
        name: "",
        component: MyUnsignedWorkView,
        icon: "",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: false,
        isRoute: true,
      },
      {
        path: "/home/orders/researcherOrders/myWork/:id",
        name: "",
        component: MySignedWorkView,
        icon: "",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: false,
        isRoute: true,
      },
    ],
  },
  {
    path: "/home/evaluationForms",
    name: "evaluationForms",
    component: EvaluationFormsGridView,
    icon: "icon-eval-form",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [
      "FundingEvaluationFormsRead",
      "FundingEvaluationFormsWrite",
      "FundingEvaluationFormsDelete",
      "FundingEvaluationFormsAcceptReject",
      "FundingEvaluationFormsCreate",
    ],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/evaluationForms/form",
    name: "",
    component: EvaluationFormAddUpdateView,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/rewards",
    name: "rewards",
    component: MyRewardsGridView,
    icon: "icon-rewards",
    layout: "/home",
    default: false,
    isExact: true,
    authorize: true,
    roles: [
      "FundingRewardsRead",
      "FundingRewardsWrite",
      "FundingRewardsDelete",
      "FundingRewardsAcceptReject",
      "FundingRewardsCreate"
    ],
    showInMenu: true,
    isRoute: true,
    groupName: "essentials",
  },
];
