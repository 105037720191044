import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import IconButton from "components/blocks/IconButton";
import { Dispatch, SetStateAction, useMemo } from "react";
import { SelectedExaminerType } from ".";
import { useTranslation } from "react-i18next";

const InternalExaminersTable = ({
  selectedInternalExaminers,
  setSelectedInternalExaminers,
}: {
  selectedInternalExaminers: SelectedExaminerType[];
  setSelectedInternalExaminers: Dispatch<SetStateAction<SelectedExaminerType[]>>;
}) => {
  const { t, i18n } = useTranslation("Orders");

  const onRemoveExaminer = (examinerId: string) => {
    setSelectedInternalExaminers((old) => old.filter((examiner) => examiner.id !== examinerId));
  };

  const onRemoveAllExaminers = () => {
    setSelectedInternalExaminers([]);
  };

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "name",
        field: "name",
        displayName: t("userFullName"),
        render: (row) => {
          return (
            <div className="d-flex gap-1">
              <IconButton
                icon="icon-close"
                fitToIconWidth
                innerIconColor="secondary"
                onClick={() => onRemoveExaminer(row.id)}
              />
              <span>{i18n.language === "ar" ? row.arabicName : row.englishName}</span>
            </div>
          );
        },
        renderHeader: () => {
          return (
            <div className="d-flex gap-1">
              <IconButton icon="icon-close" fitToIconWidth innerIconColor="secondary" onClick={onRemoveAllExaminers} />
              <span className="fw-bold">{t("userFullName")}</span>
            </div>
          );
        },
      },
      {
        field: "degree",
        displayName: t("academicDegree"),
      },
      {
        field: "specialization",
        displayName: t("specialization"),
      },
    ],
    [i18n.language, t],
  );

  return <Grid data={selectedInternalExaminers} gridSchema={gridSchema} />;
};

export default InternalExaminersTable;
