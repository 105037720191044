import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import AttachmentCard from "components/blocks/AttachmentCard";
import { useTranslation } from "react-i18next";
import { OrderUserTypes, OrderActivePages } from "../../../..";
import AddAchievementFileForm from "./AddAchievementFileForm";
import { CloseOrderFormProps } from "views/home/orders/OrderView/ResearcherCloseRequestPage";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";

const AchievementFile = ({
  userType,
  activePage,
  closeOrderFormProps,
  order,
}: {
  userType: keyof typeof OrderUserTypes;
  activePage: keyof typeof OrderActivePages;
  closeOrderFormProps?: CloseOrderFormProps;
  order: OrderLocalItemResult | null;
}) => {
  const { t } = useTranslation("Orders");

  const achievementProps = {
    attachments: order?.closeOrder?.files || [],
    journalSearchLink: order?.closeOrder?.journalSearchLink,
    journalName: order?.closeOrder?.journalName,
    journalClassification: order?.closeOrder?.journalClassification,
    journalOrder: order?.closeOrder?.journalRanking,
  };

  return (
    <Accordion>
      <AccordionItem initiallyOpen title={t("achievementFile")} elementId={6}>
        {activePage === OrderActivePages.Default ? (
          <div className="d-flex flex-column gap-4">
            {!!achievementProps.attachments.length && (
              <div className="d-flex flex-wrap gap-3">
                {achievementProps.attachments.map((attachment) => (
                  <AttachmentCard
                    key={attachment.id}
                    attachment={{
                      id: attachment.id,
                      fileName: attachment.name || "",
                      size: attachment.size ?? "",
                      extension: attachment.extension ?? "",
                    }}
                  />
                ))}
              </div>
            )}
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <label>{t("journalSearchLink")}</label>
                <input type="text" className="form-control" disabled value={achievementProps.journalSearchLink} />
              </div>
              <div className="col-lg-3 col-md-6">
                <label>{t("journalName")}</label>
                <input type="text" className="form-control" disabled value={achievementProps.journalName} />
              </div>
              <div className="col-lg-3 col-md-6">
                <label>{t("journalClassification")}</label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={achievementProps.journalClassification?.journalClassificationDetail?.displayName}
                />
              </div>
              <div className="col-lg-3 col-md-6">
                <label>{t("journalOrder")}</label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={achievementProps.journalOrder?.journalRankingDetail?.displayName}
                />
              </div>
            </div>
          </div>
        ) : (
          <AddAchievementFileForm closeOrderFormProps={closeOrderFormProps} />
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default AchievementFile;
