import { AxiosResponse } from "axios";

const useFileDownload = () => {
  const downloadFile = async (response: AxiosResponse<Blob, any>, fileName: string) => {
    const blob = new Blob([response.data], { type: response.headers["content-type"] });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName; 
    document.body.appendChild(link);
    link.click();
    // Cleanup
    link.remove();
  };

  return downloadFile;
};

export default useFileDownload;
