import { zodResolver } from "@hookform/resolvers/zod";
import { CreateProgramMinuteRequest } from "services/orders/models/OrdersTypes";
import { z } from "zod";

export const getCreateProgramMinuteSchema = (t: any) => {
  const schema = z.object({
    programOrderId: z.number().min(1, t("Common:invalidValue")),
    programMinutesNumber: z.string().min(1, t("Common:invalidValue")),
    date: z.date({
      message: t("Common:invalidValue"),
    }),
    description: z.string().min(1, t("Common:invalidValue")),
    files: z.array(z.string(), { message: t("Common:invalidValue") }),
    users: z.array(z.string()).min(1, t("Common:invalidValue")),
  }) as z.ZodType<CreateProgramMinuteRequest>;

  return zodResolver(schema);
};
