import { zodResolver } from "@hookform/resolvers/zod";
import { CloseOrderRequestFormProps } from "services/closeOrder/models";
import { z } from "zod";

export const getCreateCloseRequest = (t: any) => {
  const schema = z.object({
    journalSearchLink: z.string().min(1, t("Common:invalidValue")),
    journalName: z.string().min(1, t("Common:invalidValue")),
    journalClassificationId: z.number().min(1, t("Common:invalidValue")),
    journalRankingId: z.number().min(1, t("Common:invalidValue")),
    notes: z.string().optional(), // TOOD: check if it should be required
    financingTermsRequest: z.array(z.any() /* already validated in the modal */).min(1, "Common:invalidValue"),
    filesIds: z.array(z.string()).min(1, t("Common:invalidValue")),
    workFlowTaskRequest: z.any().optional(),
  }) as z.ZodType<CloseOrderRequestFormProps>;

  return zodResolver(schema);
};
