import { BaseResponseType } from "services/shared-models/sharedTypes";
import { SettingsResult, UpdateSettingsRequest } from "./model/settingsTypes";
import { HttpClient } from "helpers";

export const SettingsService = {
  getSettings: async (key: string) => {
    return await HttpClient.get<BaseResponseType<SettingsResult>>(`/api/Settings/GetSettingValue?key=${key}`);
  },
  updateSettings: async (setting: UpdateSettingsRequest) => {
    return await HttpClient.put<BaseResponseType<SettingsResult>>(`/api/Settings/UpdateSettingValue`, setting);
  },
};
