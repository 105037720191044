import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ResearcherPaymentLocalResult } from "services/researcherPayments/models/ResearcherPaymentsType";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { ResearcherPaymentsService } from "services/researcherPayments";
import { useNotification } from "hooks/useNotification";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import IconButton from "components/blocks/IconButton";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useCookieContext } from "contexts";
import useFileDownload from "hooks/useFileDownload";
import { FileService } from "services/fileService";
import { prepareFilters } from "components/GridView/components/GridFilter";

export default function ResearcherPaymentsGridView() {
  const { t } = useTranslation("ResearcherPayments");
  const { showNotification } = useNotification();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { setBreadcrumbs } = useBreadcrumb();
  const { isAr } = useCookieContext();
  const downloadFile = useFileDownload();

  const ready = useRef(false);
  const [loading, setLoading] = useState(false);
  const [ResearcherPayments, setResearcherPayments] = useState<ResearcherPaymentLocalResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchResearcherPayments = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await ResearcherPaymentsService.getAllResearcherPaymentsAsync({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: prepareFilters(filters),
      });

      setResearcherPayments(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchResearcherPayments();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchResearcherPayments({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "researcherPayments",
        path: "/home/researcherPayments",
        menuItemId: "researcherPayments",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "researcherName",
        displayName: t("researcherName"),
        sortable: true,
        showOnMobile: true,
        render: (row: ResearcherPaymentLocalResult) => {
          return isAr ? row.appliedUser.arabicName : row.appliedUser.englishName;
        },
      },
      {
        accessor: "orderNumber",
        field: "orderNumber",
        displayName: t("orderNumber"),
        sortable: true,
        filterable: true,
        render: (row: ResearcherPaymentLocalResult) => {
          return <Link to={`/home/orders/view/${row.programOrderId}`}>{row.programOrderSerialNumber}</Link>;
        },
      },
      {
        field: "CreatedOn",
        displayName: t("exchangeDate"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "date",
        },
        render: (row: ResearcherPaymentLocalResult) => {
          return <>{toSystemThemeDateFormat(row.exchangeDate)}</>;
        },
      },
      {
        accessor: "amount",
        field: "amount",
        displayName: t("amount"),
        sortable: true,
        render: (row: ResearcherPaymentLocalResult) => {
          return (
            <>
              {row.amount} {t("ryal")}
            </>
          );
        },
      },
      {
        field: "status",
        displayName: t("status"),
        showOnMobile: true,
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "select",
          options: [
            { id: "1", value: t("Paid") },
            { id: "0", value: t("Unpaid") },
            { id: "2", value: t("Pending") },
          ],
        },
        render(row: ResearcherPaymentLocalResult) {
          const statusClassName =
            row.paymentStatus === "Paid"
              ? "bg-success bg-opacity-10 text-success"
              : row.paymentStatus === "Unpaid"
              ? "bg-danger bg-opacity-10 text-danger"
              : "bg-primary bg-opacity-10 text-primary ";

          return <span className={`badge rounded-4 ${statusClassName} py-2`}>{t(row.paymentStatus)}</span>;
        },
      },
      {
        field: "receiptNumber",
        displayName: t("receiptNumber"),
        sortable: false,
        render: (row: ResearcherPaymentLocalResult) => {
          return row.receiptId ? (
            <IconButton
              icon="icon-document"
              innerIconColor="dark"
              fitToIconWidth
              onClick={async () => {
                await FileService.downloadFile(row.receiptId!);
              }}
            />
          ) : (
            <></>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "dashboard",
        path: "/home/dashboard",
        menuItemId: "dashboard",
      },
      {
        localizationKey: "allResearchers",
        path: "/home/allResearchers",
        menuItemId: "allResearchers",
      },
    ]);
  }, []);

  const viewHeader: GridViewHeaderProps = {
    title: t("allResearcherPayments"),
    singularName: t("result"),
    totalCount: totalCount,
    hideAddButton: true,
    onExportClick: async () => {
      setLoading(true);
      try {
        const response = await ResearcherPaymentsService.exportResearcherPaymentsToExcelAsync({
          pageIndex: pagination.currentPage,
          pageSize: 0,
          search: searchValue,
        });

        await downloadFile(response, "researcherPayments.xlsx");
      } catch (error) {
        //
      }
      setLoading(false);
    },
  };

  return (
    <>
      <GridView
        loading={loading}
        gridProps={{
          data: ResearcherPayments ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />
    </>
  );
}
