import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import OrderActionsGrid from "views/home/orders/OrderActionsGrid";

const SubProceduresTab = ({ orderId }: { orderId: number }) => {
  const { t } = useTranslation("Orders");

  return (
    <div>
      <h4>{t("subProcedures")}</h4>
      <OrderActionsGrid
        orderId={+orderId!}
        visibleColumns={["action", "processInstanceId", "folio", "workflowName", "appliedBy", "createdOn", "status"]}
      />
    </div>
  );
};

export default SubProceduresTab;
