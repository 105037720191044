import Dropdown from "components/blocks/Dropdown";
import FormInput from "components/blocks/FormInput";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useEffect, useState } from "react";
import Modal from "components/blocks/Modal";
import { useTranslation } from "react-i18next";
import { ExaminerRewardsService } from "services/examinerRewards";
import { useNotification } from "hooks/useNotification";
import { z } from "zod";
import { ExaminerRewardStatus } from "services/examinerRewards/Models/ExaminerRewardsTypes";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import FileUploadWithPreview, { SelectedFilesType } from "components/blocks/FileUploadWithPreview";

type ExaminerRewardConfirmationModalProps = {
  isOpen: boolean;
  onClose: (refetch: boolean) => void;
  data: {
    amount: number;
    status: keyof typeof ExaminerRewardStatus;
    examinerName: string;
    examinerRewardId: number;
  };
};

const updateExaminerRewardSchema = z.object({
  status: z.enum(["Paid", "NotPaid", "Reject", "Pending"], { message: "Common:fieldRequired" }),
  rewardAmount: z
    .number()
    .min(0, { message: "Common:fieldRequired" })
    .max(999999, { message: "pleaseCheckRewardAmount" }),
  // TODO: validate if the attachment is required when the status = Paid
  fileId: z.string().uuid({ message: "Common:invalidFileId" }).nullish(),
});

const ExaminerRewardConfirmationModal = ({ isOpen, onClose, data }: ExaminerRewardConfirmationModalProps) => {
  const { t } = useTranslation("ExaminerRewards");
  const [confirmationModalLoading, setConfirmationModalLoading] = useState(false);
  const [files, setFiles] = useState<SelectedFilesType[]>([]);
  const { showNotification } = useNotification();

  useEffect(() => {
    if (!isOpen || confirmationModalLoading || !data.examinerRewardId) {
      return;
    }

    const fetchExaminerReward = async () => {
      try {
        setConfirmationModalLoading(true);
        const response = await ExaminerRewardsService.getExaminersRewardById(data.examinerRewardId);
        if (!response.hasError) {
          if (response.data.file) {
            setValue("fileId", response.data.file.id);
            setFiles([
              {
                file: new File([""], response.data.file.name || `attachment.${response.data.file.extension}`, {
                  type: response.data.file.type || "",
                }),
                fileName: response.data.file.name || `attachment.${response.data.file.extension}`,
                uuid: response.data.file.id,
                size: response.data.file.size,
              },
            ]);
          }
        }
      } catch (error) {
        showNotification({
          type: "error",
          description: t("Common:internalServerError"),
        });
      } finally {
        setConfirmationModalLoading(false);
      }
    };

    fetchExaminerReward();
  }, [data.examinerRewardId]);

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
  } = useForm<z.infer<typeof updateExaminerRewardSchema>>({
    reValidateMode: "onBlur",
    resolver: zodResolver(updateExaminerRewardSchema),
    values: {
      status: data.status,
      rewardAmount: data.amount,
      fileId: null,
    },
  });

  const onModalClose = (refetch: boolean) => {
    reset();
    setFiles([]);
    onClose(refetch);
  };

  const onConfirm = async (formValues: z.infer<typeof updateExaminerRewardSchema>) => {
    try {
      setConfirmationModalLoading(true);
      const response = await ExaminerRewardsService.updateExaminerReward(data.examinerRewardId, formValues);
      if (!response.hasError) {
        onModalClose(true);
        showNotification({
          type: "success",
          description: t("examinerRewardUpdatedSuccessfully"),
        });
      } else {
        showNotification({
          type: "error",
          description: t(response.description),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
    } finally {
      setConfirmationModalLoading(false);
    }
  };

  const onReject = async () => {
    try {
      setConfirmationModalLoading(true);
      const response = await ExaminerRewardsService.rejectExaminerReward(data.examinerRewardId);
      if (!response.hasError) {
        onModalClose(true);
        showNotification({
          type: "success",
          description: t("examinerRewardRejectedSuccessfully"),
        });
      } else {
        showNotification({
          type: "error",
          description: t(response.description),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
    } finally {
      setConfirmationModalLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose(false)}
      title={data.examinerName || t("examinerRewardEdit")}
      size="lg"
      containerClassName="bg-white"
      bodyMargin="my-1"
      titleClassName="fs-6"
    >
      <form onSubmit={handleSubmit(onConfirm)}>
        <div className="d-flex flex-column gap-3 position-relative">
          <LoadingOverlay visible={confirmationModalLoading} />
          <div className="divider" />

          <div className="d-flex gap-3">
            <FormInput
              wrapperClassName="flex-1"
              label={t("amount")}
              leftElement={<span className="text-primary">{t("riyal")}</span>}
              value={watch("rewardAmount")}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "" || isNaN(+value)) {
                  setValue("rewardAmount", 0);
                } else {
                  setValue("rewardAmount", +value);
                  setError("rewardAmount", { message: +value > 999999 ? "pleaseCheckRewardAmount" : "" });
                }
              }}
              error={t(errors.rewardAmount?.message || "")}
            />
            <Dropdown
              wrapperClassName="flex-1"
              label={t("status")}
              data={[
                { id: "Paid", value: t("paid") },
                { id: "NotPaid", value: t("notpaid") },
                { id: "Pending", value: t("pending") },
              ]}
              value={watch("status")}
              onChange={(value, item) => {
                if (value) {
                  setValue("status", item.id as keyof typeof ExaminerRewardStatus);
                  setError("status", { message: "" });
                } else {
                  setError("status", { message: "Common:fieldRequired" });
                }
              }}
              error={t(errors.status?.message || "")}
            />
          </div>
          <FileUploadWithPreview
            maxNumberOfFiles={1}
            formProps={{
              clickHereForLabel: t("toAddBillOfExchange"),
            }}
            files={files}
            setFiles={(files: SelectedFilesType[]) => {
              if (files && files.length > 0) {
                setValue("fileId", files[0].uuid);
              } else {
                setValue("fileId", null);
              }

              setFiles(files);
              setError("fileId", { message: "" });
            }}
          />
          {errors.fileId && <span className="text-danger text-center">{t(errors.fileId?.message || "")}</span>}

          <div className="divider" />

          <div className="d-flex gap-2 justify-content-center mt-2">
            <button className="p-10-56P btn btn-primary" type="submit">
              {t("send")}
            </button>
            <button className="p-10-56P btn btn-outline-danger" type="button" onClick={onReject}>
              {t("rejectRewardConfirmation")}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ExaminerRewardConfirmationModal;
