import { zodResolver } from "@hookform/resolvers/zod";
import { SubmittedExaminerOrderEvaluationFormRequest } from "services/examiners/Model/ExaminerTypes";
import { z } from "zod";

export type SubmittedExaminerOrderEvaluationFormRequestFormValueType = SubmittedExaminerOrderEvaluationFormRequest & {
  isFormValueReady: boolean;
};

export const getCreateExaminerEvaluationSchema = (t: any) => {
  const schema = z.object({
    serialGuid: z.string({ message: t("Common:invalidValue") }).optional(),
    actionName: z.string({ message: t("Common:invalidValue") }).optional(),
    evaluationPercentage: z.number().min(1, t("resultShouldBeBetween100&1")).max(100, t("resultShouldBeBetween100&1")),
    recommendations: z.string().optional(),
    formBuilderValue: z.string().optional(),
    filesIds: z.array(z.string()).optional(),
    criteriaValuesRequest: z.array(
      z.object({
        criteriaId: z.number().min(1, t("Common:invalidValue")),
        value: z.number().max(100, t("resultShouldBeBetween100&1")).min(1, t("resultShouldBeBetween100&1")),
        notes: z.string().optional(),
        id: z.number().optional(),
      }),
    ),
    isFormValueReady: z
      .boolean({ message: t("pleaseFillAllRequiredFields") })
      .refine((value) => value, { message: t("pleaseFillAllRequiredFields") }),
  }) as z.ZodType<SubmittedExaminerOrderEvaluationFormRequestFormValueType>;

  return zodResolver(schema);
};
