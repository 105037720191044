import { HttpClient } from "helpers";
import {
  LoginRequestType,
  LoginResponseType,
  RefereeSignUpRequestType,
  ResetPasswordRequestType,
  ResetPasswordResponseType,
  SignupResponseType,
  UserInformationResult,
  SendInvitationsRequest,
  UserResult,
  UpdateMyProfileRequest,
} from "./models/AccountType";
import { BaseResponseType, DataGridRequest, PagedResponseType } from "services/shared-models/sharedTypes";

export const AccountService = {
  login: async (data: LoginRequestType) => {
    return await HttpClient.post<BaseResponseType<LoginResponseType>>("/api/Account", data);
  },
  signUp: async (data: RefereeSignUpRequestType) => {
    return await HttpClient.post<BaseResponseType<SignupResponseType>>("/api/Account/signup", data);
  },
  setPassword: async (data: ResetPasswordRequestType) => {
    return await HttpClient.post<BaseResponseType<ResetPasswordResponseType>>("/api/Account/SetPassword", data);
  },
  getUserInfoAsync: async () => {
    return await HttpClient.get<BaseResponseType<UserResult>>("/api/Account/GetUserInfoAsync");
  },
  getUserByIdAsync: async (id: string) => {
    return await HttpClient.get<BaseResponseType<UserInformationResult>>(`/api/Account/getUserByIdAsync/${id}`);
  },
  getUsersDropdown: async (req: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<UserResult>>("/api/Account/getUsersDropdown", req);
  },
  blockUser: async (id: string) => {
    return await HttpClient.put<BaseResponseType<any>>(`/api/Account/BlockUser/${id}`);
  },
  unblockUser: async (id: string) => {
    return await HttpClient.put<BaseResponseType<any>>(`/api/Account/UnBlockUser/${id}`);
  },
  sendInvitations: async (data: SendInvitationsRequest) => {
    return await HttpClient.post<BaseResponseType<string[]>>("/api/Account/SendInvitations", data);
  },
  updateMyProfile: async (id: string, data: UpdateMyProfileRequest) => {
    return await HttpClient.put<BaseResponseType<UserResult>>(`/api/Account/UpdateMyProfile/${id}`, data);
  },
  checkUserBankInformation: async () => {
    return await HttpClient.get<BaseResponseType<boolean>>("/api/Account/CheckUserBankInformation");
  },
  validateUsernameAndEmailAlreadyExists: async (value: string) => {
    return await HttpClient.get<BaseResponseType<boolean>>(
      `/api/Account/CheckUserByUsername/${encodeURIComponent(value)}`,
      {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "0",
          "Content-Type": "application/json",
        },
      },
    );
  },
};
