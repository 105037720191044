import DatePicker from "components/blocks/DatePicker";
import Modal from "components/blocks/Modal";
import useMutation from "hooks/useMutation";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { ExaminersService } from "services/examiners";
import { ExaminerListResult, ReferralOfExaminersRequest } from "services/examiners/Model/ExaminerTypes";
import { getReferExaminerSchema } from "./referExaminer.schema";
import { useTranslation } from "react-i18next";

const ReferExaminersModal = ({
  isOpen,
  alreadyReferredExaminerId,
  onClose,
  checkedExaminerToReferer,
}: {
  isOpen: boolean;
  alreadyReferredExaminerId?: number;
  onClose: (shouldUpdateExaminerTable?: boolean) => void;
  checkedExaminerToReferer: ExaminerListResult[];
}) => {
  const { t } = useTranslation(["Orders"]);
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    reset,
    control,
  } = useForm<ReferralOfExaminersRequest>({
    defaultValues: {
      deadLine: new Date(),
      examinersIds: checkedExaminerToReferer.map((examiner) => examiner.id),
      notes: "",
    },
    resolver: getReferExaminerSchema(t),
  });

  useEffect(() => {
    if (isOpen) {
      reset({
        deadLine: new Date(),
        examinersIds: !!alreadyReferredExaminerId
          ? [alreadyReferredExaminerId]
          : checkedExaminerToReferer.map((examiner) => examiner.id),
        notes: "",
      });
    } else {
      reset({
        deadLine: new Date(),
        examinersIds: [],
        notes: "",
      });
    }
  }, [isOpen]);

  const { loading, mutateAsync } = useMutation({
    queryFn: async (request: ReferralOfExaminersRequest) => {
      return await ExaminersService.referralOfExaminers(request);
    },
  });

  const { loading: isAddAndReferralLoading, mutateAsync: addAndReferralExaminer } = useMutation({
    queryFn: async (request: ReferralOfExaminersRequest) => {
      return await ExaminersService.addExaminerAndAssign(request);
    },
  });

  const onReferExaminers = async (request: ReferralOfExaminersRequest) => {
    let response;
    if (!!alreadyReferredExaminerId) {
      response = await addAndReferralExaminer(request);
    } else {
      response = await mutateAsync(request);
    }

    if (!response?.hasError) {
      onClose(true);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("referExaminers")}
      containerClassName="bg-white p-4"
      bodyMargin="p-0"
      size="lg"
      titleClassName="fs-6 text-dark"
    >
      <form noValidate onSubmit={handleSubmit(onReferExaminers)} className="d-flex flex-column gap-2">
        <div className="divider"></div>
        <Controller
          name="deadLine"
          control={control}
          render={({ field }) => (
            <DatePicker
              label={t("deadLine")}
              labelClassName="fw-bold text-dark fs-12px mb-0"
              placeholder="../../...."
              wrapperClassName="w-50"
              value={field.value}
              error={errors.deadLine?.message}
              onChange={(date) => {
                if (date) {
                  setValue("deadLine", date);
                }
              }}
            />
          )}
        />

        <div>
          <label className="fw-bold text-dark fs-12px  mb-1">{t("notes")}</label>
          <textarea
            className="form-control"
            placeholder={t("notes")}
            {...register("notes")}
            rows={5}
            style={{ resize: "none" }}
          />
        </div>

        <div className="d-flex justify-content-center gap-2 mt-2">
          <button
            type="button"
            className="btn border me-2 p-10-32P rounded-3 "
            onClick={() => onClose(false)}
            disabled={loading || isAddAndReferralLoading}
          >
            {t("Common:cancel")}
          </button>
          <button className="btn  btn-primary border me-2 p-10-32P rounded-3" type="submit">
            {!loading && !isAddAndReferralLoading ? t("Common:send") : <div className="custom-loader"></div>}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ReferExaminersModal;
