import Modal, { ModalProps } from "components/blocks/Modal";
import { useState } from "react";
import { FormCheck } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ReturnOrderContractModal: React.FC<
  ModalProps & {
    onSubmit: (notes: string, isReturnToResearcher: boolean) => Promise<void>;
    isSubmitting: boolean;
  }
> = (props) => {
  const { isOpen, onClose, onSubmit, isSubmitting } = props;
  const { t } = useTranslation("Orders");
  const [notes, setNotes] = useState("");
  const [selectedReturnDestination, setSelectedReturnDestination] = useState<"Researcher" | "FinancialOfficer">(
    "Researcher",
  );

  const onCancel = () => {
    onClose?.();
  };

  return (
    <Modal
      containerClassName="bg-white pb-2"
      isOpen={isOpen}
      onClose={onClose}
      title={t("returnOrderToResearcher")}
      withHeaderBorder
      size="lg"
      titleClassName="fs-6 text-dark"
      bodyMargin="py-3"
    >
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-4">
          <div className="d-flex gap-2">
            <FormCheck
              id="FinancialOfficer"
              type="radio"
              disabled
              checked={selectedReturnDestination === "FinancialOfficer"}
              onChange={() => {
                setSelectedReturnDestination("FinancialOfficer");
              }}
            />
            <label className="form-label" htmlFor="reject-radio">
              {t("returnToFinancialOfficer")}
            </label>
          </div>

          <div className="d-flex gap-2">
            <FormCheck
              id="Researcher"
              type="radio"
              checked={selectedReturnDestination === "Researcher"}
              onChange={() => {
                setSelectedReturnDestination("Researcher");
              }}
            />
            <label className="form-label" htmlFor="accept-radio">
              {t("returnToResearcher")}
            </label>
          </div>
        </div>
        <label className="form-label mt-2">{t("Common:notes")}</label>
        <textarea
          className={`form-control`}
          rows={5}
          placeholder={t("Common:notes")}
          onChange={(e) => setNotes(e.target.value)}
          style={{ resize: "none" }}
        />

        <div className="divider mt-3"></div>

        <div className="d-flex justify-content-center gap-2 w-100">
          <button type="button" className="btn text-dark rounded-3 p-10-32P" onClick={onCancel}>
            {t("Common:cancel")}
          </button>
          <button
            type="button"
            className="btn btn-primary rounded-3 p-10-32P"
            disabled={!notes.length || isSubmitting}
            onClick={async () => {
              if (!notes.length) return;

              await onSubmit(notes, selectedReturnDestination === "Researcher");
            }}
          >
            {!isSubmitting ? t("returnOrder") : <div className="custom-loader"></div>}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ReturnOrderContractModal;
