import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import DatePicker from "components/blocks/DatePicker";
import Dropdown from "components/blocks/Dropdown";
import FileInput from "components/blocks/FileInput";
import FilePreview from "components/blocks/FileInput/FilePreview";
import FormInput from "components/blocks/FormInput";
import IconButton from "components/blocks/IconButton";
import Switch from "components/blocks/Switch";
import RichTextEditor from "components/RichTextEditor";
import { useCookieContext } from "contexts";
import { useState } from "react";
import ReactSelect from "react-select";

const SamplesView: React.FC = () => {
  const { setCookie, localization } = useCookieContext();
  const [file, setFile] = useState<File | undefined>(undefined);

  const [editorReadOnly, setEditorReadOnly] = useState(false);
  const onEditorContentChange = (data: string) => {
    console.log(data);
  };

  return (
    <div>
      <h1>Samples Page</h1>

      <h3>Add Program</h3>
      <p>Here I drop samples from created components till we start the actual implementation</p>

      <div className="border-bottom my-2" />

      <h3>Toggle Locale - requires page reload for now</h3>
      <button
        className="btn btn-primary mb-2"
        onClick={() => {
          const isEn = JSON.parse(localization || "{}").CurrentLanguage === "en";
          setCookie("localization", JSON.stringify({ CurrentLanguage: isEn ? "ar" : "en", isRTL: isEn }));
        }}
      >
        Toggle Locale
      </button>
      <div className="border-bottom my-2" />

      <h3>CKEditor</h3>
      <button className="btn btn-primary mb-2" onClick={() => setEditorReadOnly(!editorReadOnly)}>
        Toggle Read Only
      </button>
      <RichTextEditor initialData="" isReadOnly={editorReadOnly} onChange={onEditorContentChange} />

      <div className="border-bottom my-2" />

      <h3>React Select</h3>
      <div className="d-flex">
        <div>
          <label className="mb-1">drop down</label>
          <ReactSelect
            placeholder="Select Option"
            styles={{
              // make it exactly like bootstrap 5
              control: (styles) => ({ ...styles, borderColor: "#e5e5e5", padding: "0.3rem 0.75rem" }),
              valueContainer: (styles) => ({ ...styles, padding: "0" }),
              input: (styles) => ({ ...styles, margin: "0" }),
              indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
              dropdownIndicator: (styles) => ({ ...styles, padding: "0.3rem 0.0rem" }),
              singleValue: (styles) => ({ ...styles, padding: "0.3rem 0.5rem" }),
              placeholder: (styles) => ({ ...styles, padding: "0.3rem 0.75rem" }),
            }}
            options={[
              { value: "Option 1", label: "Option 1" },
              { value: "Option 2", label: "Option 2" },
              { value: "Option 3", label: "Option 3" },
            ]}
            onChange={(selectedOption) => {
              console.log(selectedOption);
            }}
          />
        </div>
        <Dropdown
          label="Dropdown"
          value={"1"}
          onChange={(value) => {
            console.log(value);
          }}
          data={[
            { value: "Option 1", id: "1" },
            { value: "Option 2", id: "2" },
            { value: "Option 3", id: "3" },
          ]}
        />
      </div>

      <div className="border-bottom my-2" />

      <h3>Accordion</h3>
      <Accordion>
        <AccordionItem initiallyOpen elementId="1" title="Accordion #1">
          Content of Accordion #1.
        </AccordionItem>
        <AccordionItem elementId="2" title="Accordion #2">
          Content of Accordion #2.
        </AccordionItem>
      </Accordion>

      <div className="border-bottom my-2" />

      <h3>Elements</h3>
      <div className="d-flex align-items-end gap-2">
        <DatePicker
          wrapperClassName="flex-grow-1"
          label="Picker"
          onChange={(date) => {
            console.log(date);
          }}
          value=""
        />
        <Dropdown
          wrapperClassName="flex-grow-1"
          label="Options"
          value={"1"}
          onChange={(value) => {
            console.log(value);
          }}
          data={[
            { value: "Option 1", id: "1" },
            { value: "Option 2", id: "2" },
            { value: "Option 3", id: "3" },
          ]}
        />
        <FormInput iconClassName="icon-add-refree" leftElement={<label className="text-primary">ريال</label>} />
        <FormInput
          iconClassName="icon-add-refree"
          label="With Label"
          leftElement={<span className="icon-dashboard fs-4" />}
        />
      </div>

      <div className="d-flex align-items-end gap-2 my-4">
        <IconButton icon="icon-add-refree" variant="danger" size="sm" />
        <IconButton icon="icon-dashboard" variant="primary" />
        <IconButton icon="icon-calendar" variant="dark" size="lg" />
        <IconButton icon="icon-drag" variant="success" />
        <IconButton icon="icon-add-time" variant="secondary" size="sm" />
        <IconButton icon="icon-edit" size="sm" />
      </div>

      <div className="d-flex align-items-end gap-2 my-4">
        <Switch checked onChange={(checked) => {}} />
        <Switch checked={false} onChange={(checked) => {}} />
        <Switch checked onChange={(checked) => {}} label="عربي" />
        <Switch checked={false} onChange={(checked) => {}} label="English" />
      </div>

      <FileInput
        label="File Input"
        clickHereForLabel="لرفع ملف"
        onChange={(file) => {
          setFile(file?.[0]);
        }}
      />
      <div className="d-flex gap-2 flex-wrap">
        <FilePreview
          file={file}
          onShow={(file) => {
            console.log(file);
          }}
          onDelete={(file) => {
            console.log(file);
          }}
        />
        <FilePreview
          file={new File([""], "file.txt")}
          onShow={(file) => {
            console.log(file);
          }}
          onDelete={(file) => {
            console.log(file);
          }}
        />
      </div>
    </div>
  );
};

export default SamplesView;
