import {useTranslation} from "react-i18next";
import ManImage from "../../../../assets/image/Header/man.png";
import ArrowIcon from "../../../../assets/image/Icons/arrow.svg";
import {useCookieContext} from "contexts";
import {useState} from "react";
import ActionModal from "components/blocks/Modal/ActionModal";
import {Link} from "react-router-dom";
import OutOfOfficeModal from "./OutOfOfficeModal";
import {LoginResult} from "../../../../services/users/models/loginResult";

const SettingsDropDown = ({userName, userId}: { userName?: string; userId?: string }) => {
    const {t} = useTranslation("Shared");
    const {isAr, isHijri, setCookie, removeCookie, userSession} = useCookieContext();
    const [isActive, setIsActive] = useState(false);
    const [isSignOutModalOpened, setIsSignOutModalOpened] = useState(false);
    const [isOutOfOfficeModalOpened, setIsOutOfOfficeModalOpened] = useState(false);
    const parsedUserSession: LoginResult | null = userSession ? (JSON.parse(userSession) as LoginResult) : null;
    const handleHeaderClick = () => {
        setIsActive(!isActive);
    };

    const preventClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event.stopPropagation();
    };

    const changeLanguage = (lang: string) => {
        setCookie("localization", JSON.stringify({CurrentLanguage: lang, isRTL: lang === "ar"}));
    };

    const changeDate = (isHijri: boolean) => {
        setCookie("isHijri", isHijri.toString());
    };

    const onLogout = () => {
        removeCookie("Session");
    };

    return (
        <div className="left-section position-relative">
            <div className="dropdown cursor-pointer">
                <div className="profile-link" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="d-flex gap-2 align-items-center">
                        <div>
                            <img src={ManImage} alt="Profile Picture" className="profile-pic"/>
                        </div>
                        <div className="d-none d-sm-block profile-info">
                            <span className="profile-name">{userName}</span>
                        </div>
                    </div>
                    <div className="d-none d-sm-block">
                        <img src={ArrowIcon} alt="arrow"/>
                    </div>
                </div>
                <ul className="dropdown-menu rounded-3 bg-white w-100 text-dark profile-menu">
                    <li className="dropdown-header d-flex flex-column align-items-start">
                        <h6 className="p-0 m-0 fw-bold" style={{whiteSpace: "normal"}}>
                            {userName}
                        </h6>
                        <span className="profile-role"> الباحث</span>
                    </li>
                    <li className="dropdown-divider"></li>

                    <li>
                        <Link className="dropdown-item dropdown-item-padding "
                              to={`/home/UserProfile?userId=${userId}`}>
                            <div className="d-flex gap-1">
                                <i className="icon-user-profile"></i>
                                <span>{t("UserProfile")}</span>
                            </div>
                        </Link>
                    </li>
                    <li onClick={preventClose}>
                        {!parsedUserSession?.isOutOfOffice ? <div className="custom-accordion">
                            <div className={`accordion-item ${isActive ? "active" : ""}`}>
                                <div
                                    className="accordion-header d-flex justify-content-between dropdown-item dropdown-item-padding"
                                    onClick={handleHeaderClick}
                                >
                                    <div className="d-flex gap-1">
                                        <i className="icon-yes text-success"></i>
                                        <span>{t("Available")}</span>
                                    </div>
                                    <i className="icon-arrow-down"></i>
                                </div>
                                <div className="accordion-body">
                                    <a
                                        className="dropdown-item dropdown-item-padding "
                                        onClick={() => {
                                            setIsOutOfOfficeModalOpened(true);
                                        }}
                                    >
                                        <div className="d-flex gap-1">
                                            <i className="icon-clock text-warning"></i>
                                            <span>{t("OutOfOffice")}</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> : <div className="accordion-body">
                            <a
                                className="dropdown-item dropdown-item-padding "
                                onClick={() => {
                                    setIsOutOfOfficeModalOpened(true);
                                }}
                            >
                                <div className="d-flex gap-1">
                                    <i className="icon-clock text-warning"></i>
                                    <span>{t("OutOfOffice")}</span>
                                </div>
                            </a>
                        </div>}
                    </li>
                    <li>
                        <a className="dropdown-item dropdown-item-padding " href="#">
                            <div className="d-flex gap-1">
                                <i className="icon-help"></i>
                                <span>{t("Help")}</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <div className="dropdown-item-padding">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex gap-1 align-items-center">
                                    <i className="icon-change-lang"></i>
                                    <span>{t("Language")}</span>
                                </div>
                                <div className="d-flex gap-0">
                                    <button
                                        className={`toggle-btn btn btn-${isAr ? "primary" : "light"} fs-14px`}
                                        onClick={() => changeLanguage("ar")}
                                    >
                                        العربية
                                    </button>
                                    <button
                                        className={`toggle-btn btn btn-${!isAr ? "primary" : "light"} fs-14px`}
                                        onClick={() => changeLanguage("en")}
                                    >
                                        English
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="dropdown-item-padding">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex gap-1 align-items-center">
                                    <i className="icon-calendar"></i>
                                    <span>{t("Date")}</span>
                                </div>
                                <div className="d-flex gap-0">
                                    <button
                                        className={`toggle-btn btn btn-${!isHijri ? "primary" : "light"} fs-14px`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            changeDate(false);
                                        }}
                                    >
                                        {t("Gregorian")}
                                    </button>
                                    <button
                                        className={`toggle-btn btn btn-${isHijri ? "primary" : "light"} fs-14px`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            changeDate(true);
                                        }}
                                    >
                                        {t("Hijri")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dropdown-divider"></li>
                    <li onClick={() => setIsSignOutModalOpened(true)}>
                        <a className="dropdown-item dropdown-item-padding  text-danger">
                            <div className="d-flex gap-1">
                                <i className="icon-signout"></i>
                                <span>{t("SignOut")}</span>
                            </div>
                        </a>
                    </li>
                </ul>
                <ActionModal
                    actionIcon="icon-signout"
                    headerMsg={t("logoutMessagePart1")}
                    subMsg={t("logoutMessagePart2")}
                    isOpen={isSignOutModalOpened}
                    onClose={() => {
                        setIsSignOutModalOpened(false);
                    }}
                    onActionConfirm={onLogout}
                    iconColor="white"
                    iconWrapperColor="danger"
                    confirmBtnText={t("confirmSignOut")}
                />
                <OutOfOfficeModal
                    isOpen={isOutOfOfficeModalOpened}
                    onClose={() => {
                        setIsOutOfOfficeModalOpened(false);
                    }}
                />
            </div>
        </div>
    );
};

export default SettingsDropDown;
