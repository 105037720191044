import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ClassicEditor, Bold, Essentials, Italic, Paragraph, Undo, Heading, Font, Alignment } from "ckeditor5";

import "ckeditor5/ckeditor5.css";
import { useEffect, useRef } from "react";
// import "ckeditor5-premium-features/ckeditor5-premium-features.css";

type RichTextEditorProps = {
  initialData: string;
  onChange?: (data: string) => void;
  isReadOnly?: boolean;
};

const RichTextEditor = (props: RichTextEditorProps) => {
  const { initialData, onChange, isReadOnly } = props;
  const editorRef = useRef<CKEditor<ClassicEditor>>(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.forceUpdate();
    }
  }, [initialData]);

  return (
    <CKEditor
      ref={editorRef}
      disabled={isReadOnly}
      editor={ClassicEditor}
      onChange={(event, editor) => {
        const editorData = editor.getData();
        onChange && onChange(editorData);
      }}
      config={{
        toolbar: {
          items: [
            "undo",
            "redo",
            "|",
            "heading",
            "bold",
            "italic",
            "fontSize",
            "alignment",
            "|",
            "bulletedList",
            "numberedList",
          ],
        },
        plugins: [Bold, Essentials, Italic, Paragraph, Undo, Heading, Font, Alignment],
        initialData: initialData,
      }}
      onReady={(editor) => {
        const toolbarElement = editor.ui.view.toolbar.element;
        const stickyPanel = editor.ui.view.stickyPanel.element;
        const contentElement = editor.ui.view.editable.element;
        if (toolbarElement === null || stickyPanel === null || contentElement === null) return;

        if (isReadOnly) {
          toolbarElement.style.display = "none";
          stickyPanel.style.display = "none";
          contentElement.style.border = "none";
        }

        editor.on("change:isReadOnly", (evt, propertyName, isReadOnly) => {
          if (isReadOnly) {
            toolbarElement.style.display = "none";
          } else {
            toolbarElement.style.display = "flex";
          }
        });
      }}
    />
  );
};

export default RichTextEditor;
