import FileInput from "components/blocks/FileInput";
import FilePreview from "components/blocks/FileInput/FilePreview";
import useMutation from "hooks/useMutation";
import { useNotification } from "hooks/useNotification";
import { useTranslation } from "react-i18next";
import { FileService } from "services/fileService";
import { FileUploadResponse } from "services/shared-models/sharedTypes";

export type SelectedFilesType = FileUploadResponse & { file: File; size?: string };

const FileUploadWithPreview = ({
  maxNumberOfFiles,
  files,
  setFiles,
  formProps = {},
  onChange,
  label,
  clickHereForLabel,
  error,
}: {
  maxNumberOfFiles?: number;
  formProps?: any;
  files: SelectedFilesType[];
  setFiles: (files: SelectedFilesType[]) => void;
  onChange?: (files: SelectedFilesType[]) => void;
  label?: string;
  clickHereForLabel?: string;
  error?: string;
}) => {
  const { t } = useTranslation(["Orders", "Common"]);
  const { showNotification } = useNotification();

  const { mutateAsync: uploadFileAsync, loading: fileUploadLoading } = useMutation({
    queryFn: async (values: File) => {
      return await FileService.uploadFile(values);
    },
  });

  const { mutateAsync: fileDownload, loading: fileDownloadLoading } = useMutation({
    queryFn: async (id: string) => {
      await FileService.downloadFile(id);
      return { hasError: false, data: null, description: "", code: 200 };
    },
  });

  const onFileUpload = async (file: File) => {
    if (maxNumberOfFiles && files.length >= maxNumberOfFiles) {
      showNotification({
        description: t("Common:maxNumberOfFilesReached", { maxNumberOfFiles }),
        type: "warning",
      });
      return;
    }

    const response = await uploadFileAsync(file);
    if (response && !response.hasError) {
      const newFiles = [
        ...files,
        {
          fileName: response.data!.fileName,
          uuid: response.data!.uuid,
          file,
          size: `${(file.size / 1024 / 1024).toFixed(2)}MB`,
        },
      ];
      setFiles(newFiles);
      onChange && onChange(newFiles);
    }
  };

  return (
    <div className="d-flex flex-column gap-1 flex-2">
      <FileInput
        labelClassName="fw-bold"
        label={label ? label : t("attachments")}
        clickHereForLabel={clickHereForLabel ? clickHereForLabel : t("Common:toAttachFile")}
        containerWrapperClassName="flex-grow-1"
        {...formProps}
        onChange={(files) => {
          files && onFileUpload(files[0]);
        }}
      />
      <div className="d-flex flex-wrap gap-1 align-items-center ">
        {fileUploadLoading && <div className="custom-loader"></div>}
        {files.map((file) => (
          <FilePreview
            key={file.uuid}
            loading={fileDownloadLoading}
            onDelete={() => {
              setFiles(files.filter((f) => f.uuid !== file.uuid));
            }}
            onShow={async () => {
              await fileDownload(file.uuid);
            }}
            file={file.file}
            fileSize={file.size}
          />
        ))}
      </div>
      {error && (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {error}
        </div>
      )}
    </div>
  );
};

export default FileUploadWithPreview;
