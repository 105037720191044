import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";

const PageHeaders = ({
  order,
  programOrderTaskState,
}: {
  order: OrderLocalItemResult | null;
  programOrderTaskState:
    | {
        actions: string[];
        canReturn: boolean;
        orderId: number;
      }
    | undefined;
}) => {
  const { t } = useTranslation("Orders");
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  return [
    {
      title: t("orderNumber"),
      value: order?.serialNumber,
      renderCondition: () => !!programOrderTaskState,
    },
    {
      title: t("appliedOn"),
      value: toSystemThemeDateFormat(order?.createdOn),
      renderCondition: () => !!programOrderTaskState,
    },
    {
      title: t("contractStartDate"),
      value: toSystemThemeDateFormat(order?.contractStartDate),
      renderCondition: () => !programOrderTaskState,
    },
    {
      title: t("researchesCenter"),
      value: order?.centreManagementDetail?.displayName
        ? order?.centreManagementDetail?.displayName
        : order?.centreManagement?.displayName,
    },
    {
      title: t("researchField"),
      value: order?.researchField?.displayName,
    },
    {
      title: t("subspecialty"),
      value: order?.subspecialty,
    },
    {
      title: t("funding"),
      value: order?.fundingDurationInMonths
        ? `${order?.fundingDurationInMonths} ${t("month")} - ${order?.fundingAmount} ${t("ryal")}`
        : "",
      renderCondition: () => !programOrderTaskState,
    },
    {
      title: t("fundingType"),
      value: order?.financingTypes?.financingTypesDetail.displayName,
      renderCondition: () => !programOrderTaskState,
    },
    {
      title: `${t("contract")}`,
      value: (
        <Link className="btn btn-link fw-bold p-0" to="#">
          {t("contract")}
        </Link>
      ) /* TODO: contract ID ?? */,
      renderCondition: () => !programOrderTaskState,
    },
    {
      title: `${t("fundingReceipt")}`,
      value: (
        <Link className="btn btn-link fw-bold p-0" to="#">
          {t("receipt")}
        </Link>
      ) /* TODO: funding sarf ID ?? */,
      renderCondition: () => !programOrderTaskState,
    },
  ];
};

export default PageHeaders;
