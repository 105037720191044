import PageWithTabs from "components/PageWithTabs";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { myWorkStatusClassNames } from "../MyUnsignedWorkGridView";
import AboutProgramTab from "views/home/programs/ViewProgram/tabs/AboutProgramTab";
import { useCallback, useEffect, useState } from "react";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import { useNotification } from "hooks/useNotification";
import { ProgramsService } from "services/programs";
import { useCookieContext } from "contexts";
import { OrderStatus } from "../../../../../shared/lookups";

const MyUnsignedWorkView = () => {
  // Hooks
  const { t } = useTranslation("Programs");
  const { isAr } = useCookieContext();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { id: programId } = useParams();
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  // State
  const [programData, setProgramData] = useState<ProgramByIDResponse>();
  const [loading, setLoading] = useState(false);

  // TODO: this logic is duplicated in the AboutProgramTab.tsx file
  // TODO: move this fetching into the AboutProgramTab.tsx file
  // Functions
  const fetchProgram = useCallback(async () => {
    if (!programId) {
      showNotification({ description: "programId is required", type: "error" });
      return navigate(-1);
    }
    try {
      setLoading(true);
      const response = await ProgramsService.getProgramById(+programId);
      if (response.hasError) {
        showNotification({ description: response.description, type: "error" });
        navigate(-1);
      } else {
        setProgramData(response.data);
      }
    } catch {
      showNotification({ description: t("Common:internalServerError"), type: "error" });
      // TODO: check if we need to navigate back
      //   navigate(-1);
    } finally {
      setLoading(false);
    }
  }, []);

  // Effects
  useEffect(() => {
    fetchProgram();
  }, [fetchProgram, programId, isAr]);

  const programName = programData
    ? `${programData?.programDetails.find((x) => x.locale === (isAr ? "Ar" : "En"))?.displayName}`
    : "";

  return (
    <>
      <LoadingOverlay visible={false} />
      <PageWithTabs
        title={<div className="d-flex gap-3">{programName}</div>}
        subTitle={`رقم البرنامج: ${programData?.serialNumber}`}
        pageHeaders={[
          {
            title: t("OrdersUnsignedWork:orderNumber"),
            value: `12345678`,
          },
          {
            title: t("OrdersUnsignedWork:submissionDate"),
            value: toSystemThemeDateFormat("2024-09-21T18:23:19.837Z"),
          },
          {
            title: t("OrdersUnsignedWork:researchCenter"),
            value: `مركز البحوث العلمية`,
          },
          {
            title: t("OrdersUnsignedWork:orderPhase"),
            value: `مرحلة الطلب`,
          },
        ]}
        actions={(selectedTabId) => {
          return (
            // TODO: get the status

            <span className={`badge rounded-4 ${myWorkStatusClassNames[OrderStatus.Inprogress] as string} py-2`}>
              {t(`Common:InProgress`)}
            </span>
          );
        }}
        tapContent={
          <>
            {loading && <LoadingOverlay visible={loading} />}
            {!loading && !!programData && <AboutProgramTab program={programData} />}
          </>
        }
      />
    </>
  );
};

export default MyUnsignedWorkView;
