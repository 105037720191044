import {iconClassNames} from "assets/style/theme/icon-classes";

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: keyof typeof iconClassNames;
    variant?:
        | "primary"
        | "secondary"
        | "success"
        | "danger"
        | "warning"
        | "info"
        | "light"
        | "dark"
        | "link"
        | "outline-primary"
        | "outline-secondary"
        | "outline-success"
        | "outline-danger"
        | "outline-warning"
        | "outline-info"
        | "outline-light"
        | "transparent"
        | "outline-dark";
    size?: "sm" | "md" | "lg" | "xl";
  title?: string;
    innerIconColor?:
        | "white"
        | "black"
        | "primary"
        | "secondary"
        | "success"
        | "danger"
        | "warning"
        | "info"
        | "light"
        | "dark";
    children?: undefined;
    fitToIconWidth?: boolean;
    iconPosition?: "start" | "end" | "center";
}

const sizeStyles = {
    sm: {btn: {minWidth: "2rem", height: "2rem"}, icon: {fontSize: "1rem"}},
    md: {btn: {minWidth: "2.5rem", height: "2.5rem"}, icon: {fontSize: "1.3rem"}},
    lg: {btn: {minWidth: "2.65rem", height: "2.65rem"}, icon: {fontSize: "1.4rem"}},
    xl: {btn: {minWidth: "2.9rem", height: "2.9rem"}, icon: {fontSize: "1.6rem"}},
    "icon-fit-sm": {btn: {}, icon: {fontSize: "1rem"}},
    "icon-fit-md": {btn: {}, icon: {fontSize: "1.3rem"}},
    "icon-fit-lg": {btn: {}, icon: {fontSize: "1.6rem"}},
    "icon-fit-xl": {btn: {}, icon: {fontSize: "2.9rem"}},
};

const IconButton: React.FC<IconButtonProps> = (props) => {
    const {
        icon,
        variant,
        size = "md",
        iconPosition = "center",
        fitToIconWidth,
        title = "",
        innerIconColor,
        style,
        className,
        ...rest
    } = props;

    const iconShapeColor = innerIconColor ?? (variant === "light" || variant?.includes("outline") ? "dark" : "light");

    const selectedSize = (fitToIconWidth ? `icon-fit-${size}` : size) as keyof typeof sizeStyles;

    return (
        <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={title}
            type="button"
            style={{
                ...style,
                ...sizeStyles[selectedSize].btn,
            }}
            className={`p-0 d-flex justify-content-${iconPosition} align-items-center btn btn-${variant} rounded-3 ${className}`}
            {...rest}
        >
            <i className={`${icon} text-${iconShapeColor}`} style={sizeStyles[selectedSize].icon}/>
        </button>
    );
};

export default IconButton;
