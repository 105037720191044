import { FileService } from "services/fileService";
import "./AttachmentCard.scss";
import LoadingOverlay from "../LoadingOverlay";
import useMutation from "hooks/useMutation";

type Attachment = {
  id: number | string;
  fileName: string;
  size: string | number;
  extension: string;
};

const extensionsMapper = {
  pdf: "pdf",
  doc: "doc",
  docx: "doc",
};

const AttachmentCard = ({ attachment }: { attachment: Attachment }) => {
  const { loading, mutateAsync } = useMutation({
    queryFn: async (id: string) => {
      await FileService.downloadFile(id);
      return { hasError: false, data: null, description: "", code: 200 };
    },
  });

  const onCardClick = async () => {
    await mutateAsync(attachment.id.toString());
  };

  const ext = attachment.extension.replaceAll(".", "");
  // split the last . only
  const fileName = attachment.fileName.includes(".")
    ? attachment.fileName.split(".").slice(0, -1).join(".")
    : attachment.fileName;

  return (
    <div
      key={attachment.id}
      role="button"
      className="attachment-card  rounded-4 p-3 d-flex gap-2 flex-column justify-content-center align-items-center position-relative user-select-none"
      onClick={onCardClick}
      style={{
        minHeight: "120px",
      }}
    >
      <LoadingOverlay visible={loading} />
      <i className={`fs-1 icon-${extensionsMapper[ext as keyof typeof extensionsMapper] ?? "pdf"}-big`} />
      {/* ?? "pdf" TODO: ask about allowed values */}
      <div className="d-flex flex-column align-items-center">
        <div
          className="fs-12px fw-bold text-truncate "
          style={{
            maxWidth: "100px",
            textAlign: "center",
          }}
        >
          {fileName}
        </div>
        <span className="mx-2 fs-12px text-gray">{attachment.size}</span>
      </div>
    </div>
  );
};

export default AttachmentCard;
