import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FormInput from "components/blocks/FormInput";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import LookupDropdown from "components/blocks/LookupDropdown";
import { useCookieContext } from "contexts";
import { useQuery } from "hooks/useQuery";
import { useTranslation } from "react-i18next";
import { AccountService } from "services/accountService";
import { CloseOrderFormProps } from "../../../ResearcherCloseRequestPage";

const BankingInformation = ({ closeOrderFormProps }: { closeOrderFormProps?: CloseOrderFormProps }) => {
  const { t } = useTranslation("UserProfile");
  const { userSession } = useCookieContext();

  const { data, loading } = useQuery({
    queryFn: async () => {
      const parsedSession = JSON.parse(userSession!);
      return await AccountService.getUserByIdAsync(parsedSession.id);
    },
    options: {
      enabled: !!userSession,
    },
  });

  return (
    <Accordion>
      <AccordionItem initiallyOpen elementId="BankingInfo" title={t("Orders:bankInformation")}>
        <div className="d-flex flex-wrap flex-column gap-2 position-relative">
          <LoadingOverlay visible={loading} />
          <div className="d-flex flex-wrap gap-2">
            <FormInput
              label={t("beneficiaryName")}
              wrapperClassName="flex-1"
              readOnly
              withAsterisk
              style={{ minWidth: "150px" }}
              value={data?.beneficiaryName}
            />
            <FormInput
              label={t("bankName")}
              wrapperClassName="flex-1"
              readOnly
              withAsterisk
              value={data?.bankName}
              style={{ minWidth: "150px" }}
            />
            <LookupDropdown
              label={t("bankAccountType")}
              wrapperClassName="flex-1"
              service="lookupService"
              endpoint="getAllAccountTypes"
              idValueKey="id"
              textValueKey="acountTypeDetail.displayName"
              noSelectionPlaceholder={t("bankAccountType")}
              readOnly
              withAsterisk
              value={data?.accountTypeId}
            />
            <FormInput
              label={t("bankAccountNumber")}
              wrapperClassName="flex-1"
              readOnly
              withAsterisk
              value={data?.accountNumber}
              style={{ minWidth: "150px" }}
            />
          </div>
          <div className="d-flex flex-wrap gap-2">
            <FormInput
              label={t("bankAccountIBAN")}
              wrapperClassName="flex-1"
              readOnly
              withAsterisk
              value={data?.ibanNumber}
              style={{ minWidth: "150px" }}
            />
            <FormInput
              label={t("SWIFT")}
              wrapperClassName="flex-1"
              readOnly
              withAsterisk
              value={data?.swift}
              style={{ minWidth: "150px" }}
            />
            <FormInput
              label={t("bankRoutingNumber")}
              wrapperClassName="flex-1"
              readOnly
              withAsterisk
              value={data?.bankRoutingNumber}
              style={{ minWidth: "150px" }}
            />
            <LookupDropdown
              label={t("currency")}
              wrapperClassName="flex-1"
              service="lookupService"
              endpoint="getAllCurrencies"
              idValueKey="id"
              textValueKey="currencyDetail.displayName"
              noSelectionPlaceholder={t("currency")}
              readOnly
              withAsterisk
              value={data?.currencyId}
            />
          </div>

          <FormInput
            label={t("Common:notes")}
            wrapperClassName="flex-1"
            onChange={(e) => {
              closeOrderFormProps?.setValue("notes", e.target.value);
              closeOrderFormProps?.setValue("workFlowTaskRequest.notes", e.target.value);
            }}
            value={closeOrderFormProps?.watch("notes")}
            error={closeOrderFormProps?.errors?.notes?.message}
          />
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default BankingInformation;
