import Dropdown from "components/blocks/Dropdown";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import useMutation from "hooks/useMutation";
import { useTranslation } from "react-i18next";
import { OrdersService } from "services/orders";
import {
  FinalCommitteeDecisionLookup,
  FinalCommitteeDecisionType,
  OrderLocalResult,
} from "services/orders/models/OrdersTypes";

const ResearcherTypeDropdown = ({ order }: { order: OrderLocalResult }) => {
  const { t } = useTranslation("Programs");

  const { loading, mutateAsync } = useMutation({
    queryFn: async (type: FinalCommitteeDecisionType) => {
      return await OrdersService.changeFinalCommitteeDecisionProgramOrderStatus(order.id, type);
    },
  });

  const onChange = async (value: number) => {
    await mutateAsync(value);
  };

  return (
    <div className="position-relative">
      <LoadingOverlay visible={loading} />
      <Dropdown
        data={[
          {
            id: FinalCommitteeDecisionLookup.Primary.id,
            value: t("primary"),
          },
          {
            id: FinalCommitteeDecisionLookup.Secondary.id,
            value: t("secondary"),
          },
        ]}
        onChange={(value) => onChange(+value)}
        noSelectionPlaceholder={t("pleaseSelectResearcherType")}
        defaultValue={
          order.finalCommitteeDecision === FinalCommitteeDecisionLookup.Secondary.label
            ? FinalCommitteeDecisionLookup.Secondary.id
            : order.finalCommitteeDecision === FinalCommitteeDecisionLookup.Primary.label
            ? FinalCommitteeDecisionLookup.Primary.id
            : ""
        }
      />
    </div>
  );
};

export default ResearcherTypeDropdown;
