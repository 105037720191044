import useMutation from "hooks/useMutation";
import { useState } from "react";
import { CloseOrderService } from "services/closeOrder";
import { WorkFlowTaskRequest } from "services/orders/models/OrdersTypes";
import { OrderUserTypes } from "..";
import { useNavigate } from "react-router-dom";

export const getCloseRequestActionsByUserType = (userType: string) => {
  const userCloseAction: {
    [key: string]: {
      return?: string;
      returnToTitleKey?: string;
      approve?: string;
      reject?: string;
      complete?: string;
    };
  } = {
    [OrderUserTypes.ResearchCenterManagerForCloseRequest]: {
      return: "Return to Researcher",
      approve: "Approve",
      reject: "Reject",
      returnToTitleKey: "researcher",
    },
    [OrderUserTypes.FundingUnitForCloseRequest]: {
      return: "Return to Center",
      approve: "Approve",
      returnToTitleKey: "researchCenterManager",
    },
    [OrderUserTypes.VPofDeanForCloseRequest]: {
      return: "Return to Fund Unit",
      approve: "Approve",
      returnToTitleKey: "fundingUnit",
    },
    [OrderUserTypes.FundFinanceForCloseRequest]: {
      return: "Return to VP",
      approve: "Approve",
      returnToTitleKey: "vpOfDean",
    },
    [OrderUserTypes.DeanOfScientificResearchForCloseRequest]: {
      return: "Return to Fund Finance",
      approve: "Approve",
      returnToTitleKey: "fundFinance",
    },
    [OrderUserTypes.FinanceDepartmentForCloseRequest]: {
      return: "Return to Dean",
      complete: "Complete",
      returnToTitleKey: "deanOfScientificResearch",
    },
    [OrderUserTypes.ResearcherSendCloseResponseAfterReturnRequest]: {
      complete: "Complete",
    },
  };

  return userCloseAction[userType as string];
};

export type ApproveByCenterManagerSubmitHandlerRef = {
  acceptProgramOrderUpdateByCenterManager: (request: WorkFlowTaskRequest) => Promise<any>;
};

const useCloseRequestActions = ({
  serialGuid,
  programOrderCloseId,
  userType,
  approveByCenterManagerSubmitHandlerRef,
}: {
  serialGuid: string;
  programOrderCloseId: number;
  userType?: keyof typeof OrderUserTypes;
  approveByCenterManagerSubmitHandlerRef?: React.MutableRefObject<ApproveByCenterManagerSubmitHandlerRef | null>;
}) => {
  const navigate = useNavigate();
  const [closeRequestModalProps, setCloseRequestModalProps] = useState({
    isOpen: false,
    actionType: "return" as "return" | "reject" | "complete",
  });

  const { mutateAsync, loading } = useMutation({
    queryFn: async ({
      id,
      request,
      type,
      receiptId,
    }: {
      id: number;
      request: WorkFlowTaskRequest;
      type: "return" | "approve" | "reject" | "complete" | "send";
      receiptId?: string;
    }) => {
      let res: any;
      if (type === "return") {
        res = await CloseOrderService.generalReturnOrderCloseRequest(id, request);
      } else if (type === "complete") {
        request.actionName = "Complete";
        res = await CloseOrderService.finalApprovedOrderCloseRequest(id, {
          workFlowTaskRequest: request,
          receiptId: receiptId!,
        });
      } else if (type === "approve") {
        if (userType === OrderUserTypes.ResearchCenterManagerForCloseRequest) {
          res = await approveByCenterManagerSubmitHandlerRef?.current?.acceptProgramOrderUpdateByCenterManager(request);
        } else {
          res = await CloseOrderService.generalApproveOrderCloseRequest(id, request);
        }
      } else {
        res = await CloseOrderService.generalRejectOrderCloseRequest(id, request);
      }

      if (res && !res.hasError) {
        navigate(`/home/tasks`);
      }

      return res;
    },
  });

  const userCloseActions = getCloseRequestActionsByUserType(userType ?? "");

  const onReturn = async (note: string) => {
    return await mutateAsync({
      id: programOrderCloseId,
      request: {
        actionName: userCloseActions.return!,
        notes: note,
        serialGuid: serialGuid,
      },
      type: "return",
    });
  };

  const onApprove = async () => {
    return await mutateAsync({
      id: programOrderCloseId,
      request: {
        actionName: userCloseActions.approve!,
        notes: "",
        serialGuid: serialGuid,
      },
      type: "approve",
    });
  };

  const onReject = async (note: string) => {
    return await mutateAsync({
      id: programOrderCloseId,
      request: {
        actionName: userCloseActions.reject!,
        notes: note,
        serialGuid: serialGuid,
      },
      type: "reject",
    });
  };

  const onComplete = async (note: string, receiptId: string) => {
    return await mutateAsync({
      id: programOrderCloseId,
      request: {
        actionName: userCloseActions.complete!,
        notes: note,
        serialGuid: serialGuid,
      },
      type: "complete",
      receiptId,
    });
  };

  return {
    onReturn,
    onApprove,
    onReject,
    onComplete,
    loading,
    closeRequestModalProps,
    setCloseRequestModalProps,
    userCloseActions,
  };
};

export default useCloseRequestActions;
