import RichTextEditor from "components/RichTextEditor";
import Modal from "components/blocks/Modal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOrderDetailsRequest } from "services/closeOrder/models";

const EditPdfTemplateModal = ({
  isOpen,
  onClose,
  onSaveClicked,
  details,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSaveClicked: (res: { ar: string; en: string }) => void;
  details: (CloseOrderDetailsRequest & {
    id: number;
  })[];
}) => {
  const { t } = useTranslation("Orders");
  const [currentTemplateValues, setCurrentTemplateValues] = useState({
    ar: details.find((item) => item.locale.toLowerCase() === "ar")?.templateContent ?? "",
    en: details.find((item) => item.locale.toLowerCase() === "en")?.templateContent ?? "",
  });

  const onArEditorContentChange = (text: string) => {
    setCurrentTemplateValues((prev) => ({ ...prev, ar: text }));
  };

  const onEnEditorContentChange = (text: string) => {
    setCurrentTemplateValues((prev) => ({ ...prev, en: text }));
  };

  useEffect(() => {
    setCurrentTemplateValues({
      ar: details.find((item) => item.locale.toLowerCase() === "ar")?.templateContent ?? "",
      en: details.find((item) => item.locale.toLowerCase() === "en")?.templateContent ?? "",
    });
  }, [details]);

  return (
    <Modal
      fullscreen
      isOpen={isOpen}
      onClose={onClose}
      title={t("editTemplate")}
      footer={
        <div className="d-flex justify-content-end gap-3">
          <button type="button" className="btn btn-secondary" onClick={onClose}>
            {t("Common:cancel")}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              onSaveClicked(currentTemplateValues);
            }}
          >
            {t("Common:save")}
          </button>
        </div>
      }
    >
      <div className="row">
        <div className="col-6">
          <h5>عربي</h5>
          <RichTextEditor initialData={currentTemplateValues.ar} onChange={onArEditorContentChange} />
        </div>
        <div className="col-6">
          <h5>English</h5>
          <RichTextEditor initialData={currentTemplateValues.en} onChange={onEnEditorContentChange} />
        </div>
      </div>
    </Modal>
  );
};

export default EditPdfTemplateModal;
