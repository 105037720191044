import LoadingOverlay from "components/blocks/LoadingOverlay";
import Grid from "components/GridView/components/Grid";
import { GridSchema } from "components/GridView/GridView.types";
import { useCookieContext } from "contexts";
import { useNotification } from "hooks/useNotification";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrdersService } from "services/orders";
import { OrderActionResult } from "services/orders/models/OrdersTypes";

type OrderActionColumn =
  | "notes"
  | "workflowName"
  | "action"
  | "processInstanceId"
  | "folio"
  | "appliedBy"
  | "createdOn"
  | "status";

type OrderActionsProps = {
  visibleColumns: OrderActionColumn[];
  orderId: number;
};

const OrderActionsGrid = ({ visibleColumns, orderId }: OrderActionsProps) => {
  const { t } = useTranslation("OrderActions");
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<OrderActionResult[]>([]);
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { isAr } = useCookieContext();

  useEffect(() => {
    if (orderId) fetchOrderActions();
  }, [isAr, orderId]);

  const fetchOrderActions = async () => {
    try {
      setLoading(true);
      const res = await OrdersService.getOrderActions(orderId);
      setData(res.data);
      setLoading(false);
    } catch (error) {
      showNotification({
        type: "error",
        description: t("errorGettingOrderActions"),
      });
    } finally {
      setLoading(false);
    }
  };

  const gridSchema = useMemo<GridSchema[]>(() => {
    return [
      {
        field: "notes",
        displayName: t("notes"),
        isHidden: !visibleColumns.includes("notes"),
      },
      {
        field: "action",
        displayName: t("action"),
        showOnMobile: true,
        render: (row: OrderActionResult) => t(row.action.toString()),
        isHidden: !visibleColumns.includes("action"),
      },
      {
        field: "processInstanceId",
        displayName: t("processInstanceId"),
        isHidden: !visibleColumns.includes("processInstanceId"),
      },
      {
        field: "folio",
        displayName: t("folio"),
        isHidden: !visibleColumns.includes("folio"),
      },
      {
        field: "workflowName",
        displayName: t("workflowName"),
        isHidden: !visibleColumns.includes("workflowName"),
      },
      {
        field: "appliedBy",
        displayName: t("appliedBy"),
        render: (row: OrderActionResult) => (isAr ? row.appliedBy.arabicName : row.appliedBy.englishName),
        isHidden: !visibleColumns.includes("appliedBy"),
      },
      {
        field: "createdOn",
        displayName: t("createdOn"),
        render: (row: OrderActionResult) => toSystemThemeDateFormat(row.createdOn),
        isHidden: !visibleColumns.includes("createdOn"),
      },
      {
        field: "status",
        displayName: t("status"),
        showOnMobile: true,
        render: (row: OrderActionResult) => {
          const statusClassName =
            row.status === "Completed"
              ? "bg-success bg-opacity-10 text-success"
              : "bg-primary bg-opacity-10 text-primary";
          return <span className={`badge rounded-4 ${statusClassName} py-2`}>{t(`Common:${row.status}`)}</span>;
        },
        isHidden: !visibleColumns.includes("status"),
      },
    ];
  }, [visibleColumns, t]);

  return (
    <div className="position-relative">
      {loading && <LoadingOverlay />}
      <Grid gridSchema={gridSchema} data={data} />
    </div>
  );
};

export default OrderActionsGrid;
