import { BaseResponseType, DataGridRequest, PagedResponseType } from "services/shared-models/sharedTypes";
import { EvaluationFormLocalItemResult, EvaluationFormLocalResult } from "./Models/evaluationFormsTypes";
import { HttpClient } from "helpers";
import { AxiosResponse } from "axios";
import { CreateEvaluationFormRequest } from "views/home/evaluationForms/FormView/evaluationForm.schema";

export const EvaluationFormsService = {
  getAllEvaluationForms: async (request: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<EvaluationFormLocalResult>>(
      `/api/EvaluationForm/GetAllEvaluationForms`,
      request,
    );
  },
  exportToExcel: async (request: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>(`/api/EvaluationForm/ExportEvaluationFormToExcel`, request, {
      responseType: "blob",
    });
  },
  getById: async (evaluationFormId: number) => {
    return await HttpClient.get<BaseResponseType<EvaluationFormLocalItemResult>>(
      `/api/EvaluationForm/GetEvaluationFormById/${evaluationFormId}`,
    );
  },
  create: async (request: CreateEvaluationFormRequest) => {
    return await HttpClient.post<BaseResponseType<any>>(`/api/EvaluationForm/CreateEvaluationForm`, request);
  },
  update: async (evaluationFormId: number, request: CreateEvaluationFormRequest) => {
    return await HttpClient.put<BaseResponseType<any>>(
      `/api/EvaluationForm/UpdateEvaluationForm/${evaluationFormId}`,
      request,
    );
  },
  delete: async (evaluationFormId: number) => {
    return await HttpClient.delete<BaseResponseType<any>>(
      `/api/EvaluationForm/DeleteEvaluationForm/${evaluationFormId}`,
    );
  },
  swapOrder: async (from: { id: number; order: number }, to: { id: number; order: number }) => {
    return await HttpClient.post<BaseResponseType<any>>("/api/EvaluationForm/SwapOrderEvaluationForm", {
      from,
      to,
    });
  },
};
