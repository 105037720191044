import { useTranslation } from "react-i18next";
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import FormInput from "components/blocks/FormInput";
import { useEffect, useState } from "react";
import {
  CountryCodeResultResponse,
  CountryResultResponse,
  GenderResultResponse,
} from "services/lookupService/models/LookupsType";
import { LookupService } from "services/lookupService";
import { RefereeSignUpFormType } from "../register.schema";
import { useCookieContext } from "contexts";
import Dropdown from "components/blocks/Dropdown";

type PersonalInfoProps = {
  getFormProps: UseFormRegister<RefereeSignUpFormType>;
  formErrors: FieldErrors<RefereeSignUpFormType>;
  watch: UseFormWatch<RefereeSignUpFormType>;
  setValue: UseFormSetValue<RefereeSignUpFormType>;
};

const PersonalInfo = ({ getFormProps, formErrors, watch, setValue }: PersonalInfoProps) => {
  const { t, i18n } = useTranslation("RegisterView");
  const [countryCodesDdl, setCountryCodesDdl] = useState<CountryCodeResultResponse[]>([]);
  const [nationalityDdl, setNationalityDdl] = useState<CountryResultResponse[]>([]);
  const [genderDdl, setGenderDdl] = useState<GenderResultResponse[]>([]);
  const { isAr, isRTL } = useCookieContext();

  const initializeAllDropDowns = async () => {
    const [countryCodeRes, nationalitiesRes, gendersRes] = await Promise.all([
      LookupService.getCountryCodes(),
      LookupService.getNationalities(),
      LookupService.getGenders(),
    ]);

    setCountryCodesDdl(countryCodeRes.data);
    setNationalityDdl(nationalitiesRes.data);
    setGenderDdl(gendersRes.data);
  };

  useEffect(() => {
    initializeAllDropDowns();
  }, [isAr]);

  return (
    <>
      <div className="row">
        <div className="form-group my-2 col-6">
          <FormInput
            type="text"
            label={t("nationalId")}
            inputClassName="form-control"
            placeholder={t("nationalId")}
            error={formErrors.nationalId?.message}
            {...getFormProps("nationalId")}
          />
        </div>
        <div className="form-group my-2 col-6">
          <FormInput
            type="text"
            label={t("username")}
            inputClassName="form-control"
            placeholder={t("username")}
            error={formErrors.username?.message}
            {...getFormProps("username")}
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group my-2 col-6">
          <FormInput
            type="text"
            label={t("fullNameAr")}
            inputClassName="form-control"
            placeholder={t("fullNameAr")}
            error={formErrors.fullNameArabic?.message}
            {...getFormProps("fullNameArabic")}
          />
        </div>

        <div className="form-group my-2 col-6">
          <FormInput
            type="text"
            label={t("fullNameEn")}
            inputClassName="form-control"
            placeholder={t("fullNameEn")}
            error={formErrors.fullNameEnglish?.message}
            {...getFormProps("fullNameEnglish")}
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group my-2 col-6">
          <FormInput
            type="email"
            label={t("email")}
            inputClassName="form-control"
            placeholder={t("email")}
            error={formErrors.email?.message}
            {...getFormProps("email")}
          />
        </div>
        <div className="form-group my-2 col-6">
          <label>{t("phone")}</label>
          <div className={`d-flex gap-1 ${isRTL ? "" : "flex-row-reverse"}`}>
            <div className="w-75">
              <FormInput
                type="text"
                inputClassName={`form-control ${formErrors.phoneNumber?.message ? "is-invalid" : ""}`}
                placeholder={t("phone")}
                {...getFormProps("phoneNumber")}
              />
            </div>

            <Dropdown
              noSelectionPlaceholder={t("+000")}
              data={countryCodesDdl.map((item) => ({
                value: `${item.countryCode} (${item.isoCode})`,
                id: `${item.countryCode}`,
              }))}
              useReactSelect
              value={watch("countryCode")}
              onChange={(value) => {
                setValue("countryCode", value);
              }}
              wrapperStyle={{ width: "25%" }}
              minWidth={100}
              selectClassName="phone-number"
            />
          </div>
          <input
            type="hidden"
            className={formErrors.countryCode?.message || formErrors.phoneNumber?.message ? "is-invalid" : ""}
          />
          {(formErrors.countryCode?.message || formErrors.phoneNumber?.message) && (
            <div className="invalid-feedback">{formErrors.countryCode?.message || formErrors.phoneNumber?.message}</div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="form-group my-2 col-6">
          <Dropdown
            noSelectionPlaceholder={t("nationality")}
            label={t("nationality")}
            data={nationalityDdl.map((item) => ({
              value: `${item.countryDetail.displayName} (${item.isoCode})`,
              id: `${item.countryDetail.displayName}`,
            }))}
            error={formErrors.nationality?.message}
            value={watch("nationality")}
            onChange={(value) => {
              setValue("nationality", value);
            }}
            useReactSelect
          />
        </div>
        <div className="form-group my-2 col-6">
          <Dropdown
            noSelectionPlaceholder={t("gender")}
            label={t("gender")}
            data={genderDdl.map((item) => ({
              value: item.genderDetail.displayName,
              id: item.id,
            }))}
            error={formErrors.genderId?.message}
            value={watch("genderId")}
            onChange={(value) => {
              setValue("genderId", +value);
            }}
            useReactSelect
          />
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
