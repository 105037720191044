import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import FormInput from "components/blocks/FormInput";
import { useQuery } from "hooks/useQuery";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProgramsService } from "services/programs";
import { ProgramActionResult } from "services/programs/models/ProgramTypes";

const ProceduresTable = ({ programId }: { programId: number }) => {
  const { t, i18n } = useTranslation(["Orders", "Programs"]);
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { data } = useQuery({
    queryFn: async () => {
      return await ProgramsService.getAllProgramAction(programId);
    },
  });

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "notes",
        field: "notes",
        displayName: t("notes"),
        showOnMobile: true,
      },
      {
        accessor: "workflowName",
        field: "workflowName",
        displayName: t("phase"),
        showOnMobile: true,
      },
      {
        accessor: "action",
        field: "action",
        displayName: t("action"),
      },
      {
        accessor: "appliedBy",
        field: "appliedBy",
        displayName: t("Programs:user"),
        render: (row: ProgramActionResult) => (
          <>{i18n.language === "ar" ? row.appliedBy.arabicName : row.appliedBy.englishName}</>
        ),
      },
      {
        field: "createdOn",
        displayName: t("date"),
        render(row: ProgramActionResult) {
          return <>{toSystemThemeDateFormat(row.createdOn)}</>;
        },
      },
    ],
    [toSystemThemeDateFormat, t],
  );

  return (
    <div className="bg-white rounded-4 h-100">
      <Grid data={data?.result ?? []} gridSchema={gridSchema} />
      <FormInput wrapperClassName="mt-3" label={t("notes")} placeholder={t("notes")} />
    </div>
  );
};

export default ProceduresTable;
