import { HttpClient } from "helpers";
import { BaseResponseType } from "services/shared-models/sharedTypes";
import { OutOfOfficeRequest, OutOfOfficeResult } from "./models";

export const outOfOfficeService = {
  setOutOfOffice: async (request: OutOfOfficeRequest) => {
    return HttpClient.post<BaseResponseType<OutOfOfficeResult>>("/api/OutOfOffice", request);
  },
  updateOutOfOffice: async (id: number, request: OutOfOfficeRequest) => {
    return HttpClient.put<BaseResponseType<OutOfOfficeResult>>(`/api/OutOfOffice/${id}`, request);
  },
  getOutOfOfficeById: async (id: number) => {
    return HttpClient.get<BaseResponseType<OutOfOfficeResult>>(`/api/OutOfOffice/${id}`);
  },
};
