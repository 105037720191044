import AccountLayout from "../../layouts/account/accountLayout";
import HomeLayout from "../../layouts/home/homeLayout";
import { RouteItemConfig } from "../models";
import { AccountRoutes } from "../accountRoutes/accountRoutes";
import { HomeRoutes } from "../homeRoutes/homeRoutes";
import WelcomePageView from "views/welcomePage/WelcomePageView";
import ProgramDetails from "views/welcomePage/components/ProgramsTaps/ProgramDetails";

export const AppRoutes: RouteItemConfig[] = [
  {
    path: "/account",
    name: "Account",
    component: AccountLayout,
    layout: "",
    default: false,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
    subMenu: [...AccountRoutes],
  },
  {
    path: "/home",
    name: "Home",
    component: HomeLayout,
    layout: "",
    default: false,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    subMenu: [...HomeRoutes],
  },
  {
    path: "/",
    name: "WelcomePage",
    component: WelcomePageView,
    layout: "",
    default: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: "/ProgramDetails/:id",
    name: "ProgramDetail",
    component: ProgramDetails,
    layout: "/",
    default: false,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
];
