import Modal from "components/blocks/Modal";
import RichTextEditor from "components/RichTextEditor";
import { useState } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CreateOrderRequest } from "services/orders/models/OrdersTypes";
import { ProgramDetailsResult } from "services/programs/models/ProgramTypes";

type RulesAndConditionSectionProps = {
  register: UseFormRegister<CreateOrderRequest>;
  errors: FieldErrors<CreateOrderRequest>;
  programDetails?: ProgramDetailsResult;
};

const RulesAndConditionSection = ({ register, errors, programDetails }: RulesAndConditionSectionProps) => {
  const { t, i18n } = useTranslation(["Orders", "Common"]);
  const [modalProps, setModalProps] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onRuleAndConditionsClicked = () => {
    setModalProps({
      isOpen: true,
      title: programDetails?.ruleAndConditionName ?? "",
      body: programDetails?.ruleAndConditionTemplate ?? "",
    });
  };

  const onCommitmentClicked = () => {
    setModalProps({
      isOpen: true,
      title: programDetails?.commitmentName ?? "",
      body: programDetails?.commitmentTemplate ?? "",
    });
  };

  return (
    <div className="p-3">
      <div>
        <div >
         
         <input type="checkbox" className="mx-1 text-primary fw-bold form-check-input" {...register("isAcceptedConditionAndRules")} />
          <label htmlFor="isAcceptedConditionAndRules">
            <div className="d-flex gap-1">
              {t("accept")}
              <button
                type="button"
                onClick={onRuleAndConditionsClicked}
                className="btn btn-link p-0 text-primary fw-bold "
              >
                {t("conditionsAndRules")}
              </button>
            </div>
          </label>
        </div>

        {errors.isAcceptedConditionAndRules && (
          <div
            className="invalid-feedback mb-2"
            style={{
              display: "block",
            }}
          >
            {errors.isAcceptedConditionAndRules.message}
          </div>
        )}
      </div>

      <div className="mb-2">
        <div>
         <input type="checkbox" className="mx-1 form-check-input" {...register("isAcceptedCommitments")} />
          <label htmlFor="isAcceptedCommitments">
            <div className="d-flex gap-1">
              {t("accept")}
              <button type="button" onClick={onCommitmentClicked} className="btn btn-link text-primary fw-bold p-0">
                {t("commitment")}
              </button>
            </div>
          </label>
        </div>
        {errors.isAcceptedCommitments && (
          <div
            className="invalid-feedback my-2"
            style={{
              display: "block",
            }}
          >
            {errors.isAcceptedCommitments.message}
          </div>
        )}
      </div>

      <span className="fw-bold fs-12px mt-4">{t("createOrderNote")}</span>

      <Modal
        isOpen={modalProps.isOpen}
        onClose={() => setModalProps({ ...modalProps, isOpen: false })}
        title={modalProps.title}
        containerClassName="bg-white "
        size="lg"
      >
        <RichTextEditor initialData={modalProps.body} isReadOnly />
      </Modal>
    </div>
  );
};

export default RulesAndConditionSection;
