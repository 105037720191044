import { useTranslation } from "react-i18next";
import "./FileInput.scss";

type FileInputProps = {
  label?: string;
  clickHereForLabel?: string;
  onChange: (files: FileList | null) => void;
  containerWrapperClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  allowedFileTypes?: string;
};

const FileInput: React.FC<FileInputProps> = ({
  label,
  onChange,
  labelClassName,
  clickHereForLabel,
  containerWrapperClassName,
  disabled,
  allowedFileTypes = ".xls,.xlsx,.pdf,.doc,.docx,.zip,image/*",
}) => {
  const { t } = useTranslation("Common");
  const onLocalChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      onChange(files);
    }
  };

  return (
    <div className={`mb-3 file-input-container ${containerWrapperClassName}`}>
      {label && (
        <label htmlFor="formFile" className={`mb-1 ${labelClassName}`}>
          {label}
        </label>
      )}
      <div className="position-relative">
        <div className="file-drop-zone">
          <span>
            <strong className="text-primary px-2">{t("clickHere")}</strong>
            {clickHereForLabel}
          </span>
          <input
            accept={allowedFileTypes}
            className="file-input"
            type="file"
            onChange={onLocalChange}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default FileInput;
