import Modal from "components/blocks/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserResult } from "services/orders/models/OrdersTypes";
import ExternalExaminerSection from "./ExternalExaminerSection";
import { CreateProgramOrderExaminerRequest } from "services/examiners/Model/ExaminerTypes";
import useMutation from "hooks/useMutation";
import { ExaminersService } from "services/examiners";
import InternalExaminersTable from "./InternalExaminersTable";
import ExaminersDropDown from "./ExaminersDropDown";

export type SelectedExaminerType = Pick<UserResult, "arabicName" | "englishName" | "id" | "email" | "college">;
type AddExaminerModalProps = {
  isOpen: boolean;
  onClose: (shouldUpdateTable?: boolean) => void;
  programOrderEvaluationId: number;
};

const AddExaminerModal = ({ isOpen, onClose, programOrderEvaluationId }: AddExaminerModalProps) => {
  const { t } = useTranslation(["Orders"]);

  const [selectedInternalExaminers, setSelectedInternalExaminers] = useState<SelectedExaminerType[]>([]);
  const [selectedExaminer, setSelectedExaminer] = useState<UserResult>();
  const [externalExaminersEmail, setExternalExaminersEmail] = useState<
    {
      email: string;
      isSentInvitation: boolean;
    }[]
  >([]);

  const { loading, mutateAsync } = useMutation({
    queryFn: async (request: CreateProgramOrderExaminerRequest[]) => {
      return await ExaminersService.addExaminer(request);
    },
  });

  const onAddExaminers = async () => {
    const examiners = selectedInternalExaminers.concat(externalExaminersEmail.map((u) => ({ email: u.email, id: "" })));

    const newExaminers: CreateProgramOrderExaminerRequest[] = examiners.map((examiner) => ({
      examinerEmail: !examiner.id ? examiner.email : null,
      examinerId: examiner.id ? examiner.id : null,
      programOrderEvaluationId: programOrderEvaluationId,
    }));

    const res = await mutateAsync(newExaminers);

    if (!res?.hasError) {
      onModalClose(true);
    }
  };

  const onModalClose = (shouldUpdateExaminerTable: boolean) => {
    onClose(shouldUpdateExaminerTable);
    setSelectedInternalExaminers([]);
    setExternalExaminersEmail([]);
  };

  return (
    <Modal
      title={t("addExaminers")}
      isOpen={isOpen}
      onClose={() => onModalClose(false)}
      containerClassName="bg-white"
      size="lg"
      titleClassName="fs-6 text-dark"
    >
      <div className="d-flex flex-column gap-4">
        <ExaminersDropDown
          selectedExaminer={selectedExaminer}
          setSelectedExaminer={setSelectedExaminer}
          externalExaminersEmail={externalExaminersEmail}
          setExternalExaminersEmail={setExternalExaminersEmail}
          selectedInternalExaminers={selectedInternalExaminers}
          setSelectedInternalExaminers={setSelectedInternalExaminers}
        />

        <ExternalExaminerSection
          externalExaminersEmail={externalExaminersEmail}
          setExternalExaminersEmail={setExternalExaminersEmail}
        />

        <InternalExaminersTable
          selectedInternalExaminers={selectedInternalExaminers}
          setSelectedInternalExaminers={setSelectedInternalExaminers}
        />

        <div className="d-flex justify-content-center gap-4">
          <button className={`btn btn-outline-secondary border rounded-3 p-10-32P`} onClick={() => onModalClose(false)}>
            {t("Common:cancel")}
          </button>
          <button
            className="btn btn-primary p-10-32P rounded-3"
            onClick={onAddExaminers}
            disabled={
              loading ||
              !programOrderEvaluationId ||
              (!selectedInternalExaminers.length && !externalExaminersEmail.length)
            }
          >
            {!loading ? t("Common:add") : <div className="custom-loader"></div>}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddExaminerModal;
